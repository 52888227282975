export const TranslateDashboard = {
  en: {
    translation: {
      addACluster: "Add a Cluster for free",
      Activos: "Assets",
      Borradores: "Drafts",
      modulo: "module",
      Verdocumentacion: "See documentation",
      PRÓXIMAMENTE: "SOON",
      cambioPublicCluster: "Cluster Public Change Successfully",
      errorCambioPublicCluster: "error when changing the cluster public",
      Public: "Public",
      // Modal
      crearCluster: "Create a Cluster",
      nombreCluster: "Cluster's Name",
      cancelar: "Cancel",
      crearBtn: "Create",
      newCluster: "New Cluster",
      clusterCreada: "The Cluster has been created",
      clusterNoCreada: "The Cluster could not be created",
      // Form-Key
      EnterKey: "Introduce a key",
      Send: "Send",
      correctKey: "Correct Key",
      IncorrectKey: "Incorrect Key",
      IntroduceKey: "Introduce a Key",
      EnterKeyHere: "Enter a Key here",

      // Control Panel
      panel: "Control Panel",
      ajustes: "Settings",
      Informacion: "Information",
      estadisticasEnUso: "Statistics in use",
      activo: "Active",
      inactivo: "Desactive",
      cambioStateCluster: "Cluster State Change Successfully!",
      errorCambioStateCluster: "error when changing cluster status",
      statePublicText:
        "Allows access to the cluster configuration with the key.",
      // Token Humanizer
      modulos: "Modules",
      por: "per",
      respuestaAutomatica: "Automatic Response",
      precioPor: "Price Per",
    },
  },
  es: {
    translation: {
      Activos: "Activos",
      Borradores: "Borradores",
      modulo: "modulo",
      addACluster: "Agregue un clúster gratis",
      Verdocumentacion: "Ver documentación",
      PRÓXIMAMENTE: "PRÓXIMAMENTE",
      cambioPublicCluster: "Cambio público del clúster con éxito",
      errorCambioPublicCluster: "Error al cambiar el public del cluster",
      statePublicText:
        "Permite acceder a la configuración del cluster con la clave.",
      Public: "Público",
      //Modal
      crearCluster: "Crear un Cluster",
      nombreCluster: "Nombre del Cluster",
      cancelar: "Cancelar",
      crearBtn: "Crear",
      newCluster: "Nuevo Cluster",
      clusterCreada: "La Cluster ha sido creada",
      clusterNoCreada: "La Cluster no pudo ser creada",

      //Form Key
      EnterKey: "Introducir KEY",
      Send: "Enviar",
      correctKey: "Clave Correcta",
      IntroduceKey: "Introduce una KEY para configurar un cluster",
      EnterKeyHere: "Ingresa una Key Aquí",
      IncorrectKey: "Clave incorrecta",

      //Control Panel
      panel: "Panel de Control",
      ajustes: "Ajustes",
      Informacion: "Información",
      estadisticasEnUso: "Estadísticas en uso",
      activo: "Activar",
      inactivo: "Desactivar",
      cambioStateCluster: "¡Cambio de estado de Cluster Correctamente!",
      errorCambioStateCluster: "error al cambiar el estado de la cluster",

      // Token Humanizer
      modulos: "Modulos",
      por: "Por",
      respuestaAutomatica: "Respuesta Automática",
      precioPor: "Precio Por",
    },
  },
};
