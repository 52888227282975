import "./GuildSpammer.css";
import { desactivarModals } from "./../../../../../../Utils/Home/Home.js";
import ClusterNavbar from "../../ClusterNavbar.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CommonModules from "../../common/CommonModules.jsx";
import { useParams } from "react-router";
import { useEffect } from "react";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin.js";
import NavbarHome from "../../../../../navbar/NavbarHome.jsx";

const GuildSpammer = ({}) => {
  const { key } = useParams();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);
  return (
    <>
      <CommonModules
        titulo={"Guild Spammer"}
        ruta={"guildSpammer"}
        tituloCreate="nuevoModulo"
        rutaModulo="guildspammer"
        NuevoModulo="nuevoModulo"
        subtitulo="modulos"
      />
    </>
  );
};

export default GuildSpammer;
