// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        // store products
        // product 1
        token1_img: "https://i.ibb.co/LC8ZVKV/Nuevo-documento-de-texto-2.webp",
        token1_title: "producto 1",
        token1_subtitle: "subtitulo",
        token1_price: "3.99",
        token1_description: `Add your desired amount that you want to deposit into your Clicking the pay
        now button will lead you to a checkout page your desired amount that
        you want to deposit into your Clicking the pay now button will lead you
          to a checkout`,
        // product 2
        token2_img: "https://i.ibb.co/LC8ZVKV/Nuevo-documento-de-texto-2.webp",
        token2_title: "producto 1",
        token2_subtitle: "subtitulo",
        token2_price: "3.99",
        token2_description: `Add your desired amount that you want to deposit into your Clicking the pay
        now button will lead you to a checkout page your desired amount that
        you want to deposit into your Clicking the pay now button will lead you
          to a checkout`,
        // product 3
        token3_img: "https://i.ibb.co/LC8ZVKV/Nuevo-documento-de-texto-2.webp",
        token3_title: "producto 1",
        token3_subtitle: "subtitulo",
        token3_price: "3.99",
        token3_description: `Add your desired amount that you want to deposit into your Clicking the pay
        now button will lead you to a checkout page your desired amount that
        you want to deposit into your Clicking the pay now button will lead you
          to a checkout`,
        // Key Feacture
        token1_Keyfeacture_option1:
          "Enter the 30 digits card number on the card",
        token1_Keyfeacture_option2:
          "Enter the 30 digits card number on the card",
        token1_Keyfeacture_option3:
          "Enter the 30 digits card number on the card",

        token2_Keyfeacture_option1:
          "Enter the 30 digits card number on the card",
        token2_Keyfeacture_option2:
          "Enter the 30 digits card number on the card",
        token2_Keyfeacture_option3:
          "Enter the 30 digits card number on the card",

        token3_Keyfeacture_option1:
          "Enter the 30 digits card number on the card",
        token3_Keyfeacture_option2:
          "Enter the 30 digits card number on the card",
        token3_Keyfeacture_option3:
          "Enter the 30 digits card number on the card",
        // customOptions
        // Options <id>CustomOptions_<id>Options_title
        customOption1_option1_title: "Fresh (A few weeks to months aged)",
        customOption1_option1_subtitle: "Email & Phone Verified",
        customOption1_option2_title: "Fresh (A few weeks to months aged)",
        customOption1_option2_subtitle: "Email & Phone Verified",
        customOption1_option3_title: "Fresh (A few weeks to months aged)",
        customOption1_option3_subtitle: "Email & Phone Verified",
      },
    },
    es: {
      translation: {},
    },
    // Puedes agregar más idiomas aquí con sus respectivas traducciones
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
