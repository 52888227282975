export const TranslateBots = {
  en: {
    translation: {
      //BOTS
      cuentasUsuarios: "User accounts",
      usuarios: "Users",
      estado: "State",
      telefono: "Phone",
      agregarToken: "Add Token",
      agregarTodas: "Add All",
      archivoToken: "Token Archive",
      subirArchivo: "Uplode file",
      checkearTokens: "Check Tokens",
      eliminarTokens: "Remove invalid tokens",
      botDiscord: "Discord Bot",
      valido: "Valid",
      invalido: "Invalid",
      verificado: "Verified",
      noVerificado: "Not Verified",
      botsOficiales: "Official discord API bots.",
      noElementos: "There are no elements",
      eliminar: "Delete",
      Send: "Send",
      Addtokenhere: "Add token here",
      archivoSeleccionado: "Selected file",
      copiadoPortapapeles: "Content copied to clipboard",
      errorCopiadoPortapapeles: "Error copying to clipboard",
      errorAgregarToken: "Error adding tokens",
      tokensProcesados: "Tokens Processed Correctly!",
      procesandoToken: "processing token...",
      tokenAgregado: "Added token!",
      selfBotExistente: "The selfbot with that token already exists",
      selfBotCluster: "This selfbot's token is already in a cluster",
      tokenInvalido: "Invalid Token",
      eliminadoExitosamente: "Successfully removed",
      errorEliminar: "Delete error",
      checkeandoToken: "Checking tokens",
      tokenCheckeado: "Checked Tokens",
      noPoderCheackear: "Could not check tokens",
      tokensBienEliminados: "Tokens removed successfully!",
      noPoderEliminar: "Could not delete tokens",
      errorEliminando: "Error trying to delete",
      seleccionarArchivo: "Please select a .txt file type",
      errorSubiendoArchivos: "Error uploading file",
      errorSolicitud: "Request error",
    },
  },
  es: {
    translation: {
      //BOTS
      cuentasUsuarios: "Cuentas de usuarios",
      usuarios: "Usuarios",
      estado: "Estado",
      telefono: "Telefono",
      agregarToken: "Añadir Token",
      agregarTodas: "Añadir Todas",
      archivoToken: "Archivo de Tokens",
      subirArchivo: "Subir Archivo",
      checkearTokens: "Chekear Tokens",
      eliminarTokens: "Eliminar Tokens inválidos",
      botDiscord: "Bot de Discord",
      Addtokenhere: "Agregar Token Aquí",
      Send: "Enviar",
      valido: "Válido",
      invalido: "Inválido",
      verificado: "Verificado",
      noVerificado: "No verificado",
      botsOficiales: "Bots oficiales de la API de discord.",
      noElementos: "No hay elementos",
      eliminar: "Eliminar",
      archivoSeleccionado: "Archivo seleccionado",
      copiadoPortapapeles: "Contenido copiado al portapapeles",
      errorCopiadoPortapapeles: "Error al copiar al portapapeles",
      errorAgregarToken: "Error al agregar los tokens",
      tokensProcesados: "¡Tokens Procesados Correctamente!",
      procesandoToken: "Procesando token...",
      tokenAgregado: "Token añadido!",
      selfBotExistente: "El selfbot con ese token ya existe",
      selfBotCluster: "El token de este selfbot ya está en un grupo",
      tokenInvalido: "Token inválido",
      eliminadoExitosamente: "Eliminado exitosamente",
      errorEliminar: "Error al eliminar",
      checkeandoToken: "Checkeando tokens",
      tokenCheckeado: "Tokens checkeados",
      noPoderCheackear: "No se pudieron checkear los tokens",
      tokensBienEliminados: "¡Tokens eliminados exitosamente!",
      noPoderEliminar: "No se pudieron eliminar los tokens",
      errorEliminando: "Error al intentar eliminar",
      seleccionarArchivo: "Por favor seleccione un tipo de archivo .txt",
      errorSubiendoArchivos: "Error al cargar el archivo",
      errorSolicitud: "Error en la solicitud",
    },
  },
};
