export const TranslateHome = {
  en: {
    translation: {
      // Header
      TituloPrincipalHeader: "Get the best Discord services",
      subtituloHeader:
        "Enjoy our products and services directly through our control panel. Without hosting concerns, just hassle-free access!",
      ComienzabtnHeader: "Starts",
      DepositFundsbtn: "Deposit Funds",
      ofreciendosoluciones: "offering solutions",
      beneficiosdeclientes: "customer benefits",
      BorrarTodo: "Clear All",
      // Separador
      Productos: "PRODUCTS",
      ClientesSatifechos: "SATISFIED CUSTOMERS",
      years: "Years",
      Mercado: "ON THE MARKET",

      // Get Started
      tituloGetStarted: "We take care of the hosting!",
      subtituloGetStated:
        "Experience simplicity with our all-in-one solution – centralized, easy to use, with one user interface for mobile devices and PC. Forget the complexities of supplier management – We take care of everything, allowing you to focus on your content without problems.",
      DescurirMas: "Discover More",
      GanaDinero: "Earn money",
      ganaDineroText: "Become an affiliate of our store and earn 30% lifetime earnings.",
      automatizad: "Automated Process",
      proceso: "The entire buying and management process is completely automated and guided.",
      asegurado: "100% Secured",
      codificaciones: "We coded our tools ourselves and use UHQ proxies providers and salvers.",
      ServiciosBaratos: "Cheap Services",
      ServiciosBaratosText: "Enjoy our tools for a minimum cost per month.",

      // CLUSTER
      AñadeFondosCuenta: "Add funds to your account",
      depositaDineroCluster: "Deposit money to your balance and start enjoying the best advertising tools for Discord.",
      AñadirFondosbtn: "Add Funds",
      ConvierteteAfiliado: "Become an Affiliate",
      ConvierteteAfiliadoText:
        "Join the Affiliate program that pays you 30% for life of the profits you get us from new clients.",
      uneteBtn: "join up",

      // AboutUs
      tituloAboutUs: "About us?",
      descripcionAboutUs:
        "JikesShop redefines efficiency with its Pay-As-You-Go model. Manage your expenses clearly in our dashboard intuitive and pay only for what you use.",
      Herramientaspublicidad: "Advertising tools",
      HerramientaspublicidadText:
        "We have the best tools to advertise on the largest Discord servers at a large scale at a minimum price where you manage the entire system yourself.",
      ConvierteteAfiliadoAboutUstext: "In our store we will pay you 30% of what the people you invite spend for life.",
      Depositadinero: "Money deposit",
      DepositadineroText: "Add money to your balance and spend it on your favorite tools or our fabulous gear store.",

      // Control
      tituloControl: "Pay-As-You Go Services",
      descripcionControl:
        "Manage your expenses clearly on our intuitive dashboard and pay only for what you use. Accurate reports and tailored invoices ensure financial control and adaptability at your fingertips.",

      // Clientes
      TituloClientes: "What do our clients think?",
      subtituloClientes:
        "Some of the reviews we receive for our automated services are published here and on our account Trustpilot.",
      comentarioCliente1: "Love the service! 10/10. I've bought many times; it's super cheap and definitely worth it.",
      comentarioCliente2:
        "Best advertising service! I highly recommend it. Rated 5/5 for support, functionality, price, and simplicity.",
      comentarioCliente3: "Great bot! Invested $10 and made around $100 in a week, super easy with this.",

      //Preguntas
      PreguntasTitulo: "Some frequently asked questions",
      pregunta1: "How do clusters work and what tools do they include? How do clusters work and what tools do they include?",
      respuesta1:
        "Since clusters are an integral part of your services, providing detailed information about what they are, how they work, and what specific tools like guild spammer, mass friender, and token humanizer they include will help users understand the value they bring.",
      pregunta2: "What do I do if my tools or products are not working correctly?",
      respuesta2:
        "If your tools or products aren't working well, make sure everything is set up correctly. If the problem continues, contact technical support detailing the problem.",
      pregunta3: "What is the purchase return policy?",
      respuesta3:
        "The return policy for purchases will vary depending on the specific product purchased. It is important to review the return policy for each product before making a purchase to ensure you understand the terms and conditions.",
      pregunta4: "What should I do if I don't receive a purchase?",
      respuesta4:
        "If you do not receive a purchase confirmation email, check your spam folder. If it is not there, contact customer support and provide your invoice number for assistance.",

      // separador GetStarted
      tituloStarted: "Start your services now!",
      descripcionStarted: "All you need to do is adding balance and set up your favorite tools.",
      empezarbtn: "Get Started",
      // Footer
      descripcionFooter: "A new way to make the payments easy, reliable and secure.",
      primerTitulo: "UseFul Links",
      descripcion1: "Contact",
      descripcion2: "How it Works",
      descripcion3: "Create",
      descripcion4: "Explore",
      descripcion5: "Terms & Services",
      segundoTitulo: "Community",
      descripcion6: "Help Center",
      descripcion7: "Partners",
      descripcion8: "Suggestions",
      descripcion9: "Blogs",
      descripcion10: "Newsletters",
      tercerTitulo: "Partner",
      descripcion11: "Our Partner",
      descripcion12: "Become a Partner",
      changeIdioma: "Change Idiom",
    },
  },
  es: {
    translation: {
      //Header
      TituloPrincipalHeader: "Obtén los mejores servicios de Discord",
      subtituloHeader:
        "Disfruta de nuestros productos y servicios directamente a través de nuestro panel de control. ¡Sin preocupaciones de alojamiento, solo acceso sin problemas!",
      ComienzabtnHeader: "Comienza",
      DepositFundsbtn: "Depositar Fondos",
      BorrarTodo: "Borrar Todo",
      // Separador
      Productos: "PRODUCTOS",
      ClientesSatifechos: "CLIENTES SATISFECHOS",
      ofreciendosoluciones: "ofreciendo soluciones",
      beneficiosdeclientes: "beneficios de clientes",
      years: "Años",
      Mercado: "EN EL MERCADO",

      //Get Started
      tituloGetStarted: "¡Del Hosting nos encargamos nosotros!",
      subtituloGetStated:
        "Experimente la simplicidad con nuestra solución todo en uno: centralizada, fácil de usar, con una interfaz de usuario para dispositivos móviles y PC. Olvídese de las complejidades de la administración de proveedore: nosotros nos encargamos de todo, permitiéndole concentrarse en su contacto sin problemas.",
      DescurirMas: "Descurir Más",
      GanaDinero: "Gana Dinero",
      ganaDineroText: "Conviértase en afiliado de nuestra tienda y obtenga un 30% de ganancias de por vida.",
      asegurado: "100% Asegurado",
      codificaciones: "Nosotros mismos codificamos nuestras herramientas y utilizamos proveedores y bandejas de proxy UHQ",
      automatizad: "Proceso automatizado",
      proceso: "Todo el proceso de compra y gestión está completamente automatizado y guiado.",
      ServiciosBaratos: "Servicios Baratos",
      ServiciosBaratosText: "Disfruta de nuestras herramientas por un coste mínimo al mes.",

      //CLUSTER
      AñadeFondosCuenta: "Añade fondos a tu cuenta",
      depositaDineroCluster: "Deposita dinero en tu saldo y empieza a disfrutar de las mejores herramientas publicitarias para Discord.",
      AñadirFondosbtn: "AñadirFondos",
      ConvierteteAfiliado: "Conviértete en Afiliado",
      ConvierteteAfiliadoText:
        "Únete al programa de Afiliados que te paga un 30% para toda la vida de las ganancias que nos consigas con nuevos clientes.",
      uneteBtn: "Únete",

      //AboutUs
      tituloAboutUs: "¿Quienes somos?",
      descripcionAboutUs:
        "JikesShop redefine la eficiencia con su modelo Pay-As-You-Go.Administra tus gastos con claridad en nuestro tablero intuitivo y paga solo por lo que usas.",
      Herramientaspublicidad: "Herramientas de publicidad",
      HerramientaspublicidadText:
        "Tenemos las mejores herramientas para publicitar en los mayores servidores de Discord a gran escala a un precio mínimo en el que tú mismo manejas todo el sistema.",
      ConvierteteAfiliadoAboutUstext:
        "En nuestra tienda te pagaremos un 30% de lo que gaste la gente que invites de forma vitalicea.",
      Depositadinero: "Deposita dinero",
      DepositadineroText:
        "Añade dinero a tu balance y gastalo en tus herramientas favoristas o en nuestra fabulosa tienda de objetos.",

      // Control
      tituloControl: "Paga solo por lo que gastas.",
      descripcionControl:
        "JikesShop redefine la eficiencia con su modelo Pay-As-You-Go. Administra tus gastos con claridad en nuestro tablero intuitivo y paga solo por lo que usas. Informes precisos y facturas a medida garantizan control y adaptabilidad financiera a tu alcance.",

      //Clientes
      TituloClientes: "¿Qué opinan nuestros clientes?",
      subtituloClientes:
        "Algunas de las reseñas que recibimos para nuestros servicios automatizados se publican aquí y en nuestra cuenta de Trustpilot.",
      comentarioCliente1:
        "¡Me encanta el servicio! 10/10. He comprado muchas veces; Es súper barato y definitivamente vale la pena Trustpilot.",
      comentarioCliente2:
        "¡El mejor servicio de publicidad! Lo recomiendo altamente. Calificación 5/5 por soporte, funcionalidad, precio y simplicidad.",
      comentarioCliente3: "¡Gran robot! Invertí $10 y gané alrededor de $100 en una semana, muy fácil con esto.",

      //Preguntas
      PreguntasTitulo: "Algunas preguntas frecuentes",
      descripcionPreguntas: "Todo lo que necesitas hacer es añadir balance y configurar tus herramientas favoritas.",
      pregunta1:
        "¿Cómo funcionan los clusters y qué herramientas incluyen? ¿Cómo funcionan los clusters y qué herramientas incluyen?",
      respuesta1:
        "Dado que los clusters son una parte integral de tus servicios, proporcionar información detallada sobre qué son, cómo funcionan y qué herramientas específicas como el guild spammer, el mass friender, y el token humanizer incluyen, ayudará a los usuarios a entender el valor que aportan.",
      pregunta2: "¿Qué hago si mis tools o products no están funcionando correctamente?",
      respuesta2:
        "Si tus herramientas o productos no funcionan bien, asegúrate de que todo esté configurado correctamente. Si el problema continúa, contacta con soporte técnico detallando el problema.",
      pregunta3: "¿Cuál es la política de devolución de compras?",
      respuesta3:
        "La política de devoluciones de las compras variará en función del producto concreto que se adquiera. Es importante revisar la política de devoluciones de cada producto antes de realizar una compra para asegurarse de que comprende los términos y condiciones.",
      pregunta4: "¿Qué debo hacer si no recibo una compra?",
      respuesta4:
        " Si no recibes un correo de confirmación de compra, verifica la carpeta de spam. Si no está allí, contacta con soporte al cliente y proporciona tu número de factura para asistencia.",

      // separador GetStarted
      tituloStarted: "Inicie sus servicios ahora!",
      descripcionStarted: "Todo lo que necesitas hacer es agregar saldo y configurar tus herramientas favoritas.",
      empezarbtn: "Empezar",
      //Footer
      descripcionFooter: "Una nueva forma de hacer los pagos fáciles, confiables y seguros.",
      primerTitulo: "Enlaces útiles",
      descripcion1: "Contacto",
      descripcion2: "Cómo funciona",
      descripcion3: "Crear",
      descripcion4: "Explorar",
      descripcion5: "Términos y servicios",
      segundoTitulo: "Comunidad",
      descripcion6: "Centro de ayuda",
      descripcion7: "Socios",
      descripcion8: "Sugerencias",
      descripcion9: "Blogs",
      descripcion10: "Boletines",
      tercerTitulo: "Socio",
      descripcion11: "Nuestro socio",
      descripcion12: "Convertirse en un socio",
      changeIdioma: "Cambiar Idioma",
    },
  },
};
