import axios from "axios";
import { backendURL } from "../config";

export const orderNotifications = async (setNotifiacionesItem) => {
  if (localStorage.getItem("tokenlogin")) {
    try {
      const response = await axios.get(`${backendURL}/notification/list`, {
        token: localStorage.getItem("tokenlogin"),
      });
      if (response.status === 200) {
        setNotifiacionesItem(response.data.notifications);
      } else if (response.status === 203) {
        setNotifiacionesItem();
        // alert("no hay notificaciones");
      }
    } catch (error) {
      console.error(error);
    }
  }
};
