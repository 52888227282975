import { t } from "i18next";
import "./styles/CommonModulo.css";
import add from "../../../../img/add.webp";
import { useEffect, useState } from "react";
import { timeOut } from "../../../../Utils/alerts/Alerts.js";
import { useParams } from "react-router";
import { OrderModules, CreateModule, deleteModule } from "./common/FuntionsRequest/FunctionsRequest.js";
import Separador from "./common/Separador.jsx";
import ParteModuloRaid from "./pages/massFriender/ParteModuloRaid.jsx";
import ModalDeleteModule from "./common/ModalDeleteModule.jsx";
import Spinner from "../../../Spinner.jsx";
import { validateKeyCluster } from "../../../../Utils/handleValidateLogin.js";

const CommonRaid = ({ titulo, ruta, rutaModulo, activeModuleRaid, tituloCreate, subtitulo, tipoModulo }) => {
  const [arrayModulesActive, setArrayModulesActive] = useState([{}]);
  const [arrayModulesBorradores, setArrayModulesBorradores] = useState([{}]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [ModuleId, setModuleId] = useState();
  const { key } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    validateKeyCluster(key);
  }, []);
  const handleClickModulo = async (e, id) => {
    const clickedElement = e.target.tagName.toLowerCase() === "button";
    if (clickedElement) {
      deleteModule(
        id,
        key,
        setIsSelected,
        setIsSelectBorradores,
        setArrayModulesActive,
        setArrayModulesBorradores,
        ruta,
        rutaModulo
      );
      return;
    }
    timeOut(`/dashboard/${key}/${ruta}/${id}`, 0);
  };
  useEffect(() => {
    OrderModules(key, setArrayModulesActive, setArrayModulesBorradores, rutaModulo);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-principal-herramienta-cluster">
          <div className="container-info-panel-cluster">
            <div>
              <h1>{titulo}</h1>
              <p>Información</p>
            </div>
          </div>
          <div className="container-externo-herramienta-cluster">
            <div
              className="btn-add-module"
              onClick={() => CreateModule(key, ruta, rutaModulo, setArrayModulesActive, setArrayModulesBorradores)}
            >
              <h4>{t(tituloCreate)}</h4>
              <button>
                <img src={add} alt="add" />
              </button>
            </div>
            <div className="container-herramienta-cluster">
              <div className="container-info-herramientas-clusters">
                <h2>{t(subtitulo)}</h2>
                <p>Conjunto</p>
              </div>
              <Separador />
              {arrayModulesBorradores !== undefined && (
                <>
                  <div
                    className={`background-modal  ${isSelectBorradores ? "active" : ""}`}
                    onClick={() => setIsSelectBorradores(false)}
                  ></div>
                  <ParteModuloRaid
                    setArrayModulesActive={setArrayModulesActive}
                    setArrayModulesBorradores={setArrayModulesBorradores}
                    rutaModulo={rutaModulo}
                    tipoRaid={"Borradores"}
                    isSelected={isSelected}
                    setModuleId={setModuleId}
                    setIsSelected={setIsSelectBorradores}
                    arrayRaid={arrayModulesBorradores}
                    handleClickModulo={handleClickModulo}
                  />
                  <ModalDeleteModule
                    id={ModuleId}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    setIsSelectBorradores={setIsSelectBorradores}
                    isSelectBorradores={isSelectBorradores}
                    setArrayModulesActive={setArrayModulesActive}
                    deleteModule={deleteModule}
                    setArrayModulesBorradores={setArrayModulesBorradores}
                    ruta={ruta}
                    rutaModulo={rutaModulo}
                  />
                </>
              )}
              <Separador />
              {activeModuleRaid && arrayModulesActive.length < 0 && (
                <>
                  <div
                    className={`background-modal  ${isSelected ? "active" : ""}`}
                    onClick={() => setIsSelectBorradores(false)}
                  ></div>
                  <ParteModuloRaid
                    setArrayModulesActive={setArrayModulesActive}
                    setArrayModulesBorradores={setArrayModulesBorradores}
                    rutaModulo={rutaModulo}
                    isSelected={isSelected}
                    setModuleId={setModuleId}
                    tipoRaid={"En proceso"}
                    setIsSelected={setIsSelected}
                    arrayRaid={arrayModulesActive}
                    handleClickModulo={handleClickModulo}
                  />
                  <ModalDeleteModule
                    id={ModuleId}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    setIsSelectBorradores={setIsSelectBorradores}
                    isSelectBorradores={isSelectBorradores}
                    setArrayModulesActive={setArrayModulesActive}
                    deleteModule={deleteModule}
                    setArrayModulesBorradores={setArrayModulesBorradores}
                    ruta={ruta}
                    rutaModulo={rutaModulo}
                  />
                  <Separador />
                </>
              )}
              <ParteModuloRaid
                setArrayModulesActive={setArrayModulesActive}
                setArrayModulesBorradores={setArrayModulesBorradores}
                rutaModulo={rutaModulo}
                isSelected={isSelected}
                setModuleId={setModuleId}
                tipoRaid={"Historial"}
                setIsSelected={setIsSelected}
                arrayRaid={arrayModulesActive}
                handleClickModulo={handleClickModulo}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonRaid;
