import React from "react";
import { useParams } from "react-router";
import { t } from "i18next";
import "./Modulos.css";
import imgDoc from "../../imgDashboard/icon-documentation.svg";

export default function BtnsModules({
  NoCambioDeNombre,
  setModalchangeName,
  setIsSelected,
  modulo,
  cooldown,
  saveDraft,
  price,
  setActiveModalRaid,
  saveActive,
  ruta,
  stateRequest,
}) {
  const { key, id } = useParams();

  return (
    <>
      {modulo !== undefined && (
        <div className="container-btns-titulo-herramienta-cluster">
          <div className="container-btn-back-title-modulo">
            <h2>
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = `/dashboard/${key}/${ruta}`)
                }
              >
                &#60;
              </span>
              <p>{modulo.name}</p>

              <button onClick={() => setModalchangeName(true)}>
                <i className="fa-solid fa-pencil"></i>
              </button>
            </h2>
            <a
              href="https://docs.jikesshop.com"
              target="_blank"
              className="btn-modulo-documentation"
              style={{ textDecoration: "none" }}
            >
              <img src={imgDoc} alt="" />
              <span>{t("Verdocumentacion")}</span>
            </a>
          </div>
          <div className="container-btns-modulo">
            <button onClick={() => setIsSelected(true)}>{t("borrar")}</button>
            {NoCambioDeNombre ? (
              <>
                {cooldown ? (
                  <button
                    onClick={() => saveDraft(key, id, modulo, cooldown)}
                    disabled={stateRequest}
                    className={stateRequest ? "btn-disabled" : ""}
                  >
                    {t("guardarBorrador")}
                  </button>
                ) : (
                  <button
                    onClick={() => saveDraft(key, id, modulo)}
                    disabled={stateRequest}
                    className={stateRequest ? "btn-disabled" : ""}
                  >
                    {t("guardarBorrador")}
                  </button>
                )}
                {/* {ruta === "massFriender" && (
                  <button
                    onClick={() => saveActive(key, id, modulo, 0, price)}
                    disabled={stateRequest}
                    className={stateRequest ? "btn-disabled" : ""}
                  >
                    Raidear
                  </button>
                )} */}
                {ruta === "massFriender" && (
                  <button
                    onClick={() => setActiveModalRaid(true)}
                    disabled={stateRequest}
                    className={stateRequest ? "btn-disabled" : ""}
                  >
                    Raidear
                  </button>
                )}
              </>
            ) : (
              <>
                {cooldown ? (
                  <>
                    <button
                      onClick={() => saveDraft(key, id, modulo, cooldown)}
                    >
                      {t("guardarBorrador")}
                    </button>
                    <button
                      onClick={() => saveActive(key, id, modulo, cooldown)}
                    >
                      {t("guardar")}
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => saveDraft(key, id, modulo)}>
                      {t("guardarBorrador")}
                    </button>
                    <button onClick={() => saveActive(key, id, modulo)}>
                      {t("guardar")}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
