import axios from "axios";
import {
  notifyErroneo,
  notifyExitoso,
  timeOut,
} from "../../../../Utils/alerts/Alerts";
import { backendURL } from "../../../../config";
import { t } from "i18next";

export const CreateCluster = async (
  email,
  nameCluster,
  setIsSelected,
  setNameCluster
) => {
  try {
    const response = await axios.post(`${backendURL}/create-cluster`, {
      email: email,
      nameCluster: nameCluster,
    });
    if (response.status === 200) {
      setIsSelected(false);
      setNameCluster("");
      notifyExitoso(t("clusterCreada"));
      timeOut(`/dashboard/${response.data.cluster.key}`, 2000);
    } else if (response.status === 205) {
      notifyErroneo(t("clusterNoCreada"));
    }
  } catch (error) {
    console.log(error);
  }
};
