import bots from "./../imgDashboard/bots.webp";
import massDm from "./../imgDashboard/massDm.webp";
import massFriender from "./../imgDashboard/massFriender.webp";
import panel from "./../imgDashboard/panel.webp";
import token_humanizer_img_navbar from "./../imgDashboard/token_humanizer-img_navbar.webp";
import message from "./../imgDashboard/message.webp";
import serverJoinerImg from "./../imgDashboard/serverJoiner.webp";
import guildSpammer from "./../imgDashboard/guildSpammer.webp";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import Spinner from "../../../Spinner";
import "./styles/clusterNavbar.css";

export default function ClusterNavbar({ user, isLoading, handleCodigoChange, arrayCluster }) {
  const [scroll, setScroll] = useState(false);
  const { key } = useParams();
  const [trueNavbarDash, setTrueNavbarDash] = useState(false);
  const [keySelect, setKeySelect] = useState();

  useEffect(() => {
    const selectedCluster = arrayCluster.find((cluster) => cluster.key === key);
    setKeySelect(selectedCluster);
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 30) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [key, arrayCluster]);

  const closeNavbar = () => {
    setTrueNavbarDash(!trueNavbarDash);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="navbar-cluster">
            <div className={`container-navbar-dashboard ${trueNavbarDash ? "active" : ""}`}>
              {user && (
                <div className="filtros">
                  <select id="estadoFilter" value={keySelect?.key} onChange={handleCodigoChange}>
                    <option value={keySelect?.key} hidden>
                      {keySelect?.name}
                    </option>
                    {arrayCluster &&
                      arrayCluster
                        .filter((cluster) => cluster.key !== keySelect?.key)
                        .map((cluster, index) => (
                          <option key={index} value={cluster.key}>
                            {cluster.name}
                          </option>
                        ))}
                  </select>
                </div>
              )}
              <div className="container-info-navbar-dashboard">
                <Link to={`/dashboard/${key}`} onClick={closeNavbar} className="info-navbar-dashboard">
                  <img src={panel} alt="Panel" />
                  <p>{t("panel")}</p>
                </Link>
                <Link to={`/dashboard/${key}/bots`} onClick={closeNavbar} className="info-navbar-dashboard">
                  <img src={bots} alt="Bots" />
                  <p>Self bots</p>
                </Link>
              </div>
              <div className="separator-navbar-dashboard"></div>
              <Link to={`/dashboard/${key}/massFriender`} onClick={closeNavbar} className="info-navbar-dashboard">
                <img src={massFriender} alt="imagen1" />
                <p>Mass Friender</p>
              </Link>
              <p to={`#`} className="MassMD-a">
                <img src={message} alt="imagen1" />
                <span className="">Mass DM</span>
                <span
                  style={{
                    margin: "0",
                    opacity: 1,
                    color: "white",
                    fontSize: "0.5rem",
                  }}
                >
                  {t("PRÓXIMAMENTE")}
                </span>
              </p>
              <Link to={`/dashboard/${key}/serverjoiner`} onClick={closeNavbar} className="info-navbar-dashboard">
                <img src={serverJoinerImg} alt="imagen1" />
                <p>Server Joiner</p>
              </Link>
              <div className="separator-navbar-dashboard"></div>
              <Link to={`/dashboard/${key}/guildSpammer`} onClick={closeNavbar} className="info-navbar-dashboard">
                <img src={guildSpammer} alt="imagen1" />
                <p>Guild Spammer</p>
              </Link>
              <Link to={`/dashboard/${key}/tokenHumanizer`} onClick={closeNavbar} className="info-navbar-dashboard">
                <img src={token_humanizer_img_navbar} alt="imagen1" />
                <p>Token Humanizer</p>
              </Link>
            </div>
          </div>
          {trueNavbarDash ? (
            <>
              <button className="btn-open-dash false" onClick={() => setTrueNavbarDash(false)}>
                <i className="fa-solid fa-circle-left"></i>
              </button>
            </>
          ) : (
            <>
              <button className="btn-open-dash true" onClick={() => setTrueNavbarDash(true)}>
                <i className="fa-solid fa-circle-right"></i>
              </button>
            </>
          )}
        </>
      )}
    </>
  );
}
