import { useEffect, useState } from "react";
import { useParams } from "react-router";
import "../../common/Modulos.css";
import { notifyErroneo, notifyExitoso } from "../../../../../../Utils/alerts/Alerts.js";
import EstadisticasGuildSpammer from "./ModuleGuildSpammer/EstadisticasGuildSpammer.jsx";
import MensajesGuildSpammer from "./ModuleGuildSpammer/MensajesGuildSpammer.jsx";
import CooldownGuildSpammer from "./ModuleGuildSpammer/CooldownGuildSpammer.jsx";
import CanalesGuildSpammer from "./ModuleGuildSpammer/CanalesGuildSpammer.jsx";
import "react-toastify/dist/ReactToastify.css";
import {
  SaveActiveGuildSpammer,
  SaveDraftGuildSpammer,
} from "./ModuleGuildSpammer/functionsModuleGuildSpammer/moduleGuildSpammer.js";
import { deleteModule, orderModule } from "../../common/FuntionsRequest/FunctionsRequest.js";
import BtnsModules from "../../common/BtnsModules.jsx";
import ModalDeleteModule from "../../common/ModalDeleteModule.jsx";
import ModalChangeNameModule from "../../common/ModalChangeNameModule.jsx";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin.js";
import BackgroundModalEffectunfocused from "../../common/BackgroundModalEffectunfocused.jsx";

export default function GuildSpammerModulo({ rutaModulo, ruta }) {
  const { key, id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [ModalchangeName, setModalchangeName] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [modulo, setModulo] = useState({});
  const [cooldown, setCooldown] = useState();
  useEffect(() => {
    orderModule(id, key, setModulo, rutaModulo, ruta);
    validateKeyCluster(key);
  }, []);
  useEffect(() => {
    if (modulo.cooldown && cooldown) {
      const updatedModulo = {
        ...modulo,
        cooldown: {
          minimum: cooldown.minimum,
          maximum: cooldown.maximum,
        },
      };
      setModulo(updatedModulo);
    }
  }, [cooldown]);

  return (
    <>
      <div className="container-principal-herramienta-cluster ">
        <div>
          <BtnsModules
            setModalchangeName={setModalchangeName}
            setIsSelected={setIsSelected}
            modulo={modulo}
            cooldown={cooldown}
            saveActive={SaveActiveGuildSpammer}
            saveDraft={SaveDraftGuildSpammer}
            ruta={"guildSpammer"}
          />
          <BackgroundModalEffectunfocused estado={isSelected} setEstado={setIsSelected} />
          <ModalDeleteModule
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setIsSelectBorradores={setIsSelectBorradores}
            isSelectBorradores={isSelectBorradores}
            id={id}
            key={key}
            notifyExitoso={notifyExitoso}
            notifyErroneo={notifyErroneo}
            deleteModule={deleteModule}
            backPath={true}
            ruta={"guildSpammer"}
            rutaModulo={"GuildSpammer"}
          />
          <BackgroundModalEffectunfocused estado={ModalchangeName} setEstado={setModalchangeName} />
          <ModalChangeNameModule
            id={id}
            modulo={modulo}
            setModulo={setModulo}
            setModalchangeName={setModalchangeName}
            ModalchangeName={ModalchangeName}
          />
          <div className="container-modulos-cluster">
            <EstadisticasGuildSpammer />
            <MensajesGuildSpammer setModulo={setModulo} modulo={modulo} />
            <CooldownGuildSpammer modulo={modulo} cooldown={cooldown} setCooldown={setCooldown} />
            <CanalesGuildSpammer setModulo={setModulo} modulo={modulo} />
          </div>
        </div>
      </div>
    </>
  );
}
