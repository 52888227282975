import axios from "axios";

const fetchUserProfile = async (setUser, backendURL) => {
  try {
    const tokenlogin = localStorage.getItem("tokenlogin");
    if (!tokenlogin) {
      setUser(null);
      return;
    }
    const response = await axios.get(`${backendURL}/account/`, {
      headers: {
        Authorization: `Bearer ${tokenlogin}`,
      },
    });
    if (response.status === 200) {
      setUser(response.data);
    } else if (response.status === 205) {
      setUser(null);
      localStorage.removeItem("tokenlogin");
    }
  } catch (error) {
    if (error.response && error.response.status === 205) {
      setUser(null);
    } else {
      console.log(error);
    }
  }
};
export default fetchUserProfile;
