import { useEffect, useState } from "react";
import tachoBasura from "../../../../imgDashboard/tachoBasura.svg";
import add from "../../../../../../../img/add.webp";
import saveIcon from "../../../../../../../img/save-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import AddMessageImg from "../../../../imgDashboard/img-agregar-servidor.png";
import { t } from "i18next";
import Separador from "../../../common/Separador";

export default function MensajesGuildSpammer({ setModulo, modulo }) {
  const [messages, setMessages] = useState(modulo.messages || []);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editedMessage, setEditedMessage] = useState("");

  useEffect(() => {
    setMessages(modulo.messages || []);
  }, [modulo.messages]);

  const addNewMessage = () => {
    if (!editedMessage.trim()) {
      return;
    }

    if (editMode && editIndex !== null) {
      const updatedMessages = [...messages];
      if (editedMessage !== messages[editIndex]) {
        updatedMessages[editIndex] = editedMessage;
        setModulo((prevModulo) => ({
          ...prevModulo,
          messages: updatedMessages,
        }));
        setEditMode(false);
        setEditIndex(null);
        setEditedMessage("");
      } else {
        setEditMode(false);
        setEditIndex(null);
        setEditedMessage("");
      }
    } else {
      if (!messages.includes(editedMessage)) {
        const updatedMessages = [...messages, editedMessage];
        setModulo((prevModulo) => ({
          ...prevModulo,
          messages: updatedMessages,
        }));
        setEditedMessage("");
      }
    }
    // notifyErroneo(t(editMode ? "mensajeEditadoExitosamente" : "Mensaje Ya existe"));
  };

  const deleteMessage = (index) => {
    setEditMode(false);
    setEditedMessage("");
    const updatedMessages = [...messages];
    updatedMessages.splice(index, 1);
    setModulo((prevModulo) => ({
      ...prevModulo,
      messages: updatedMessages,
    }));
    // notifyExitoso(t("mensajeBorradoExitosamente"));
  };

  const editMessage = (index) => {
    setEditMode(true);
    setEditIndex(index);
    setEditedMessage(messages[index]);
  };

  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>{t("Mensajes")}</h2>
        <p>{t("aleatoriosEnviados")}</p>
      </div>
      <Separador />
      <div className="container-herramient-cluster-title-subtitle">
        <div>
          <h4>{t("tusMensajes")}</h4>
        </div>
        <div className="container-mensajes" style={{ display: "block" }}>
          {messages.length <= 0 || messages === undefined ? (
            <p className="parrafo-no-elements" style={{ textAlign: "center" }}>
              {t("NohayMensajes")}
            </p>
          ) : (
            <>
              {messages.map((mensaje, index) => (
                <div className="mensaje" key={index} style={{ marginBottom: "0.8rem" }}>
                  {editMode && editIndex === index ? (
                    <>
                      <textarea
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                        rows="4"
                        style={{ width: "100%" }}
                      />
                      <button onClick={() => deleteMessage(index)}>
                        <img src={tachoBasura} alt="tachoDeBasura" />
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="mensaje-guardado">
                        <pre style={{ color: "#88898a", whiteSpace: "pre-wrap", padding: "0", boxShadow: "none" }}>
                          <span>{mensaje}</span>
                        </pre>
                        <button onClick={() => deleteMessage(index)} style={{ top: "4%" }}>
                          <img src={tachoBasura} alt="tachoDeBasura" />
                        </button>
                      </div>
                      {/* <div className="mensaje-buttons">
                        <button onClick={() => editMessage(index)}>
                          <img src={editIcon} alt="edit" />
                        </button>
                      </div> */}
                    </>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        <Separador />

        <div>
          <h4>{t("Añadeunnuevomensaje")}</h4>
        </div>
        <div className="container-herramient-cluster-title-subtitle">
          <div className="container-input-mensaje">
            <textarea
              placeholder={t("nuevoMensaje")}
              value={editedMessage}
              onChange={(e) => setEditedMessage(e.target.value)}
              rows="4"
              style={{ width: "100%", height: "auto" }}
            />
            <div className="agregar-message">
              <button onClick={addNewMessage} style={{ cursor: "pointer", outline: "none" }}>
                <img src={AddMessageImg} alt="Add" /> <span>+</span>
              </button>
              {/* <button onClick={addNewMessage} style={{ cursor: "pointer" }}> */}
              {/* <img src={editMode ? saveIcon : add} alt={editMode ? "save" : "add"} /> */}
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
