import "./Separator.css";

const Separator = ({ t }) => {
  return (
    <main className="separador-medio">
      <div className="background-separador"></div>
      <div className="container-separator">
        <div className="container-number-text">
          <div className="sep"></div>
          <div>
            <p className="numbers-separator">15+</p>
            <p className="degrade ">{t(`Productos`)}</p>
          </div>
        </div>
        <div className="container-number-text">
          <div className="sep"></div>
          <div>
            <p className="numbers-separator">200</p>
            <p className="degrade ">{t(`ClientesSatifechos`)}</p>
          </div>
        </div>
        <div className="container-number-text">
          <div className="sep"></div>
          <div>
            <p className="numbers-separator">2 {t(`years`)}</p>
            <p className="degrade ">{t(`ofreciendosoluciones`)}</p>
          </div>
        </div>
        <div className="container-number-text">
          <div className="sep"></div>
          <div>
            <p className="numbers-separator">$20.000+</p>
            <p className="degrade ">{t(`beneficiosdeclientes`)}</p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Separator;
