import { t } from "i18next";
import { Link } from "react-router-dom";

// Navbar component for non-logged-in users
const NotLogNavbar = ({ setMenuBars, handleManuResponse, menuBars, logo }) => {
  return (
    <div className="form-navbar">
      {/* Logo link */}
      <Link to="/" onClick={() => setMenuBars(false)} className="logo">
        <img src={logo} alt="" />
      </Link>
      <div className="container-primeraMitad desactive">
        <div className="list-navbar">
          {/* Link to documentation */}
          <Link to="/" onClick={handleManuResponse}>
            {t("Docs")}
          </Link>
          {/* Link to reviews */}
          <Link to="/" onClick={handleManuResponse}>
            {t("Reviews")}
          </Link>
        </div>
      </div>
      <div className="container-segundaMitad desactive">
        {/* Link to dashboard */}
        <Link to="/dashboard" onClick={handleManuResponse} className="btn-dashboard">
          {t("dash")}
        </Link>
        {/* Link to login page */}
        <Link to="/login" onClick={handleManuResponse} className="btn-login-navbar">
          {t("Login")}
        </Link>
        {/* Link to registration page */}
        <Link to="/register" onClick={handleManuResponse} className="btn-register-navbar">
          {t("Register")}
        </Link>
      </div>
      {/* Responsive version */}
      <div className="container-primeraMitad responsive">
        <div className={`navbar-responsive ${menuBars ? "active" : ""}`}>
          <div className="list-navbar">
            {/* Link to documentation */}
            <Link to="/" onClick={handleManuResponse}>
              {t("Docs")}
            </Link>
            {/* Link to store */}
            <Link to="/store" onClick={handleManuResponse}>
              {t("Store")}
            </Link>
            {/* Link to reviews */}
            <Link to="/" onClick={handleManuResponse}>
              {t("Reviews")}
            </Link>
          </div>
          <div className="container-segundaMitad">
            {/* Link to dashboard */}
            <Link to="/dashboard" onClick={handleManuResponse} className="btn-dashboard">
              {t("Dashboard")}
            </Link>
            {/* Link to login page */}
            <Link to="/login" onClick={handleManuResponse}>
              {t("Log in")}
            </Link>
            {/* Link to registration page */}
            <Link to="/register" onClick={handleManuResponse} className="btn-register-navbar">
              {t("Register")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotLogNavbar;
