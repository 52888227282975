import { useParams } from "react-router";
import { t } from "i18next";
import { useState } from "react";
import "../../../Clusters/common/CommonModulo.css";

const ModalRaidCost = ({
  setActiveModalRaid,
  activeModalRaid,
  price,
  saveActive,
  id,
  modulo,
}) => {
  const { key } = useParams();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setActiveModalRaid(false);
  };

  const handleYesClick = () => {
    setIsDisabled(true);
    saveActive(key, id, modulo, 0, price);
  };

  return (
    <div className={`Modal module ${activeModalRaid ? "view" : ""}`}>
      <h1>{`${t("Elprecioestimadoesde")} ${price}$`}</h1>
      <p style={{ margin: "0", color: "white", fontWeight: "bold" }}>
        {t("DeseaContinuar")}
      </p>
      <div className="container-btn-add-modulo">
        <button
          type="reset"
          style={{
            background: "var(--color--background-btn-navbar)",
          }}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {t("cancelar")}
        </button>
        <button
          style={{ background: "var(--color--azul--dashboard)" }}
          onClick={handleYesClick}
          disabled={isDisabled}
        >
          {t("si")}
        </button>
      </div>
    </div>
  );
};

export default ModalRaidCost;
