import { desactivarModals } from "../../../../Utils/Home/Home";
import ModuloAfiliados from "./ModuloAfiliados";
import "./../Afiliados.css";
import { t } from "i18next";
import Informacion from "./Informacion";
import Descuento from "./Descuento";
import Saldo from "./Saldo";
import NavbarHome from "../../../navbar/NavbarHome";
import Spinner from "../../../Spinner";
import { useState, useEffect } from "react";

const ProgramaAfiliados = ({
  setMenuBars,
  user,
  setUser,
  setSeccionNotify,
  setSeccionLogOut,
}) => {
  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);

  return (
    // <>
    //   {loading ? (
    //     <Spinner />
    //   ) : (
    //     <>
    <div
      className="container-programa-afiliados"
      onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}
    >
      {user && (
        <>
          <h1 style={{ color: "white" }}>{t("programaAfiliados")}</h1> {/* Title of the affiliate program */}
          <Informacion setUser={setUser} user={user} />
          <ModuloAfiliados setUser={setUser} user={user} titulo={t("Enlacedereferidos")} />
          {/* <Descuento setUser={setUser} user={user} /> */}
          <Saldo user={user} />
        </>
      )}
      <div style={{ width: "83%" }}>
        <p style={{ color: "white", opacity: "0.5" }}>*{t("ultimoParrafoAfiliados")}</p> {/* Final paragraph of the affiliate program */}
      </div>
    </div>
    //     </>
    //   )}
    // </>
  );
};

export default ProgramaAfiliados;
