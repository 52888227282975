import "./footer.css";
import logoBanner from "../../../../../img/logoBanner.svg";
import instagram from "../../../../../img/instagram.webp";
import facebook from "../../../../../img/facebook.webp";
import twitter from "../../../../../img/twitter.webp";
import linkedln from "../../../../../img/linkedln.webp";

export default function Footer({ t, handleLanguageChange }) {
  return (
    <section className="containerFooter">
      <div className="primer-footer">
        <div className="container-primera-parte-footer">
          <div>
            <img src={logoBanner} alt="logoBlanco" className="logo-footer" />
          </div>
          <div>
            <p>{t("descripcionFooter")}</p>
          </div>
        </div>
        <div className="container-segunda-parte-footer">
          <div className="container-info-footer">
            <p
              style={{
                color: "white",
                opacity: "1",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              {t("primerTitulo")}
            </p>
            <p>{t("descripcion1")}</p>
            <p>{t("descripcion2")}</p>
            <p>{t("descripcion3")}</p>
            <p>{t("descripcion4")}</p>
            <p>{t("descripcion5")}</p>
          </div>
          <div className="container-info-footer">
            <p
              style={{
                color: "white",
                opacity: "1",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              {t("segundoTitulo")}
            </p>
            <p>{t("descripcion6")}</p>
            <p>{t("descripcion7")}</p>
            <p>{t("descripcion8")}</p>
            <p>{t("descripcion9")}</p>
            <p>{t("descripcion10")}</p>
          </div>
          <div className="container-info-footer">
            <p
              style={{
                color: "white",
                opacity: "1",
                marginBottom: "1rem",
                fontWeight: "bold",
              }}
            >
              {t("tercerTitulo")}
            </p>
            <p>{t("descripcion11")}</p>
            <p>{t("descripcion12")}</p>
          </div>
        </div>
      </div>
      <div className="separator-footer"></div>
      <div className="container-segundo-footer">
        <div className="container-copyright">
          <p style={{ fontFamily: "Abel, sans-serif" }}>Copyright</p>
          <p>© 2022 JikesShop. All Rights Reserved.</p>
        </div>
        <div className="container-redes">
          <img
            src={instagram}
            alt="instagram"
            style={{
              width: "10%",
              height: "10%",
              cursor: "pointer",
            }}
          />
          <img
            src={facebook}
            alt="facebook"
            style={{
              width: "10%",
              height: "10%",
              cursor: "pointer",
            }}
          />
          <img
            src={twitter}
            alt="twitter"
            style={{
              width: "10%",
              height: "10%",
              cursor: "pointer",
            }}
          />
          <img
            src={linkedln}
            alt="linkedln"
            style={{
              width: "10%",
              height: "10%",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      <select onChange={handleLanguageChange} className="changeLanguage">
        <option>{t(`changeIdioma`)}</option>
        <option value="en">English</option>
        <option value="es">Español</option>
      </select>
    </section>
  );
}

{
  /* Agregar más opciones para otros idiomas si es necesario */
}
