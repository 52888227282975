import "./AboutUs.css";
import sombraAboutUs from "../../../../../img/sombraAboutUs.webp";
import comillas from "../../../../../img/comillas.webp";
import persona1 from "../../../../../img/persona1.webp";
import persona2 from "../../../../../img/persona2.webp";
import persona3 from "../../../../../img/persona3.webp";

const AboutUs = ({ t }) => {
  return (
    <main>
      <div className="about-us">
        <div className="background-fondo-about-us"></div>
        <section className="container-about-us">
          <div className="primera-parte-about-us">
            <h1>{t("TituloClientes")}</h1>
            <p>{t("subtituloClientes")}</p>
          </div>
          <div className="segunda-parte-about-us">
            <div className="container-opinion">
              <div>
                <p>{t("comentarioCliente1")}</p>
              </div>
              <div className="container-personas">
                <img src={persona1} alt="persona1" className="personas" />
                <div className="nombres-about-us">
                  <h3>Congresista</h3>
                  <p>Exchanger</p>
                </div>
              </div>
            </div>
            <div className="container-opinion">
              <div>
                <p>{t("comentarioCliente2")}</p>
              </div>
              <div className="container-personas">
                <img src={persona2} alt="persona2" className="personas" />
                <div className="nombres-about-us">
                  <h3>Jack</h3>
                  <p>Loyal cliente</p>
                </div>
              </div>
              <br />
            </div>
            <div className="container-opinion">
              <div>
                <p>{t("comentarioCliente3")}</p>
              </div>
              <div className="container-personas">
                <img src={persona3} alt="persona3" className="personas" />
                <div className="nombres-about-us">
                  <h3>Mats</h3>
                  <p>Trader</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default AboutUs;
