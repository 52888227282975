import { useState } from "react";
import "./ModalBotsTxt.css";
import axios from "axios";
import { useParams } from "react-router";
import { notifyErroneo, notifyEsperando, notifyExitoso, timeOut } from "../../../../../../Utils/alerts/Alerts";
import { t } from "i18next";
import { backendURL } from "../../../../../../config";

const ModalBotsTxt = ({ setIsSelected, isSelected, arrayTokens }) => {
  const [nameCluster, setNameCluster] = useState("");
  let { key } = useParams();

  const saveTokensTxt = async (e) => {
    e.preventDefault();
    try {
      notifyEsperando("los tokens se estan procesando....!");
      setIsSelected(false);
      setNameCluster("");
      const response = await axios.put(`${backendURL}/check-tokens-txt-selfbots`, {
        arrayTokens: arrayTokens,
        key: key,
      });
      if (response.status === 200) {
        notifyExitoso(t("tokensProcesados"));
        timeOut(`/dashboard/${key}/bots`, 2000);
      } else if (response.status === 205) {
        notifyErroneo(t("errorAgregarToken"));
      }
    } catch (error) {
      notifyErroneo(t("errorAgregarToken"));
      console.log(error);
    }
  };
  return (
    <form onSubmit={saveTokensTxt} className={`ModalBotsText ${isSelected ? "view" : ""}`}>
      <h1>{t("agregarToken")}</h1>
      <div className="ContainerTokensBotsText">
        {arrayTokens.map((e, index) => (
          <p key={index}>{e}</p>
        ))}
      </div>
      <div className="container-btn-add-cluster">
        <button
          type="reset"
          style={{
            background: "var(--color--background-btn-navbar)",
          }}
          onClick={() => setIsSelected(false)}
        >
          {t("cancelar")}
        </button>
        <button style={{ background: "var(--color--azul--dashboard)" }} type="submit">
          {t("agregarTodas")}
        </button>
      </div>
    </form>
  );
};

export default ModalBotsTxt;
