import headerhand from "../../../../../img/header-img.webp";
import headerhandbackground from "../../../../../img/header-img-background.webp";
import "./Header.css";

const Header = ({ t, user }) => {
  return (
    <>
      <div className="header">
        <img src={headerhandbackground} alt="Imagen Background jikesShop" className="background-header" />
        <div className="backgound-black"></div>
        <section className="container-header">
          <div className="container-primeraParte">
            <div>
              <div className="container-p-header">
                <p>{t("TituloPrincipalHeader")}</p>
              </div>
              <div className="container-description-header">
                <p>{t("subtituloHeader")}</p>
                <div className="btns-header">
                {!user && <button onClick={() => (window.location.href = "/register")}>{t("ComienzabtnHeader")}</button>}
                {!user ? (
                  <button onClick={() => (window.location.href = "/register")}>{t("DepositFundsbtn")}</button>
                ) : (
                  <button onClick={() => (window.location.href = "/add-funds")}>{t("DepositFundsbtn")}</button>
                )}
              </div>
              </div>
              
            </div>
          </div>
          <div className="container-segundaParte">
            <img src={headerhand} alt="Img header JikesShop" />
          </div>
        </section>
      </div>
    </>
  );
};

export default Header;
