import CommonRaid from "../../CommonRaid";
import { useEffect } from "react";
import { useParams } from "react-router";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin";
import CommonModules from "../../common/CommonModules";

const ServerJoiner = () => {
  const { key } = useParams();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);

  return (
    <>
      {/* <CommonRaid
        NuevoModulo="Nuevo raid"
        tituloCreate="Nuevo raid"
        subtitulo="Raids"
        titulo={"Mass Friender"}
        activeModuleRaid={true}
        ruta={"massFriender"}
        tipoModulo="Historial"
        rutaModulo={"MassFriender"}
      /> */}
      <CommonModules
        NuevoModulo="Nuevo raid"
        tituloCreate="nuevoRaid"
        subtitulo="Raids"
        titulo={"Server Joiner"}
        ruta={"ServerJoiner"}
        rutaModulo={"ServerJoiner"}
      />
      {/* <Raids /> */}
    </>
  );
};

export default ServerJoiner;
