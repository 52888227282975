// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// i18n.use(initReactI18next).init({
//   resources: {
//     en: {
//       translation: {
//         Docs: "Docs",
//         Store: "Store",
//         Reviews: "Reviews",
//         dash: "Dashboard",
//         Login: "Login",
//         loginNavbar: "Log in",
//         Logout: "Log out",
//         Register: "Register",
//         Affiliates: "Affiliates",
//         ajustes: "Settings",
//         historialPagos: "Payment History",
//         nuevosFondos: "Add Funds",
//         productosComprados: "Purchased Products",
//         saludo: "Hello, <User>!",
//       },
//     },
//     es: {
//       translation: {
//         Docs: "Documentos",
//         Store: "Tienda",
//         Reviews: "Reseñas",
//         dash: "Panel",
//         Login: "Iniciar Sesión",
//         Logout: "Cerrar sesión",
//         Register: "Registrarse",
//         Affiliates: "Afiliados",
//         ajustes: "Ajustes",
//         historialPagos: "Historial de Pagos",
//         nuevosFondos: "Añadir Fondos",
//         productosComprados: "Productos Comprados",
//         saludo: "¡Hola, <Usuario>!",
//       },
//     },
//     // Puedes agregar más idiomas aquí con sus respectivas traducciones
//   },
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;

export const TranslateNavbar = {
  en: {
    translation: {
      Docs: "Docs",
      Store: "Store",
      Reviews: "Reviews",
      dash: "Dashboard",
      Login: "Login",
      loginNavbar: "Log in",
      Logout: "Log out",
      Register: "Register",
      Affiliates: "Affiliates",
      ajustes: "Settings",
      historialPagos: "Payment History",
      nuevosFondos: "Add Funds",
      productosComprados: "Purchased Products",
      saludo: "¡Hello!",
      notificaciones: "Notifications",
      noNotificaciones: "No notifications",
    },
  },
  es: {
    translation: {
      Docs: "Documentos",
      Store: "Tienda",
      Reviews: "Reseñas",
      dash: "Panel",
      Login: "Iniciar Sesión",
      Logout: "Cerrar sesión",
      Register: "Registrarse",
      Affiliates: "Afiliados",
      ajustes: "Ajustes",
      historialPagos: "Historial de Pagos",
      nuevosFondos: "Añadir Fondos",
      productosComprados: "Productos Comprados",
      saludo: "¡Hola!",
      notificaciones: "Notificaciones",
      noNotificaciones: "Sin notificaciones",
    },
  },
};
