import { useState } from "react";
import { checkKeyCluster } from "../funtionsDashboard/checkKeyCluster";
import { t } from "i18next";
import Spinner from "../../../Spinner";

export default function FormKey({ isSelected, close }) {
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Desactiva el botón al iniciar la solicitud
    try {
      await checkKeyCluster(key, setLoading);
    } catch (error) {
      console.error("Error creating cluster:", error);
      // Manejo de errores si es necesario
    }
  };
  return (
    <>
      <div
        className={
          isSelected ? "container-form-key-borroso" : "container-form-key"
        }
        onClick={close}
      >
        <form onSubmit={handleSubmit} className="form-key">
          <h1 className="titulo">{t("EnterKey")}</h1>
          <div className="container-input-form-key">
            <p>{t("IntroduceKey")}</p>
            <div className="container-segunda-parte-key">
              <input
                type="text"
                placeholder={t("EnterKeyHere")}
                value={key}
                onChange={(e) => setKey(e.target.value)}
                required
              />
              <button
                style={{
                  background: "var(--color--azul--dashboard)",
                  opacity: loading ? 0.5 : 1,
                  cursor: loading ? "not-allowed" : "pointer",
                }}
                type="submit"
                disabled={loading}
              >
                {loading ? t("cargando") : t("Send")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
