import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { OrderSelfBots } from "../../bots/functionsBots/functionsSelfBots";
import Separador from "../../../common/Separador";
import SelectSelfBotsMassFriender from "./SelectSelfBotsMassFriender";
import pencilEditImg from "../../../../imgDashboard/pencil-edit.svg";
import { t } from "i18next";
export default function SelfBotsMassFriender({ setModulo, modulo }) {
  const [tags, setTags] = useState([]);
  const [selfBots, setSelftBots] = useState([]);
  const { key } = useParams();

  useEffect(() => {
    const orderBots = async () => {
      await OrderSelfBots(setSelftBots, key);
    };
    orderBots();
  }, []);
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>Self Bots</h2>
        <p>{t("SelfBotsText")}</p>
      </div>
      <Separador />
      <div className="container-selfbots-massfrinder-descripcion-nickname">
        <div className="selfbots-massfrinder-descripcion-nickname">
          <h3>{t("Descripción")}</h3>
          <div>
            <input type="text" placeholder={t("Descripcioncuentas")} />
            <img src={pencilEditImg} alt="Lapiz Edit descripción Bots" />
          </div>
        </div>
        <div className="container-selfbots-massfrinder-nickname">
          <h3>Nickname</h3>
          <div>
            <input type="text" placeholder="! R3AD MY DESCRPTION" />
            <img src={pencilEditImg} alt="Lapiz Edit descripción Bots" />
          </div>
        </div>
      </div>
      <Separador />

      <SelectSelfBotsMassFriender
        setTags={setTags}
        tags={tags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        setModulo={setModulo}
        modulo={modulo}
      />

      {/* Sección de etiquetas específicas de agregar */}
      {/* <InputAddChannelsGuildSpammer
        tags={tags}
        setTags={setTags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        modulo={modulo}
        setModulo={setModulo}
      /> */}
    </div>
  );
}
