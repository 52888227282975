export const TranslateMassFriender = {
  en: {
    translation: {
      Conjunto: "Set",
      // Mass Friender
      nuevoRaid: "New Raid",
      borrador: "Draft",
      // Mass Friender 2
      borrar: "Delete",
      guardarBorrador: "Save Draft",
      YaExiste: "Exist",
      estadisticas: "Statistics",
      estadisticasInfoModuloMassFrender:
        "Get detailed information about the price of our services with our updated statistics.",
      precioEstimado: "Estimated price",
      Miembrosdelservidor: "Server Members",
      SelfBotsSugeridos: "Suggested SelfBots",
      Tiempomedio: "Half time",
      Servidores: "Servers",
      ServidoresDescripcion:
        "Indicate which servers you want to send mass friend requests to.",
      NohayInvitaciones: "There are no invitations",
      Seguridad: "Security",
      SeguridadText:
        "Make your raids much safer with the different security options",
      Dispersión: "Dispersion",
      DispersionText:
        "Method created by us that makes bots undetectable when joining a server",
      CooldownText:
        "Time interval between sending a friend request and another",
      SelfBotsText:
        "Select the bots you will use to spam friend requests on the servers",
      Descripción: "Description",
      Descripcioncuentas: "Description of your accounts",
      Cuentasparautlizar: "Accounts to use",
      CuentasparautlizarText: "Self Bots to be used for raiding",
      SeleccionarBot: "Select Bot",
      VerificandoInvitación: "Verifying Invitation",
      miembrosServidores: "Server Members",
      selfBotsMinimos: "Minimal Self Bots",
      selfBotsRecomendados: "Recommended Self Bots",
      mensajesServidores:
        "Indicate which servers you want to send mass direct messages on",
      invitaciones: "Invitations",
      agregarInvitacionybots: "Add Invitations and SelfBots",
      seleccionarBots: "Select the bots you will use to spam the servers",
      NameFormat: "The format must be name#1234.",
      EnProceso: "In Process",
      InvitacionInvalida: "should be https://discord.gg/asdf2as",
      agregarInvitaciones: "add invitations",
      History: "History",
      nohaysuficientesaldo: "there is not enough balance",
      Elprecioestimadoesde: "The estimated price is",
      DeseaContinuar: "Do you wish to continue",
    },
  },
  es: {
    translation: {
      Elprecioestimadoesde: "El precio estimado es de",
      DeseaContinuar: "Desea Continuar",
      nohaysuficientesaldo: "No Hay Suficiente Saldo",
      NameFormat: "El formato debe ser nombre#1234.",
      InvitacionInvalida:
        "El formato es invalido debe ser https://discord.gg/asdf2as",

      Conjunto: "Conjunto",
      //Mass Friender
      nuevoRaid: "Nuevo Raid",
      borrador: "Borrador",
      // Mass Friender 2
      borrar: "Borrar",
      YaExiste: "Ya Existe",
      guardarBorrador: "Guardar Borrador",
      estadisticas: "Estadisticas",
      estadisticasInfoModuloMassFrender:
        "Obtén información detallada sobre el precio de nuestros servicios con nuestras estadísticas actualizadas.",
      precioEstimado: "Precio Estimado",
      Miembrosdelservidor: "Miembros del servidor",
      EnProceso: "En Proceso",
      SelfBotsSugeridos: "SelfBots Sugeridos",
      Tiempomedio: "Tiempo medio",
      Servidores: "Servidores",
      ServidoresDescripcion:
        "Indica en que servidores quieres enviar solicitudes de amistad de forma masiva",
      NohayInvitaciones: "No hay Invitaciones",
      Seguridad: "Seguridad",
      SeguridadText:
        "Convierte tus raideos mucho más seguros con las distintas opciones de seguridad",
      Dispersión: "Dispersión",
      DispersionText:
        "Metodo creado por nosotros que hace indetectable a los bots al unirse a un servidor",
      CooldownText:
        "Intervalo de tiempo entre que se envía una solicitud de amistad y otra",
      SelfBotsText:
        "Selecciona los bots que utilizarás para spamear solicitudes de amistad en los servidores",
      Descripción: "Descripción",
      Descripcioncuentas: "Descripción de tus cuentas",
      Cuentasparautlizar: "Cuentas para utlizar",
      CuentasparautlizarText: "Self Bots que se van a utilizar para raidear",
      SeleccionarBot: "Seleccionar Bot",
      VerificandoInvitación: "Verificando Invitación",
      miembrosServidores: "Miembros Servidores",
      selfBotsMinimos: "Self Bots Mínimos",
      selfBotsRecomendados: "Self Bots Recomendados",
      mensajesServidores:
        "Indica en qué servidores quieres enviar mensajes directos masivos",
      invitaciones: "Invitaciones",
      agregarInvitacionybots: "Agregar Invitaciones y SelfBots",
      agregarInvitacion: "Añadir invitaciones",
      seleccionarBots:
        "Selecciona los bots que utilizarás para spamear los servidores",
      History: "Historial",
    },
  },
};
