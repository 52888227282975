import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { OrderSelfBots } from "../../bots/functionsBots/functionsSelfBots";
import SelectSelfBotsTokenHumanizer from "./SelectSelfBotsTokenHumanizer";
import Separador from "../../../common/Separador";
import { t } from "i18next";

export default function SelfBotsTokenHumanizer({ setModulo, modulo }) {
  const [tags, setTags] = useState([]);
  const [selfBots, setSelftBots] = useState([]);
  const { key } = useParams();

  useEffect(() => {
    const orderBots = async () => {
      await OrderSelfBots(setSelftBots, key);
    };
    orderBots();
  }, []);
  // console.log(selfBots);
  // console.log(modulo);
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>Self Bots</h2>
        <p>{t("SelfBotsText")}</p>
      </div>
      <Separador />
      <SelectSelfBotsTokenHumanizer
        setTags={setTags}
        tags={tags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        setModulo={setModulo}
        modulo={modulo}
      />

      {/* <InputAddChannelsGuildSpammer
        tags={tags}
        setTags={setTags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        modulo={modulo}
        setModulo={setModulo}
      /> */}
    </div>
  );
}
