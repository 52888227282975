import "./QuienesSomos.css";
import iconoComunidad from "../../../../../img/icono-comunidad.svg";
import { t } from "i18next";

export default function QuienesSomos() {
  return (
    <main>
      <section className="container-quienes-somos">
        <div className="primera-parte-quienes-somos">
          <h1>{t("tituloAboutUs")}</h1>
          <p>{t("descripcionAboutUs")}</p>
        </div>
        <div className="segunda-parte-quienes-somos">
          <div>
            <img src={iconoComunidad} alt="Icono comunidadad JikesShop" />
            <h1>{t("Herramientaspublicidad")}</h1>
            <p>{t("HerramientaspublicidadText")}</p>
          </div>
          <div>
            <img src={iconoComunidad} alt="Icono comunidadad JikesShop" />
            <h1>{t("ConvierteteAfiliado")}</h1>
            <p>{t("ConvierteteAfiliadoAboutUstext")}</p>
          </div>
          <div>
            <img src={iconoComunidad} alt="Icono comunidadad JikesShop" />
            <h1>{t("Depositadinero")}</h1>
            <p>{t("DepositadineroText")}</p>
          </div>
        </div>
      </section>
    </main>
  );
}
