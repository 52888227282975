import { useState, useRef } from "react";
import { t } from "i18next";
import pencilEditImg from "../../../../imgDashboard/pencil-edit.svg";
import sinFoto from "../../../../../../../img/sin-foto.png";
import { notifyErroneo } from "../../../../../../../Utils/alerts/Alerts";

export default function ButtonsSelfbotsUser({ setModulo, modulo }) {
  const [descripcion, setDescription] = useState(modulo.description || "");
  const [nickname, setNickname] = useState(modulo.nickname || "");
  const [photoPreview, setPhotoPreview] = useState(modulo.photo || "");

  // Estado para controlar si un campo es editable
  const [editableField, setEditableField] = useState(null);

  // Referencias para los inputs
  const descriptionRef = useRef(null);
  const nicknameRef = useRef(null);
  const photoInputRef = useRef(null); // Nueva referencia para el input de archivo

  // Expresión regular para validar el nombre de usuario de Discord
  const discordUsernameRegex = /^.{3,32}#[0-9]{4}$/;

  // Función para guardar los datos en el objeto modulo
  const handleSaveDescription = () => {
    setModulo({
      ...modulo,
      description: descripcion,
    });
    setEditableField(null); // Desactiva la edición después de guardar
  };

  const handleSaveNickname = () => {
    // Validar el nickname
    if (discordUsernameRegex.test(nickname)) {
      setModulo({
        ...modulo,
        nickname: nickname,
      });
      setEditableField(null); // Desactiva la edición después de guardar
    } else {
      notifyErroneo(t("NameFormat"));
      // Aquí puedes agregar lógica para mostrar un mensaje de error al usuario si lo deseas
    }
  };

  // Función para manejar el cambio en el input de archivo
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Actualiza el estado y el módulo directamente
        setPhotoPreview(reader.result);
        setModulo({
          ...modulo,
          photo: reader.result,
        });
      };
      reader.readAsDatabackendURL(file);
    }
  };

  // Función para manejar el clic en el ícono del lápiz y enfocar el campo correspondiente
  const handleEditClick = (field) => {
    setEditableField(field);
    if (field === "description" && descriptionRef.current) {
      descriptionRef.current.focus();
    } else if (field === "nickname" && nicknameRef.current) {
      nicknameRef.current.focus();
    } else if (field === "photo" && photoInputRef.current) {
      photoInputRef.current.click(); // Abre el selector de archivos al hacer clic en el lápiz
    }
  };

  return (
    <div className="container-selfbots-massfrinder-descripcion-nickname">
      <div className="selfbots-massfrinder-descripcion-nickname">
        <h3>{t("Descripción")}</h3>
        <div>
          <textarea
            name="description"
            ref={descriptionRef}
            placeholder={t("Descripcioncuentas")}
            value={descripcion}
            onChange={(e) => setDescription(e.target.value)}
            readOnly={editableField !== "description"}
            maxLength={150}
            style={{
              width: "250px",
              minHeight: "100px",
              background: "#1b1c1f",
              borderRadius: "15px",
              padding: "1rem",
              outline: "none",
              color: "white",
              resize: "vertical",
            }}
          />
          {editableField === "description" ? (
            <button
              style={{ cursor: "pointer", background: "none", border: "none" }}
              onClick={handleSaveDescription}
            >
              <i className="fa-solid fa-floppy-disk"></i>
            </button>
          ) : (
            <button
              style={{ cursor: "pointer", background: "none", border: "none" }}
              onClick={() => handleEditClick("description")}
            >
              <img src={pencilEditImg} alt="Lapiz Edit descripción Bots" />
            </button>
          )}
        </div>
      </div>
      <div className="container-selfbots-massfrinder-nickname">
        <h3>Nickname</h3>
        <div>
          <input
            type="text"
            ref={nicknameRef}
            placeholder="nombre#1234"
            value={nickname}
            onChange={(e) => setNickname(e.target.value)}
            readOnly={editableField !== "nickname"}
            maxLength={37} // Limita la longitud máxima considerando el formato nombre#1234
          />
          {editableField === "nickname" ? (
            <button
              style={{ cursor: "pointer", background: "none", border: "none" }}
              onClick={handleSaveNickname}
            >
              <i className="fa-solid fa-floppy-disk"></i>
            </button>
          ) : (
            <button
              style={{ cursor: "pointer", background: "none", border: "none" }}
              onClick={() => handleEditClick("nickname")}
            >
              <img src={pencilEditImg} alt="Lapiz Edit nickname Bots" />
            </button>
          )}
        </div>
      </div>
      <div className="container-selfbots-massfrinder-photo">
        <h3>Photo</h3>
        <div>
          {photoPreview ? (
            <img
              src={photoPreview}
              alt="Preview jikesshop"
              className="img-massfriender"
              style={{ width: "100px", height: "100px" }}
            />
          ) : (
            <div>
              <img
                src={sinFoto}
                className="img-massfriender"
                alt="Foto Perfil jikesshop"
              />
            </div>
          )}
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            id="photo-upload"
            ref={photoInputRef} // Asociar la referencia con el input
            onChange={handlePhotoChange}
          />
          <label htmlFor="photo-upload" style={{ cursor: "pointer" }}>
            <img src={pencilEditImg} alt="Lapiz Edit foto Bots" />
          </label>
        </div>
      </div>
    </div>
  );
}
