import { t } from "i18next";
import "./ProgramaAfiliados.css";
import SeparadorAfiliados from "./SeparadorAfiliados";
import ProgressWeek from "./ProgressWeek";

const Informacion = ({ user }) => {
  return (
    <>
      {user.affiliates && ( // Check if the user has affiliate information
        <div className="container-modulo-afiliados">
          <div className="subtitulo-afiliados">
            <h2>{t("informacion")}</h2> {/* Header for the information section */}
          </div>
          <SeparadorAfiliados />
          <div className="container-informacion-afiliados">
            <div className="container-info-numbers-afiliados">
              <div>
                <h1>${user.affiliates.totalEarnings}</h1> {/* Display total earnings */}
                <p>{t("gananciasTotales")}</p> {/* Label for total earnings */}
              </div>
              <div>
                <h1>{user.affiliates.rank}</h1> {/* Display user's rank */}
                <p>{t("Rangosemanal")}</p> {/* Label for weekly rank */}
              </div>
              <div>
                <h1>{user.affiliates.percentage}%</h1> {/* Display percentage */}
                <p>{t("Porcentaje")}</p> {/* Label for percentage */}
              </div>
            </div>
            <div className="details-afiliados">
              <p>{t(`afiliadosInformatcion`)}</p> {/* Additional information about affiliates */}
            </div>
          </div>
          <SeparadorAfiliados />
          <ProgressWeek /> {/* Component showing progress for the week */}
        </div>
      )}
    </>
  );
};

export default Informacion;
