import i18next from "i18next";
export const handleLanguageChange = (e) => {
	changeLanguage(e.target.value);
	window.location.href = "/";
};


export const changeLanguage = (selectedLanguage) => {
	if (selectedLanguage !== "es" && selectedLanguage !== "en") {
		return;
	}
	i18next.changeLanguage(selectedLanguage);
	localStorage.setItem("language", selectedLanguage);
};

export const loadingLanguage = async (setLoading) => {
	let language;
	const browserLanguage = navigator.language.split('-')[0];

	if (browserLanguage === 'es' || browserLanguage === 'en') language = browserLanguage;
	else language = 'en';
	  
	localStorage.setItem("language", language);
	i18next.changeLanguage(language);
	window.location.reload();
	setLoading(false);
};
