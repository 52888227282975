import { useParams } from "react-router";
import { t } from "i18next";
import "./CommonModulo.css";

const ModalDeleteModule = ({
  id,
  setIsSelectBorradores,
  isSelectBorradores,
  isSelected,
  setIsSelected,
  setArrayModulesActive,
  setArrayModulesBorradores,
  arrayModulesHistory,
  deleteModule,
  ruta,
  rutaModulo,
  backPath,
}) => {
  const { key } = useParams();
  const handleClick = (e) => {
    e.stopPropagation();
    setIsSelected(false);
    setIsSelectBorradores(false);
  };
  return (
    <div
      className={`Modal module ${isSelected ? "view" : ""} ${
        isSelectBorradores ? "ver" : ""
      }`}
    >
      <h1>{t("seguroEliminar")}</h1>
      <div className="container-btn-add-modulo">
        <button
          type="reset"
          style={{
            background: "var(--color--background-btn-navbar)",
          }}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {t("cancelar")}
        </button>
        <button
          style={{ background: "var(--color--azul--dashboard)" }}
          onClick={() =>
            deleteModule(
              id,
              key,
              setIsSelected,
              setIsSelectBorradores,
              setArrayModulesActive,
              setArrayModulesBorradores,
              ruta,
              rutaModulo,
              backPath,
              arrayModulesHistory
            )
          }
        >
          {t("si")}
        </button>
      </div>
    </div>
  );
};

export default ModalDeleteModule;
