import { useState, useRef, useEffect } from "react";
import { desactivarModals } from "../../../Utils/Home/Home.js";
import NavbarHome from "../../navbar/NavbarHome.jsx";
import "./AddFunds.css";
import visa from "./img/visa.webp";
import mastercard from "./img/Mastercard.webp";
import googleplay from "./img/GooglePay.webp";
import cripto from "./img/cripto.webp";
import img_DEPOSIT_principal from "./img/img-DEPOSIT-principal.webp";
import { backendURL } from "../../../config.js";
import arrow_down from "./img/free-arrow-down-icon.png";
import axios from "axios";
import Spinner from "../../Spinner.jsx";
import "../../../Translates/TranslateProductsStore.jsx";
import { t } from "i18next";

export default function AddFunds({
  setMenuBars,
  setSeccionNotify,
  setSeccionLogOut,
}) {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [money, setMoney] = useState(100);
  // const [loading, setLoading] = useState(true);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem("tokenlogin");

      if (!money) {
        return;
      } else if (money === 0 || money < 0) {
        return;
      }

      const response = await axios.post(`${backendURL}/order-payment-cryptomus`, {
        amount: `${money}`,
        currency: "USD",
        token,
        type: "deposit",
      });

      window.location.href = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "-" || e.key === "e") {
      e.preventDefault();
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 500);
  // }, []);
  const handleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };
  return (
    // <>
    //   {loading ? (
    //     <Spinner />
    //   ) : (
    <>
      <div
        className="container-payment-details"
        onClick={() =>
          desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)
        }
      >
        <div className="deposit-container">
          <h1 className="titulo-addfunds">{t("Añadirfondos")}</h1>
          <h3>{t("Pagafacilrápido")}</h3>
          <p>
            {t("addFundsText1")}
            <br /> {t("addFundsText2")}
          </p>
          <h3>{t("Consiguecriptomonedas")}</h3>
          <br />
          <div className="container-img-deposit">
            <div
              className="img-deposit-one"
              style={{ backgroundColor: "#1f2023" }}
            >
              <div>
                <img
                  src={mastercard}
                  alt="Logo MasterCard AddFunds Jikesshop"
                />
                <img src={visa} alt="Logo Visa AddFunds Jikesshop" />
                <img
                  src={googleplay}
                  alt="Logo googleplay AddFunds Jikesshop"
                />
              </div>
              <button
                onClick={() =>
                  (window.location.href = "https://www.moonpay.com")
                }
              >
                Moonpay
              </button>
            </div>
            <div
              className="img-deposit-two"
              style={{ backgroundColor: "#0a398e" }}
            >
              <div>
                <div>
                  <h4>{t("Cryptohere")}</h4>
                  <p>{t("Addyourdesiredamount")}</p>
                </div>
                <img src={cripto} alt="Logo Cripto AddFunds Jikesshop" />
              </div>
              <button
                onClick={() =>
                  (window.location.href =
                    "https://simpleswap.io/?ref=788d5b0e071f ")
                }
              >
                Simpleswipes
              </button>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="container">
            <div className="desplegables-deposit">
              <hr />
              <div
                className={`desplegable-deposit ${
                  activeQuestion === 0 ? "active" : ""
                }`}
              >
                <p>{t("pregunta1addfunds")}</p>
                <button
                  onClick={() => handleQuestion(0)}
                  className={activeQuestion === 0 ? "rotate" : ""}
                >
                  <img src={arrow_down} alt="Flecha Deposit jikesshop" />
                </button>
                {activeQuestion === 0 && (
                  <div className="answer">
                    {/* Contenido de la respuesta aquí */}
                    {t("respuesta1addfunds")}
                  </div>
                )}
              </div>
              <hr />
              <div
                className={`desplegable-deposit ${
                  activeQuestion === 1 ? "active" : ""
                }`}
              >
                <p>{t("pregunta2addfunds")}</p>
                <button
                  onClick={() => handleQuestion(1)}
                  className={activeQuestion === 1 ? "rotate" : ""}
                >
                  <img src={arrow_down} alt="Flecha Deposit jikesshop" />
                </button>
                {activeQuestion === 1 && (
                  <div className="answer">
                    {/* Contenido de la respuesta aquí */}
                    {t("respuesta2addfunds")}
                  </div>
                )}
              </div>
              <hr />
            </div>
          </div>
        </div>

        <div className="deposit-container">
          <div className="two-part-deposit">
            <h2 className="add-funds-titulo">{t("Depositar")}</h2>
            <h4>{t("Cantidad")}</h4>
            <div className="money-input">
              <input
                type="number"
                value={money}
                min={0}
                step="any"
                onChange={(e) => setMoney(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <span>USD</span>
            </div>
            <div className="img-container-deposit-right">
              <img src={img_DEPOSIT_principal} alt="" />
            </div>
            <div className="description-container-deposit">
              <h3>{t("PleaseNote")}</h3>
              <p>{t("PleaseNoteText")}</p>
            </div>
            <div className="total">
              <h3>{t("TOTAL")}</h3> <span>{money} USD</span>
            </div>
            <button className="paynow-button" onClick={handleSubmit}>
              {t("PagarAhora")}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
    //   )}
    // </>
  );
}
