import { t } from "i18next";
import "./CommonModulo.css";
import tachoBasura from "./../../imgDashboard/tachoBasura.svg";
import add from "../../../../../img/add.webp";
import { useEffect, useState } from "react";
import { timeOut } from "../../../../../Utils/alerts/Alerts";
import { useParams } from "react-router";
import Separador from "./Separador";
import ModalDeleteModule from "./ModalDeleteModule";
import {
  OrderModules,
  CreateModule,
  deleteModule,
} from "./../common/FuntionsRequest/FunctionsRequest.js";
import ParteModulos from "./ParteModulos.jsx";
import Spinner from "../../../../Spinner.jsx";
import BackgroundModalEffectunfocused from "./BackgroundModalEffectunfocused.jsx";

const CommonModules = ({
  titulo,
  ruta,
  rutaModulo,
  activeModuleRaid,
  tituloCreate,
  subtitulo,
  tipoModulo,
}) => {
  const [arrayModulesActive, setArrayModulesActive] = useState([{}]);
  const [arrayModulesBorradores, setArrayModulesBorradores] = useState([{}]);
  const [arrayModulesHistory, setArrayModulesHistory] = useState([{}]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [ModuleId, setModuleId] = useState();
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false); // Estado para manejar el botón deshabilitado
  const { key } = useParams();

  const handleClickModulo = async (e, id) => {
    const clickedElement = e.target.tagName.toLowerCase() === "button";
    if (clickedElement) {
      deleteModule(
        id,
        key,
        setIsSelected,
        setIsSelectBorradores,
        setArrayModulesActive,
        setArrayModulesBorradores,
        ruta,
        rutaModulo,
        setArrayModulesHistory
      );

      return;
    }
    timeOut(`/dashboard/${key}/${ruta}/${id}`, 200);
  };

  const handleAddModuleClick = () => {
    if (!buttonDisabled) {
      setButtonDisabled(true); // Deshabilita el botón
      CreateModule(
        key,
        ruta,
        rutaModulo,
        setArrayModulesActive,
        setArrayModulesBorradores
      );
    }
  };

  useEffect(() => {
    OrderModules(
      key,
      setArrayModulesActive,
      setArrayModulesBorradores,
      rutaModulo,
      setArrayModulesHistory
    );
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-principal-herramienta-cluster">
          <div className="container-info-panel-cluster">
            <div>
              <h1>{titulo}</h1>
              <p>{t("Informacion")}</p>
            </div>
          </div>
          <div className="container-externo-herramienta-cluster">
            <div
              className={`btn-add-module ${buttonDisabled ? "disabled" : ""}`} // Aplicar clase CSS si el botón está deshabilitado
              onClick={handleAddModuleClick}
            >
              <h4>{t(tituloCreate)}</h4>
              <button disabled={buttonDisabled}>
                <img src={add} alt="add" />
              </button>
            </div>
            <div className="container-herramienta-cluster">
              <div className="container-info-herramientas-clusters">
                <h3>{t(subtitulo)}</h3>
                <p>{t("Conjunto")}</p>
              </div>
              {activeModuleRaid && arrayModulesActive.length < 0 && (
                <ParteModulos
                  setArrayModulesActive={setArrayModulesActive}
                  setArrayModulesBorradores={setArrayModulesBorradores}
                  rutaModulo={rutaModulo}
                  isSelected={isSelected}
                  setModuleId={setModuleId}
                  setIsSelected={setIsSelected}
                  arrayModulesActive={arrayModulesActive}
                  handleClickModulo={handleClickModulo}
                />
              )}

              <Separador />
              <div className="parte-modulos">
                <h4>{t("Borradores")}</h4>
                {!arrayModulesActive || arrayModulesBorradores.length === 0 ? (
                  <p className="parrafo-no-elements">{t("noElementos")}</p>
                ) : (
                  <div className="modulos">
                    <BackgroundModalEffectunfocused
                      estado={isSelectBorradores}
                      setEstado={setIsSelectBorradores}
                    />
                    {arrayModulesBorradores.map((modulo, index) => (
                      <div key={index}>
                        <div
                          className="modulo"
                          key={index}
                          onClick={(e) => handleClickModulo(e, modulo._id)}
                        >
                          <div>
                            {!modulo.name ? (
                              <h4>{t("modulo")}</h4>
                            ) : (
                              <h4>{modulo.name}</h4>
                            )}
                          </div>
                          <div className="parte-btn-delete draft">
                            <p className="borrador">{t("borrador")}</p>
                            <button
                              className="btn-delete-spammer"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsSelectBorradores(true);
                                setModuleId(modulo._id);
                              }}
                            >
                              <img src={tachoBasura} alt="tachoDeBasura" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <ModalDeleteModule
                  id={ModuleId}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  setIsSelectBorradores={setIsSelectBorradores}
                  isSelectBorradores={isSelectBorradores}
                  setArrayModulesActive={setArrayModulesActive}
                  deleteModule={deleteModule}
                  setArrayModulesBorradores={setArrayModulesBorradores}
                  ruta={ruta}
                  rutaModulo={rutaModulo}
                />
              </div>
              <Separador />
              <div className="parte-modulos">
                <h4>
                  {titulo === "Mass Friender" ? t("EnProceso") : t("Activos")}
                </h4>

                {!arrayModulesActive || arrayModulesActive.length === 0 ? (
                  <p className="parrafo-no-elements">{t("noElementos")}</p>
                ) : (
                  <div className="modulos">
                    <BackgroundModalEffectunfocused
                      estado={isSelected}
                      setEstado={setIsSelected}
                    />
                    {!arrayModulesActive ||
                      (arrayModulesActive.length > 0 &&
                        arrayModulesActive.map((modulo, index) => (
                          <div
                            key={index}
                            className="modulo"
                            // onClick={(e) => handleClickModulo(e, modulo._id)}
                            onClick={
                              titulo !== "Mass Friender"
                                ? (e) => handleClickModulo(e, modulo._id)
                                : null
                            }
                          >
                            <div className="info-modulo">
                              <div className="info-modulo">
                                {!modulo.name ? (
                                  <h4>{t("modulo")}</h4>
                                ) : (
                                  <h4>{modulo.name}</h4>
                                )}
                              </div>
                            </div>
                            {/* <div className="parte-btn-delete">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsSelected(true);
                                  setModuleId(modulo._id);
                                }}
                              >
                                <img src={tachoBasura} alt="tachoDeBasura" />
                              </button>
                            </div> */}
                          </div>
                        )))}
                  </div>
                )}
              </div>
              <Separador />
              {/* {//raid in history//} */}
              {titulo === "Mass Friender" && (
                <div className="parte-modulos">
                  <h4>{t("History")}</h4>
                  {!arrayModulesHistory || arrayModulesHistory.length === 0 ? (
                    <p className="parrafo-no-elements">{t("noElementos")}</p>
                  ) : (
                    <div className="modulos">
                      <BackgroundModalEffectunfocused
                        estado={isSelectBorradores}
                        setEstado={arrayModulesHistory}
                      />
                      {arrayModulesHistory.map((modulo, index) => (
                        <div key={index}>
                          <div
                            className="modulo"
                            key={index}
                            // onClick={(e) => handleClickModulo(e, modulo._id)}
                          >
                            <div className="date-module-history">
                              <div>
                                <span>id: {modulo.id}</span>
                                <span>Duration: {modulo.duration}</span>
                                <span>Date: {formatDate(modulo.date)}</span>
                              </div>
                              <div>
                                <h2>Users:</h2>
                                <div>
                                  {modulo.users && (
                                    <>
                                      <span>Cost: {modulo.users.cost}</span>
                                      <span>
                                        Failure: {modulo.users.failure}
                                      </span>
                                      <span>Total: {modulo.users.total}</span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="parte-btn-delete draft">
                              {/* <p className="borrador">{t("borrador")}</p> */}
                              {/* <button
                                className="btn-delete-spammer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsSelectBorradores(true);
                                  setModuleId(modulo._id);
                                }}
                              >
                                <img src={tachoBasura} alt="tachoDeBasura" />
                              </button> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <ModalDeleteModule
                    id={ModuleId}
                    isSelected={isSelected}
                    setIsSelected={setIsSelected}
                    setIsSelectBorradores={setIsSelectBorradores}
                    isSelectBorradores={isSelectBorradores}
                    arrayModulesHistory={arrayModulesHistory}
                    deleteModule={deleteModule}
                    setArrayModulesHistory={setArrayModulesHistory}
                    ruta={ruta}
                    rutaModulo={rutaModulo}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonModules;
