export const TranslateGuildSpammer = {
  en: {
    translation: {
      // Guild Spammer
      nuevoModulo: "New Module",
      activos: "Actives",
      borradores: "Drafts",
      modulo: "Module",
      modulos: "Modules",
      creandoModulo: "Creating Module",
      moduloCreado: "Module created successfully",
      errorCreandoModulo: "Error creating a module",
      canales: "Channels",
      seleccionarCanales: "Select the channels where the bots will spam",
      si: "Yes",
      NohaySelfBots: "There are no SelfBots",
      addTags: "Add tags...",
      seleccioneBot: "Select a bot...",
      intervaloTiempo: "Time interval between one message being sent and another",
      segundos: "Seconds",
      minutos: "Minutes",
      maximoIntervalo: "Maximum interval",
      precio: "Price",
      maximo: "Maximum",
      minimo: "Minimum",
      estimado: "Estimated",
      nuevoMensaje: "Add a new message",
      mensajeAgregadoExitosamente: "Message added successfully",
      mensajeBorradoExitosamente: "Successfully deleted message",
      nuevoNombre: "New name",
      cambiar: "Change",
      Mensajes: "Messages",
      Añadeunnuevomensaje: "Add a new message",
      errorGuardarModulo: "Error saving module",
      guardadoExitosamente: "Saved correctly",
      aleatoriosEnviados: "Random messages sent to spam channels",
      tusMensajes: "Your messages",
      NohayMensajes: "No messages",
      CooldownText: "Time interval between one message being sent and another",
      Añadecanalesnuevos: "Add new channels",
      nombreCambiado: "Name Temporarily changed",
      seguroEliminar: "Are you sure you want to Delete?",
    },
  },
  es: {
    translation: {
      //Guild Spammer
      nuevoModulo: "Nuevo Módulo",
      activos: "Activos",
      borradores: "Borradores",
      modulo: "Módulo",
      modulos: "Modulos",
      creandoModulo: "Creando Modulo",
      moduloCreado: "Module creado exitosamente",
      errorCreandoModulo: "Error al crear el modulo",
      canales: "Canales",
      seleccionarCanales: "Seleccione los canales donde los bots enviarán spam",
      si: "Sí",
      addTags: "Añadir tags...",
      seleccioneBot: "Selecciona un bot...",
      intervaloTiempo: "Intervalo de tiempo entre el envío de un mensaje y otro",
      segundos: "Segundos",
      minutos: "Minutos",
      NohaySelfBots: "No hay SelfBots",
      Añadecanalesnuevos: "Añadecanalesnuevos",
      maximoIntervalo: "Intervalo máximo",
      precio: "Precio",
      maximo: "Máximo",
      minimo: "Mínimo",
      estimado: "Estimado",
      nuevoMensaje: "Agregar un nuevo mensaje",
      mensajeAgregadoExitosamente: "Mensaje agregado exitosamente",
      mensajeBorradoExitosamente: "Mensaje eliminado exitosamente",
      nuevoNombre: "Nuevo nombre",
      cambiar: "Cambiar",
      Mensajes: "Mensajes",
      CooldownText: "Intervalo de tiempo entre que se envía un mensaje y otro",
      Añadeunnuevomensaje: "Añade un nuevo mensaje",
      errorGuardarModulo: "Error al guardar el módulo",
      guardadoExitosamente: "Guardado correctamente",
      aleatoriosEnviados: "Mensajes aleatorios enviados a canales de spam",
      tusMensajes: "Tus mensajes",
      NohayMensajes: "No hay Mensajes",
      nombreCambiado: "Nombre Cambiado temporalmente",
      seguroEliminar: "¿Seguro que desea Eliminar?",
    },
  },
};
