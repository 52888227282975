export const TranslateLoginRegister = {
  en: {
    translation: {
      // Login and Register
      Login: "Log In",
      UserName: "Username",
      Password: "Password",
      RepeatPassword: "Repeat password",
      correo: "Mail",
      Back: "Back",
      crearCuenta: "Create an account",
      correoOrUserName: "Email or Username",
      haveAccount: "Don't have an account?",
      rememberMe: "Remember Me",
      forgotPassword: "Forgot Password?",
      signIn: "Sign In",
      sesionIniciada: "Session Started",
      mailIncorrecto: "The email or password is incorrect",
      registrado: "It has been successfully registered",
      emailExistente: "Email or username already exists",
      noCoinciden: "Passwords do not match",
      ElusuarioNoexiste: "The User Not exist",
      nuevo: "New",
      guardar: "Save",
      aceptarTerminos: "By clicking Register you agree to our terms of service and conditions.",
    },
  },
  es: {
    translation: {
      // Login and Register
      Login: "Iniciar sesión",
      UserName: "Nombre de Usuario",
      Password: "Contraseña",
      RepeatPassword: "Repetir Contraseña",
      correo: "Correo electrónico",
      Back: "Volver",
      crearCuenta: "Crea una cuenta",
      correoOrUserName: "Correo electrónico or Nombre de usuario",
      haveAccount: "¿No tenes cuenta?",
      rememberMe: "Recordame",
      forgotPassword: "¿Olvidaste la constraseña?",
      signIn: "Ingresar",
      sesionIniciada: "Sesión Iniciada",
      mailIncorrecto: "El mail o la contraseña son incorrectos",
      registrado: "Registrado Con Exito",
      emailExistente: "Email o username Ya Existe",
      noCoinciden: "Las contraseñas no coinciden",
      ElusuarioNoexiste: "El usuario No existe",
      nuevo: "Nuevo",
      guardar: "Guardar",
      aceptarTerminos: "Al hacer clic en Registrarte aceptas nuestros términos de servicio y condiciones.",
    },
  },
};
