import ClusterNavbar from "../../ClusterNavbar";
import "./TokenHumanizer.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { desactivarModals } from "./../../../../../../Utils/Home/Home.js";
import CommonModules from "../../common/CommonModules.jsx";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin.js";
import { useEffect } from "react";
import { useParams } from "react-router";
import NavbarHome from "../../../../../navbar/NavbarHome.jsx";

const TokenHumanizer = ({}) => {
  const { key } = useParams();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);
  return (
    <>
      <CommonModules
        NuevoModulo="nuevoModulo"
        subtitulo="modulos"
        tituloCreate="nuevoModulo"
        titulo={"Token Humanizer"}
        ruta={"tokenHumanizer"}
        rutaModulo={"TokenHumanizer"}
      />
    </>
  );
};

export default TokenHumanizer;
