import "./Bots.css";
import SelfBots from "./SelfBots";
import BotDiscord from "./BotDiscord";
import { desactivarModals } from "./../../../../../../Utils/Home/Home.js";
import { useParams } from "react-router";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin.js";
import { useEffect } from "react";

const Bots = ({ user, setUser }) => {
  const { key } = useParams();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);

  return (
    <>
      <SelfBots />
      {/* <BotDiscord t={t}  /> */}
    </>
  );
};

export default Bots;
