import { Link } from "react-router-dom";
import MenuPerfil from "./MenuPerfil";
import MenuNotificaciones from "./MenuNotificaciones";
import { appearNotifies, appearLogOut, handleLogout, desactivarModals } from "./../../../Utils/Home/Home.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { backendURL } from "../../../config.js";
import fetchUserProfile from "../../../Utils/fetchUserProfile.js";
import { orderNotifications } from "../../../Utils/orderNotification.js";

const LogNavbar = ({
  logo,
  setMenuBars,
  handleManuResponse,
  menuBars,
  cash,
  logout,
  notify,
  seccionNotify,
  seccionLogOut,
  setSeccionLogOut,
  setSeccionNotify,
}) => {
  // State to hold formatted price and user info
  const [formattedPrice, setFormattedPrice] = useState(0);
  const [user, setUser] = useState();
  const [notificationsItem, setNotificationsItem] = useState();

  // Fetch notifications on component mount
  const orderNotification = async () => {
    await orderNotifications(setNotificationsItem);
  };

  useEffect(() => {
    orderNotification();
  }, []);
  
  // Fetch user profile information on component mount
  useEffect(() => {
    fetchUserProfile(setUser, backendURL);
  }, []);
  
  // Update formatted price when user data changes
  useEffect(() => {
    if (user) {
      setFormattedPrice(user.balance);
    }
  }, [user]);

  // Handle menu interactions
  const handleMenus = () => {
    setMenuBars(false);
    setSeccionLogOut(false);
    setSeccionNotify(false);
  };

  return (
    <div className="form-navbar">
      {/* Logo and main link */}
      <Link to="/" onClick={handleMenus} className="logo">
        <img src={logo} alt="Logo JikesShop" onClick={seccionLogOut} />
      </Link>
      
      {/* Main menu */}
      <div
        className="container-primeraMitad desactive"
        onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}
      >
        <div className="list-navbar" onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}>
          <Link to="/" onClick={handleManuResponse}>
            {t("Docs")}
          </Link>
          {/* Commented out for now */}
          {/* <Link to="/store" onClick={handleManuResponse}>
            {t("Store")}
          </Link> */}
          <Link to="/" onClick={handleManuResponse}>
            {t("Reviews")}
          </Link>
        </div>
      </div>
      
      {/* Second half of the menu */}
      <div className="container-segundaMitad desactive">
        <div
          style={{ display: "flex" }}
          onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}
        >
          <Link to="/dashboard" className="btn-dashboard separado">
            {t("dash")}
          </Link>
          <Link to="/afiliados" className="btn-dashboard separado">
            {t("Affiliates")}
          </Link>
        </div>
        <div className="separator"></div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginLeft: "1rem",
            gap: "1rem",
          }}
        >
          <Link to="/add-funds">
            <div className="container-cash" onClick={() => setMenuBars(false)}>
              <img src={cash} alt="" />
              <p>${formattedPrice.toFixed(2)}</p>
            </div>
          </Link>
          <div
            style={{ display: "flex", gap: "0.5rem" }}
          >
            <div className="container-notify">
              <button className="logout-btn" onClick={() => appearNotifies(seccionNotify, setSeccionNotify, setSeccionLogOut)}>
                <img src={notify} alt="Notify" />
              </button>
              <MenuNotificaciones seccionNotify={seccionNotify} notifiacionesItem={notificationsItem} />
            </div>
            <div>
              <button className="logout-btn" onClick={() => appearLogOut(seccionLogOut, setSeccionLogOut, setSeccionNotify)}>
                <img src={logout} alt="" title="Logout" />
              </button>
              <MenuPerfil user={user} seccionLogOut={seccionLogOut} handleLogout={handleLogout} />
            </div>
          </div>
        </div>
      </div>
      
      {/* Responsive menu */}
      <div className="container-primeraMitad responsive">
        <div className={`navbar-responsive ${menuBars ? "active" : ""}`}>
          <div className="list-navbar">
            <Link to="/" onClick={handleManuResponse}>
              {t("Docs")}
            </Link>
            <Link to="/store" onClick={handleManuResponse}>
              {t("Store")}
            </Link>
            <Link to="/" onClick={handleManuResponse}>
              {t("Reviews")}
            </Link>
          </div>
          <div className="container-segundaMitad responsive">
            <div style={{ display: "flex" }}>
              <Link
                to="/dashboard"
                onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}
                className="btn-dashboard separado"
              >
                {t("Dashboard")}
              </Link>
              <Link
                to="/afiliados"
                onClick={() => desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)}
                className="btn-dashboard separado"
              >
                {t("Affiliates")}
              </Link>
            </div>
            <div className="separator"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginLeft: "1rem",
                gap: "1rem",
              }}
            >
              <Link to="/add-funds">
                <div className="container-cash" onClick={() => setMenuBars(false)}>
                  <img src={cash} alt="" />
                  <p>${formattedPrice.toFixed(2)}</p>
                </div>
              </Link>
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <button className="logout-btn">
                  <img src={notify} alt="" />
                </button>
                <button onClick={handleLogout} className="logout-btn">
                  <img src={logout} alt="" title="Logout" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogNavbar;
