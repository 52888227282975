import axios from "axios";
import { backendURL } from "../../../../config";
import {
  notifyErroneo,
  notifyExitoso,
  timeOut,
} from "../../../../Utils/alerts/Alerts";
import { t } from "i18next";

export const checkKeyCluster = async (key, setLoading) => {
  const formData = {
    key: key,
  };
  try {
    const response = await axios.post(`${backendURL}/check-key`, formData);
    if (response.status === 200) {
      notifyExitoso(t("correctKey"));
      timeOut(`/dashboard/${response.data.cluster}`, 2000);
    } else {
      notifyErroneo(t("IncorrectKey"));
      setLoading(false);
    }
  } catch (error) {
    console.log(error);
    notifyErroneo(t("incorrectKey"));
  }
};
