import "./StartService.css";

const StartService = ({ t }) => {
  return (
    <main>
      <section className="containerService">
        <div className="primera-parte-service">
          <h1>{t("tituloStarted")}</h1>
          <p>{t("descripcionStarted")}</p>
        </div>
        <div className="segunda-parte-service">
          <button>{t("empezarbtn")}</button>
        </div>
      </section>
    </main>
  );
};

export default StartService;
