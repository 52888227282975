import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./../../../common/Modulos.css";
import { t } from "i18next";

const CustomClearText = () => <>{t("BorrarTodo")}</>;

const ClearIndicator = (props) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles("clearIndicator", props)}>
      <div style={{ padding: "0px 5px" }}>{children}</div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

export default function SelectSelfBotsMassFriender({ selfBots, setModulo, modulo }) {
  const [selectedBotIds, setSelectedBotIds] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useEffect(() => {
    if (modulo && modulo.selfBots) {
      setSelectedBotIds(modulo.selfBots);
    }
  }, [modulo]);

  const handleChange = (selectedOptions) => {
    const isSelectAll = selectedOptions.some((option) => option.value === "all");
    let newSelectedBotIds;

    if (isSelectAll) {
      newSelectedBotIds = selfBots.filter((bot) => bot.valid).map((bot) => bot.id);
    } else {
      newSelectedBotIds = selectedOptions ? selectedOptions.map((option) => option.value) : [];
    }

    setSelectedBotIds(newSelectedBotIds);
    handleSave(newSelectedBotIds);
  };

  const handleSave = (newBotIds) => {
    setModulo((prevModulo) => {
      return { ...prevModulo, selfBots: newBotIds };
    });
  };

  const handleMenuOpen = () => {
    const allBotIds = availableBots.map((bot) => bot.value);
    if (selectedBotIds.length !== allBotIds.length) {
      setMenuIsOpen(true);
    }
  };

  const handleMenuClose = () => {
    setMenuIsOpen(false);
  };

  const availableBots = selfBots.filter((bot) => bot.valid === true).map((bot) => ({ label: bot.username, value: bot.id }));

  const allBotIds = availableBots.map((bot) => bot.value);
  const isAllSelected = selectedBotIds.length === allBotIds.length;

  const selectAllOption = { label: `${t("Seleccionartodos")}`, value: "all" };
  const optionsWithSelectAll = [selectAllOption, ...availableBots];

  useEffect(() => {
    if (isAllSelected) {
      setMenuIsOpen(false);
    }
  }, [isAllSelected]);

  return (
    <div className="canal">
      <h4 style={{ color: "white" }}>{t("Cuentasparautlizar")}</h4>
      <p>{t("CuentasparautlizarText")}</p>
      <Select
        closeMenuOnSelect={false}
        components={{ ClearIndicator }}
        styles={{ clearIndicator: ClearIndicatorStyles }}
        isMulti
        placeholder={t("SeleccionarBot")}
        options={optionsWithSelectAll}
        value={availableBots.filter((bot) => selectedBotIds.includes(bot.value))}
        onChange={handleChange}
        isSearchable={false}
        menuIsOpen={menuIsOpen}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onFocus={() => setSelectedBotIds(selectedBotIds)}
        onBlur={() => setSelectedBotIds(selectedBotIds)}
      />
    </div>
  );
}
