import axios from "axios";
import { t } from "i18next";
import { backendURL } from "../../../../../../../config";
import {
  notifyErroneo,
  notifyEsperando,
  notifyExitoso,
  timeOut,
} from "../../../../../../../Utils/alerts/Alerts";

export const calculoStatsMassfriender = async (
  key,
  id,
  modulo,
  setPrice,
  setMiembros,
  setSugeridos,
  setTime,
  setModulo,
  setOkModule,
  setStateRequest
) => {
  try {
    const response = await axios.post(
      `${backendURL}/process-stats-module-massfriender`,
      {
        data: { key: key, id: id, modulo: modulo },
      }
    );
    if (response.status === 200) {
      setPrice(response.data.price);
      setMiembros(response.data.userCount);
      // setSugeridos(response.data.guildsInfo.price)
      const validInvites = response.data.guildsData
        .map((guild) => guild.invites)
        .flat();
      setTime(response.data.expectedTime);
      setModulo((prevModulo) => ({
        ...prevModulo,
        invites: validInvites,
        guildsData: response.data.guildsData,
      }));
      setOkModule(false);
      setStateRequest(false);
    } else {
      setStateRequest(false);
      return;
    }
  } catch (error) {
    setStateRequest(false);
    console.error(error);
  }
};

export const SaveDraftMassFriender = async (key, id, modulo) => {
  try {
    const response = await axios.put(`${backendURL}/save-module-draft-MassFriender`, {
      data: { key: key, id: id, modulo: modulo },
    });
    if (response.status === 200) {
      notifyExitoso(t("guardadoExitosamente"));
      timeOut(`/dashboard/${key}/massFriender`, 2000);
    } else if (response.status === 205) {
      notifyErroneo(t("errorGuardarModulo"));
    }
  } catch (error) {
    notifyErroneo(t("errorGuardarModulo"));
    console.error(error);
  }
};

// guarda los modulos en active
export const SaveActiveMassFriender = async (
  key,
  id,
  modulo,
  cooldown,
  price
) => {
  try {
    if (modulo.selfBots.length <= 0 || modulo.invites.length <= 0) {
      notifyEsperando(t("agregarInvitacionybots"));
      return;
    }
    if (price > 0) {
      const response = await axios.post(`${backendURL}/subtract-amount-balance`, {
        data: {
          token: localStorage.getItem("tokenlogin"),
          price: price,
        },
      });

      if (response.status === 200) {
        modulo.cooldown = cooldown;
        const responseData = await axios.put(
          `${backendURL}/save-module-active-MassFriender`,
          {
            data: { key: key, id: id, modulo: modulo },
          }
        );
        if (responseData.status === 200) {
          notifyExitoso(t("guardadoExitosamente"));
          timeOut(`/dashboard/${key}/massFriender`, 2000);
        } else if (responseData.status === 205) {
          notifyErroneo(t("errorGuardarModulo"));
        }
      } else if (response.status === 205) {
        notifyErroneo(t("nohaysuficientesaldo"));
      }
    } else {
      return;
    }
  } catch (error) {
    console.error(error);
  }
};
