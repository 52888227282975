import { t } from "i18next";
import { useEffect, useState } from "react";
import Separador from "../../../common/Separador";
import BtnInputBajar from "../../../../imgDashboard/btn-input-bajar.png";
import BtnInputSubir from "../../../../imgDashboard/btn-input-subir.png";

export default function CooldownGuildSpammer({ modulo, setCooldown }) {
  const [minimum, setMinimum] = useState(120);
  const [maximum, setMaximum] = useState(300);
  const [minimumUnit, setMinimumUnit] = useState("");
  const [maximumUnit, setMaximumUnit] = useState("");
  useEffect(() => {
    if (modulo && modulo.cooldown) {
      const minimumInSeconds = modulo.cooldown.minimum;
      const maximumInSeconds = modulo.cooldown.maximum;

      if (minimumInSeconds % 3600 === 0) {
        setMinimum(minimumInSeconds / 3600);
        setMinimumUnit("Horas");
      } else if (minimumInSeconds % 60 === 0) {
        setMinimum(minimumInSeconds / 60);
        setMinimumUnit("Minutos");
      } else {
        setMinimum(minimumInSeconds);
        setMinimumUnit("Segundos");
      }

      if (maximumInSeconds % 3600 === 0) {
        setMaximum(maximumInSeconds / 3600);
        setMaximumUnit("Horas");
      } else if (maximumInSeconds % 60 === 0) {
        setMaximum(maximumInSeconds / 60);
        setMaximumUnit("Minutos");
      } else {
        setMaximum(maximumInSeconds);
        setMaximumUnit("Segundos");
      }
    }
  }, [modulo.cooldown]);
  useEffect(() => {
    const convertToSeconds = (value, unit) => {
      switch (unit) {
        case "Segundos":
          return value;
        case "Minutos":
          return value * 60;
        case "Horas":
          return value * 3600;
        default:
          return value;
      }
    };

    const convertedMinimum = convertToSeconds(minimum || 0, minimumUnit);
    const convertedMaximum = convertToSeconds(maximum || 0, maximumUnit);

    setCooldown({ minimum: convertedMinimum, maximum: convertedMaximum });
  }, [minimum, maximum, minimumUnit, maximumUnit, setCooldown]);

  const handleMinimumUnitChange = (event) => {
    setMinimumUnit(event.target.value);
  };
  const handleMaximumUnitChange = (event) => {
    setMaximumUnit(event.target.value);
  };
  const handleMinimumChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 0) {
      setMinimum(value);
    }
  };

  const handleMaximumChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 0) {
      setMaximum(value);
    }
  };

  const handleDecreaseMinimum = () => {
    if (minimum > 0) {
      setMinimum(minimum - 1);
    }
  };

  const handleDecreaseMaximum = () => {
    if (maximum > 0) {
      setMaximum(maximum - 1);
    }
  };
  return (
    <div className="container-herramienta-cluster">
      <div className=" container-herramient-cluster-title-subtitle">
        <h2>{t("Cooldown")}</h2>
        <p>{t("CooldownText")}</p>
      </div>
      <Separador />
      <div className=" container-herramient-cluster-title-subtitle">
        <div className="container-cooldown">
          <div>
            <p>{t("minimo")}</p>
            <div className="cooldown-datos">
              <div className="custom-number-input">
                <input type="number" value={minimum || 1} min={1} onChange={handleMinimumChange} />
                <div className="controls">
                  <button type="button" onClick={() => setMinimum(minimum + 1)}>
                    <img src={BtnInputSubir} alt="Button Jikesshop up Number" />
                  </button>
                  <button type="button" onClick={handleDecreaseMinimum}>
                    <img src={BtnInputBajar} alt="Button Jikesshop down Number" />
                  </button>
                </div>
              </div>
              <select name="" id="minimum-unit" value={minimumUnit} onChange={handleMinimumUnitChange}>
                <option value="Segundos">{t("segundos")}</option>
                <option value="Minutos">{t("minutos")}</option>
                <option value="Horas">{t("horas")}</option>
              </select>
            </div>
          </div>
          <div>
            <p>{t("maximo")}</p>
            <div className="cooldown-datos">
              <div className="custom-number-input">
                <input type="number" min={1} value={maximum || 1} onChange={handleMaximumChange} />
                <div className="controls">
                  <button type="button" onClick={() => setMaximum(maximum + 1)}>
                    <img src={BtnInputSubir} alt="Button Jikesshop up Number" />
                  </button>
                  <button type="button" onClick={handleDecreaseMaximum}>
                    <img src={BtnInputBajar} alt="Button Jikesshop down Number" />
                  </button>
                </div>
              </div>
              <select name="" id="maximum-unit" value={maximumUnit} onChange={handleMaximumUnitChange}>
                <option value="Segundos">{t("segundos")}</option>
                <option value="Minutos">{t("minutos")}</option>
                <option value="Horas">{t("horas")}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
