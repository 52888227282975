// PanelControl.jsx
import { useEffect, useState } from "react";
import { t } from "i18next";
import {
  orderKeyCluster,
  orderPublicCluster,
} from "../funtionsDashboard/handleOrderClusters.js";
import { backendURL } from "../../../../config.js";
import {
  ClusterPublic,
  ClusterState,
} from "./common/FuntionsRequest/FunctionsRequest.js";
import { useParams } from "react-router";
import { validateKeyCluster } from "../../../../Utils/handleValidateLogin.js";
import Spinner from "../../../Spinner.jsx";
import "./styles/panelControl.css";
import StatisticsChart from "./StatisticsChart.jsx";

export default function PanelControl({ user }) {
  const { key } = useParams();
  const [active, setActive] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [publicCluster, setPublicCluster] = useState();

  // When loading the website, verify that the cluster key is valid
  useEffect(() => {
    validateKeyCluster(key);
  }, [key]);

  // function to change the public status of the cluster
  const toggleActive = async () => {
    await ClusterPublic(key, !publicCluster);
    setPublicCluster(!publicCluster);
  };

  // function to change the state of the cluster
  const handleStateCluster = async () => {
    await ClusterState(key, !active);
    setActive(!active);
  };

  // requests when loading the website to know the status of public and state
  const handleOrder = async () => {
    await orderPublicCluster(key, backendURL, setPublicCluster);
    await orderKeyCluster(key, backendURL, setActive);
    setIsLoading(false);
  };

  useEffect(() => {
    handleOrder();
  }, []);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="container-info-panel-cluster">
            <div>
              <h1>{t("panel")}</h1>
              <p>{t("Informacion")}</p>
            </div>
            <div className="botones-control">
              <button
                onClick={handleStateCluster}
                className={`btn-panel-control ${
                  active ? "active" : "desactive"
                }`}
              >
                {active ? t("activo") : t("inactivo")}
              </button>
            </div>
          </div>
          <div className="container-estadisticas-control">
            <div
              style={{
                boxShadow: "0 0 1.3px 0px white",
              }}
            >
              <h3>{t("estadisticasEnUso")}</h3>
              <div className="resultados-estadisticas">
                <StatisticsChart user={user} />
                <div className="avatares">
                  <ul>
                    <li>
                      <div className="point guildspammer"></div>
                      GuildSpammer
                    </li>
                    <li>
                      <div className="point massfriender"></div>
                      MassFriender
                    </li>
                    <li>
                      <div className="point serverJoiner"></div>
                      serverJoiner
                    </li>
                    <li>
                      <div className="point tokenHumanizer"></div>
                      tokenHumanizer
                    </li>
                    <li>
                      <div className="point tokenChecker"></div>
                      tokenChecker
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="container-estadisticas-control">
            <div
              style={{
                padding: "1.5rem 3rem",
                boxShadow: "0 0 1.3px 0px white",
              }}
            >
              <div className="container-estadisticas-control_div">
                <h2 style={{ color: "#FFFFFF", margin: "0" }}>{t("Public")}</h2>
                <button
                  onClick={toggleActive}
                  className={`btn-control ${
                    publicCluster ? " btn-panel-control-active" : ""
                  }`}
                  style={{ width: "1rem" }}
                >
                  <span
                    className={`bolita${publicCluster ? " bolita-active" : ""}`}
                  />
                </button>
              </div>
              <div className="resultados-estadisticas">
                <p style={{ color: "#CECECE" }}>{t("statePublicText")}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
