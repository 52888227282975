import axios from "axios";
import { backendURL } from "../../../../../../../../config";
import { notifyErroneo, notifyExitoso, timeOut } from "../../../../../../../../Utils/alerts/Alerts";
import { t } from "i18next";

// guarda los modulos en borrador
export const SaveDraftGuildSpammer = async (key, id, modulo, cooldown) => {
  try {
    modulo.cooldown = cooldown;
    const response = await axios.put(`${backendURL}/save-module-draft-GuildSpammer`, {
      data: { key: key, id: id, modulo: modulo },
    });
    if (response.status === 200) {
      notifyExitoso(t("guardadoExitosamente"));
      timeOut(`/dashboard/${key}/guildSpammer`, 2000);
    } else if (response.status === 205) {
      notifyErroneo(t("errorGuardarModulo"));
    }
  } catch (error) {
    notifyErroneo(t("errorGuardarModulo"));
    console.error(error);
  }
};

// guarda los modulos en active
export const SaveActiveGuildSpammer = async (key, id, modulo, cooldown) => {
  try {
    modulo.cooldown = cooldown;
    const response = await axios.put(`${backendURL}/save-module-active-GuildSpammer`, {
      data: { key: key, id: id, modulo: modulo },
    });
    if (response.status === 200) {
      notifyExitoso(t("guardadoExitosamente"));
      timeOut(`/dashboard/${key}/guildSpammer`, 2000);
    } else if (response.status === 205) {
      notifyErroneo(t("errorGuardarModulo"));
    }
  } catch (error) {
    console.error(error);
  }
};
