import "./../dashboard.css";
import add from "./../../../../img/add.webp";
import { t } from "i18next";

const AddCluster = ({ open }) => {
  return (
    <div className="container-add-cluster">
      <button className="btn-add-cluster" onClick={open}>
        <img src={add} alt="Add" style={{ width: "80%" }} />
      </button>
      <p>{t("addACluster")}</p>
    </div>
  );
};

export default AddCluster;
