import { t } from "i18next";
import "./Modulos.css";

const EstadisticasHerramientasCluster = ({
  num,
  info,
  color,
  loadingStats,
}) => {
  return (
    <div className="container-info-estadistica">
      <p>{t(`${info}`)}</p>
      {!loadingStats ? (
        <h1
          className={`animation-stats ${
            color === "primario" ? "primario" : ""
          }`}
        >
          {num}
        </h1>
      ) : (
        <h1 className={`${color === "primario" ? "primario" : ""}`}>{num}</h1>
      )}
    </div>
  );
};

export default EstadisticasHerramientasCluster;
