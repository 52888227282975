import { useState } from "react";
import { t } from "i18next";
import imgAgregarServidor from "./../../../../imgDashboard/img-agregar-servidor.png";
import tachoBasura from "./../../../../imgDashboard/tachoBasura.svg";
import Separador from "../../../common/Separador";
import { notifyErroneo } from "../../../../../../../Utils/alerts/Alerts";

// Expresión regular para validar la backendURL de invitación de Discord
const DISCORD_INVITE_REGEX = /^https:\/\/discord\.gg\/[a-zA-Z0-9]{2,}$/;

export default function Invitaciones({ modulo, setModulo }) {
  const [inviteUrl, setInviteUrl] = useState("");

  const handleAddInvite = (e) => {
    e.preventDefault();
    if (!inviteUrl) {
      return;
    }
    // Validar la backendURL
    if (!DISCORD_INVITE_REGEX.test(inviteUrl.trim())) {
      notifyErroneo(t("InvitacionInvalida")); // Mensaje de error para backendURL no válida
      return;
    }

    if (inviteUrl.trim() === "") {
      return;
    }

    const updatedModulo = { ...modulo };
    updatedModulo.invites = updatedModulo.invites || [];

    // Verifica si la invitación ya existe
    if (updatedModulo.invites.includes(inviteUrl.trim())) {
      notifyErroneo(t("YaExiste"));
      return;
    }

    // Agrega la invitación si no existe
    updatedModulo.invites.push(inviteUrl.trim());
    setModulo(updatedModulo);
    setInviteUrl("");
  };

  const handleDeleteInvite = (index) => {
    const updatedModulo = { ...modulo };
    updatedModulo.invites.splice(index, 1);
    setModulo(updatedModulo);
  };
  
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>{t("Servidores")}</h2>
        <p>{t("ServidoresDescripcion")}</p>
      </div>
      <Separador />
      <div className="invitaciones">
        <h4>{t("invitaciones")}</h4>

        {modulo && modulo.invites && modulo.invites.length > 0 ? (
          <>
            {modulo.invites.map((moduloItem, index) => (
              <div
                key={index}
                className="agregar-invitaciones"
                style={{ margin: "0.3rem 0" }}
              >
                <input
                  type="text"
                  defaultValue={moduloItem}
                  className="input-invitacion"
                  readOnly
                />
                <button type="button" onClick={() => handleDeleteInvite(index)}>
                  <img
                    src={tachoBasura}
                    alt="TachoBasura"
                    className="basura-img-invitacion"
                  />
                </button>
              </div>
            ))}
          </>
        ) : (
          <p className="parrafo-no-elements">{t("NohayInvitaciones")}</p>
        )}
      </div>
      <Separador />
      <form className="invitaciones" onSubmit={(e) => handleAddInvite(e)}>
        <h4>{t("agregarInvitacion")}</h4>
        <div className="agregar-invitaciones">
          <input
            type="text"
            placeholder="https://discord.gg/asdf2as"
            value={inviteUrl}
            onChange={(e) => setInviteUrl(e.target.value)}
          />
          <button type="submit">
            <img src={imgAgregarServidor} alt="Add" /> <span>+</span>
          </button>
        </div>
      </form>
    </div>
  );
}
