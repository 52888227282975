import { useEffect, useState } from "react";
import FormKey from "./Dashboardsectiones/FormKey";
import "./dashboard.css";
import CreateClusters from "./Dashboardsectiones/CreateClusters.jsx";
import { desactivarModals } from "./../../../Utils/Home/Home.js";
import Spinner from "../../Spinner.jsx";
import { backendURL } from "../../../config.js";
import { handleOrderClusters } from "./funtionsDashboard/handleOrderClusters.js";

export default function Dashboard({
  user,
  setMenuBars,
  setSeccionNotify,
  setSeccionLogOut,
}) {
  const [activeKey, setActiveKey] = useState(false); // State to manage the active key
  const [isSelected, setIsSelected] = useState(false); // State to manage selection status
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [arrayCluster, setArrayClusters] = useState([]); // State to store clusters

  const orderCluster = async () => {
    await handleOrderClusters(user.email, backendURL, setArrayClusters);
    setLoading(false); // Set loading to false once clusters are ordered
  };

  useEffect(() => {
    if (user) {
      orderCluster(); // Fetch and order clusters when user is available
    }
  }, [user]);

  const open = () => {
    setIsSelected(!isSelected); // Toggle selection status
  };

  const close = () => {
    if (isSelected) {
      setIsSelected(false); // Deselect if currently selected
    }
  };

  return (
    <>
      {loading ? (
        <Spinner /> // Display spinner while loading
      ) : (
        <>
          <section
            className="dashboard"
            onClick={() =>
              desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut)
            }
          >
            {user ? (
              <div>
                <CreateClusters
                  setIsSelected={setIsSelected}
                  email={user.email}
                  isSelected={isSelected}
                  open={open}
                  close={close}
                  arrayCluster={arrayCluster}
                />
                <FormKey
                  user={user}
                  setActiveKey={setActiveKey}
                  isSelected={isSelected}
                  close={close}
                />
              </div>
            ) : (
              <FormKey user={user} setActiveKey={setActiveKey} close={close} />
            )}
          </section>
        </>
      )}
    </>
  );
}
