import { t } from "i18next";
import { useState, useEffect } from "react";
import BtnInputBajar from "../../../../imgDashboard/btn-input-bajar.png";
import BtnInputSubir from "../../../../imgDashboard/btn-input-subir.png";

export default function DemoraTokenHumanizer({ setModulo, modulo }) {
  const [originalDelayTime, setOriginalDelayTime] = useState(180);
  const [delayTime, setDelayTime] = useState(180);
  const [timeUnit, setTimeUnit] = useState("Segundos");

  useEffect(() => {
    if (modulo.autoReply && modulo.autoReply.delayTime) {
      const { value, unit } = convertToUnits(modulo.autoReply.delayTime);
      setOriginalDelayTime(modulo.autoReply.delayTime);
      setDelayTime(value);
      setTimeUnit(unit);
    }
  }, [modulo.autoReply]);

  const convertToSeconds = (value, unit) => {
    switch (unit) {
      case "Segundos":
        return value;
      case "Minutos":
        return value * 60;
      case "Horas":
        return value * 3600;
      default:
        return value;
    }
  };

  const convertToUnits = (seconds) => {
    if (seconds % 3600 === 0) {
      return { value: seconds / 3600, unit: "Horas" };
    } else if (seconds % 60 === 0) {
      return { value: seconds / 60, unit: "Minutos" };
    } else {
      return { value: seconds, unit: "Segundos" };
    }
  };

  const handleDelayTimeChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setDelayTime(value);
  };

  const handleTimeUnitChange = (event) => {
    const unit = event.target.value;
    setTimeUnit(unit);
  };
  const handleDecreaseMaximum = () => {
    if (delayTime > 0) {
      setDelayTime(delayTime - 1);
    }
  };
  const handleMinimumChange = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 0) {
      setDelayTime(value);
    }
  };
  useEffect(() => {
    // Aquí actualizamos el objeto modulo solo si el valor cambió
    if (delayTime !== originalDelayTime) {
      const delayInSeconds = convertToSeconds(delayTime, timeUnit);
      setModulo((prevModulo) => ({
        ...prevModulo,
        autoReply: {
          ...prevModulo.autoReply,
          delayTime: delayInSeconds,
        },
      }));
    }
  }, [delayTime, timeUnit, originalDelayTime, setModulo]);
  const handleMinimumUnitChange = (event) => {
    setTimeUnit(event.target.value);
  };
  return (
    <div className="container-cooldown">
      <div className="container-info-spammer">
        <div className="container-herramienta-modulo-titulo">
          <h4 style={{ color: "white", margin: "0", padding: "0" }}>Delay</h4>
          <p style={{ marginLeft: "0" }}>{t("DelayText")}</p>
        </div>

        <div className="cooldown-datos tokenHumanizer ">
          <div className="custom-number-input tokenHumanizer">
            <input type="number" value={delayTime || 1} min={1} onChange={handleMinimumChange} />
            <div className="controls tokenHumanizer">
              <button type="button" onClick={() => setDelayTime(delayTime + 1)}>
                <img src={BtnInputSubir} alt="Button Jikesshop up Number" />
              </button>
              <button type="button" onClick={() => handleDecreaseMaximum()}>
                <img src={BtnInputBajar} alt="Button Jikesshop down Number" />
              </button>
            </div>
          </div>
          <select name="" id="minimum-unit" value={timeUnit} onChange={handleMinimumUnitChange}>
            <option value="Segundos">{t("segundos")}</option>
            <option value="Minutos">{t("minutos")}</option>
            <option value="Horas">{t("horas")}</option>
          </select>
        </div>
      </div>
    </div>
  );
}
