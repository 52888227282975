import { t } from "i18next";
import tachoBasura from "./../../imgDashboard/tachoBasura.svg";
import { useEffect } from "react";
import { OrderModules } from "./FuntionsRequest/FunctionsRequest";
import { useParams } from "react-router";

export default function ParteModulos({
  setArrayModulesActive,
  setArrayModulesBorradores,
  rutaModulo,
  isSelected,
  setModuleId,
  setIsSelected,
  arrayModulesActive,
  handleClickModulo,
}) {
  const { key } = useParams();
  useEffect(() => {
    OrderModules(
      key,
      setArrayModulesActive,
      setArrayModulesBorradores,
      rutaModulo
    );
  }, []);
  return (
    <div className="parte-modulos">
      <h4>{t("En Ejecución")}</h4>
      {arrayModulesActive.length === 0 ? (
        <p>{t("noElementos")}</p>
      ) : (
        <div className="modulos">
          <div
            className={`background-modal-guildspammer  ${
              isSelected ? "active" : ""
            }`}
            onClick={() => setIsSelected(false)}
          ></div>
          {arrayModulesActive.length > 0 &&
            arrayModulesActive.map((modulo, index) => (
              <div
                key={index}
                className="modulo"
                onClick={(e) => handleClickModulo(e, modulo._id)}
              >
                <div className="info-modulo">
                  <h4>{modulo.name}</h4>
                </div>
                <div className="parte-btn-delete">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsSelected(true);
                      setModuleId(modulo._id);
                    }}
                  >
                    <img src={tachoBasura} alt="tachoDeBasura" />
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
