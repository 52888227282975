import axios from "axios";
import { backendURL } from "../../../../../../../config";
import { notifyErroneo, notifyEsperando, notifyExitoso, timeOut } from "../../../../../../../Utils/alerts/Alerts";
import { t } from "i18next";

// order selfbots
export const OrderSelfBots = async (setCountsUsersBots, key) => {
  try {
    const response = await axios.post(`${backendURL}/order-selfbots`, {
      key: key,
    });
    if (response.status === 200) {
      setCountsUsersBots(response.data.orderedSelfBots.reverse());
    }
  } catch (error) {
    console.log(error);
  }
};

// insertar un token individual

export const handleCheckBotsTokens = async (token, key, setCountsUsersBots, setToken) => {
  try {
    notifyEsperando(t("procesandoToken"));
    const response = await axios.post(`${backendURL}/insert-token-bot`, {
      token: token,
      key: key,
    });

    if (response.status === 200) {
      OrderSelfBots(setCountsUsersBots, key);
      notifyExitoso(t("tokenAgregado"));
      // window.location.href = `/dashboard/${key}/bots`;
      setToken("");
    } else if (response.status === 205) {
      notifyErroneo(t("selfBotExistente"));
    } else if (response.status === 204) {
      notifyErroneo(t("selfBotCluster"));
    }
  } catch (error) {
    notifyErroneo(t("tokenInvalido"));
    console.log(error);
  }
};

// eliminar un selfbot
export const deteleTokenSelfBot = async (id, key, setCountsUsersBots) => {
  try {
    const response = await axios.delete(`${backendURL}/delete-token-selfbot`, {
      data: { key: key, id: id },
    });
    if (response.status === 200) {
      OrderSelfBots(setCountsUsersBots, key);
      notifyExitoso(t("tokensBienEliminados"));
      // setTimeout((window.location.href = `/dashboard/${key}/bots`), 4000);
    } else if (response.status === 205) {
      notifyErroneo(t("errorEliminar"));
    }
  } catch (error) {
    notifyErroneo(t("errorEliminar"));
    console.error(error);
  }
};

// checkearValidTokens btn
export const checkearValidTokens = async (countsUsersBots, key, setCountsUsersBots) => {
  try {
    if (countsUsersBots !== 0) {
      notifyEsperando(t("checkeandoToken"));
      const response = await axios.put(`${backendURL}/checkear-valid-tokens`, {
        key: key,
      });
      notifyExitoso(t("tokenCheckeado"));
      OrderSelfBots(setCountsUsersBots, key);
    }
  } catch (error) {
    setTimeout(() => {
      notifyErroneo(t("noPoderCheackear"));
    }, 1500);
    console.log(error);
  }
};

// eliminar tokens invalidos
export const deleteTokenksInvalid = async (setCountsUsersBots, key) => {
  try {
    const response = await axios.delete(`${backendURL}/detele-tokens-invalid`, {
      data: { key: key },
    });
    if (response.status === 200) {
      notifyExitoso(t("tokensBienEliminados"));
      OrderSelfBots(setCountsUsersBots, key);
    } else if (response.status === 205) {
      notifyErroneo(t("noPoderEliminar"));
    }
  } catch (error) {
    notifyErroneo(t("errorEliminando"));
    console.error(error);
  }
};

// detecta el texto que tiene dentro el txt
export const detectText = async (file, setSelectedFile, setIsSelected, setArrayTokens, setCountsUsersBots, key) => {
  if (file) {
    if (file.type === "text/plain") {
      setSelectedFile(file);
      detectedTxt(file, setArrayTokens, setCountsUsersBots, key);
      setIsSelected(true);
    } else {
      alert(t("seleccionarArchivo"));
    }
  }
};
const detectedTxt = async (file, setArrayTokens, setCountsUsersBots, key) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(`${backendURL}/detected-txt`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.data && response.data.tokens) {
      setArrayTokens(response.data.tokens);
      OrderSelfBots(setCountsUsersBots, key);
    } else {
      console.error(t("errorSubiendoArchivos"), response.data.error || response.statusText);
    }
  } catch (error) {
    console.error(t("errorSolicitud"), error);
  }
};
