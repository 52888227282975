export const TranslateTokenHumanizer = {
  en: {
    translation: {
      // Token Humanizer
      modulos: "Modules",
      por: "per",
      respuestaAutomatica: "Automatic Response",
      precioPor: "Price Per",
      Precioporhosting: "Price for hosting",
      Preciopormensaje: "Price per message",
      SelfBotsText: "Self bots that will be shown as online",
      Seleccionartodos: "Select All",
      Autorespuestas: "Authors put",
      AutorespuestasText: "Your Self Bots will automatically respond to direct messages they receive",
      Mensaje: "Message",
      PlaceholderMensaje: "Write your self-response here...",
      DelayText: "Time it takes to respond to the message",
    },
  },
  es: {
    translation: {
      // Token Humanizer
      modulos: "Modulos",
      por: "Por",
      Seleccionartodos: "Seleccionar todos",
      respuestaAutomatica: "Respuesta Automática",
      precioPor: "Precio Por",
      Precioporhosting: "Precio por hosting",
      Preciopormensaje: "Precio por mensaje",
      SelfBotsText: "Self bots que se mostrarán como online",
      Autorespuestas: "Autorespuestas",
      AutorespuestasText: "Tus Self Bots responderán de forma automática a los mensajes directos que reciban",
      Mensaje: "Mensaje",
      PlaceholderMensaje: "Escribe aquí tu auto respuesta...",
      DelayText: "Tiempo que tarda en responder al mensaje",
    },
  },
};
