import { useState } from "react";
import "./../dashboard.css";
import { CreateCluster } from "../funtionsDashboard/CreateCluster";
import { t } from "i18next";

const Modal = ({ setIsSelected, isSelected, email, close }) => {
  const [nameCluster, setNameCluster] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCreateCluster = async (e) => {
    e.preventDefault();
    setLoading(true); // Desactiva el botón al iniciar la solicitud
    try {
      await CreateCluster(email, nameCluster, setIsSelected, setNameCluster);
    } catch (error) {
      console.error("Error creating cluster:", error);
      // Manejo de errores si es necesario
    } finally {
      setLoading(false); // Reactiva el botón cuando la solicitud ha terminado
    }
  };

  return (
    <form
      onSubmit={handleCreateCluster}
      className={`create-cluster ${isSelected ? "modal-open" : "modal-closed"}`}
    >
      <h3>{t("crearCluster")}</h3>
      <div className="container-input-add-cluster">
        <p>{t("nombreCluster")}</p>
        <input
          type="text"
          placeholder={t("newCluster")}
          required
          value={nameCluster}
          onChange={(e) => setNameCluster(e.target.value)}
        />
      </div>
      <div className="container-btn-add-cluster">
        <button
          style={{
            background: "var(--color--background-btn-navbar)",
          }}
          onClick={close}
          type="button"
        >
          {t("cancelar")}
        </button>
        <button
          style={{
            background: "var(--color--azul--dashboard)",
            opacity: loading ? 0.5 : 1,
            cursor: loading ? "not-allowed" : "pointer",
          }}
          type="submit"
          disabled={loading}
        >
          {loading ? t("cargando") : t("crearBtn")}
        </button>
      </div>
    </form>
  );
};

export default Modal;
