import { t } from "i18next";
import Separador from "../../../common/Separador";
import { useEffect, useState } from "react";
import "./../../../common/Modulos.css";
import CooldownMassFriender from "./CooldownMassFriender";
import BtnInputBajar from "../../../../imgDashboard/btn-input-bajar.png";
import BtnInputSubir from "../../../../imgDashboard/btn-input-subir.png";

export default function SecurityMassFriender({ modulo, setModulo }) {
  const [actived, setActive] = useState(true);
  const [cooldown, setCooldown] = useState();
  const [thread, setThread] = useState(1);
  const [threadUnit, setThreadUnit] = useState("");
  const toggleActive = () => {
    if (modulo.security) {
      setActive(!actived);
      modulo.security.dispersion = !modulo.security.dispersion;
      setModulo(modulo);
    }
  };

  useEffect(() => {
    if (modulo.security && cooldown) {
      const updatedModulo = {
        ...modulo,
        security: {
          ...modulo.security,
          cooldown: {
            minimum: cooldown.minimum,
            thread: cooldown.thread,
          },
        },
      };

      setModulo(updatedModulo);
    }
  }, [cooldown]);
  const handleThread = (event) => {
    const value = parseInt(event.target.value, 10);
    if (value >= 0) {
      setThread(value);
    }
  };
  const handlethreadUnitChange = (event) => {
    setThreadUnit(event.target.value);
  };
  const handleDecreasethread = () => {
    if (thread > 0) {
      setThread(thread - 1);
    }
  };
  return (
    <>
      {modulo && (
        <>
          <div className="container-herramienta-cluster">
            <div className="container-herramienta-modulo-titulo">
              <h2>{t("Seguridad")}</h2>
              <p>{t("SeguridadText")}</p>
            </div>
            <Separador />
            <div className=" container-herramient-cluster-title-subtitle">
              <div>
                <h4>{t("Dispersión")}</h4>
                <div>
                  {modulo.security && (
                    <button
                      onClick={toggleActive}
                      className={`btn-control ${modulo.security.dispersion ? " btn-panel-control-active" : ""}`}
                    >
                      <span className={`bolita${actived ? " bolita-active" : ""}`} />
                    </button>
                  )}
                </div>
              </div>
              <p>{t("DispersionText")}</p>
              <Separador />
              <div className="container-herramient-cluster thread">
                <div className=" container-herramient-cluster-title-subtitle">
                  <div>
                    <h4 style={{ padding: "0", margin: "0" }}>Thread</h4>
                  </div>
                </div>
                {/* <p>Thread {t("texto de ejemplo")}</p> */}
                <div className="cooldown-datos thread">
                  <div className="custom-number-input">
                    <input type="number" min={1} value={thread || 1} onChange={handleThread} />
                    <div className="controls" style={{ right: "0" }}>
                      <button type="button" onClick={() => setThread(thread + 1)}>
                        <img src={BtnInputSubir} alt="Button Jikesshop up Number" />
                      </button>
                      <button type="button" onClick={handleDecreasethread}>
                        <img src={BtnInputBajar} alt="Button Jikesshop down Number" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Separador />
            <div className=" container-herramient-cluster-title-subtitle">
              <h4>Cooldown</h4>
              <p>{t("CooldownText")}</p>
              <CooldownMassFriender modulo={modulo} cooldown={cooldown} setCooldown={setCooldown} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
