import { useEffect, useState } from "react";
import { t } from "i18next";
import TableCanalesGuildSpammer from "./TableCanalesGuildSpammer";
import InputAddChannelsGuildSpammer from "./InputAddChannelsGuildSpammer";
import { OrderSelfBots } from "../../bots/functionsBots/functionsSelfBots";
import { useParams } from "react-router";
import Separador from "../../../common/Separador";

export default function CanalesGuildSpammer({ modulo, setModulo }) {
  const [tags, setTags] = useState([]);
  const [selfBots, setSelftBots] = useState([]);
  const { key } = useParams();
  useEffect(() => {
    const orderBots = async () => {
      await OrderSelfBots(setSelftBots, key);
    };
    orderBots();
  }, []);
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>{t("canales")}</h2>
        <p>{t("seleccionarCanales")}</p>
      </div>
      <Separador />
      <TableCanalesGuildSpammer
        setTags={setTags}
        tags={tags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        setModulo={setModulo}
        modulo={modulo}
      />
      <Separador />
      {/* Sección de etiquetas específicas de agregar */}
      <InputAddChannelsGuildSpammer
        tags={tags}
        setTags={setTags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        modulo={modulo}
        setModulo={setModulo}
      />
    </div>
  );
}
