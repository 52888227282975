import { t } from "i18next";
import "./ProgramaAfiliados.css";
import SeparadorAfiliados from "./SeparadorAfiliados";

const Saldo = ({ user }) => {
  return (
    <div className="container-modulo-afiliados">
      <div className="subtitulo-afiliados">
        <h2>{t("saldoAfiliado")}</h2> {/* Title for affiliate balance */}
      </div>
      <SeparadorAfiliados />
      <div className="container-primera-parte-saldo">
        <div style={{ width: "30%", color: "white", opacity: "0.6" }}>
          <h1>$ {user.balance}</h1> {/* Display current user balance */}
        </div>
        <div style={{ width: "60%", color: "white", opacity: "0.6" }}>{t("saldoDescripcion")}</div> {/* Balance description */}
      </div>
      <SeparadorAfiliados />
      <div className="container-segunda-parte-saldo">
        <div style={{ width: "100%", color: "white", opacity: "0.6" }}>
          <h5>{t("solicitarRetirada")}</h5> {/* Request withdrawal heading */}
        </div>
        <div className="container-selects-afiliados">
          <div className="container-selects">
            <select name="" id="">
              <option value="Criptomoneda">Criptomoneda</option> {/* Cryptocurrency option */}
            </select>
            <select name="" id="">
              <option value="Network">Network</option> {/* Network option */}
            </select>

            <input type="number" placeholder="$180" style={{ width: "8%" }} /> {/* Amount to withdraw */}
          </div>
          <div className="selects-container-btn-input">
            <input type="text" name="" id="" placeholder="14N9GdjTjcvomT6mbpb963o5GXupvGkBgj" /> {/* Address or wallet ID */}
            <button className="agregarAfiliados" type="button">
              {t("solicitar")}
            </button> {/* Button to request withdrawal */}
          </div>
        </div>
      </div>
      <SeparadorAfiliados />
      <div className="container-segunda-parte-saldo">
        <div style={{ width: "100%", color: "white", opacity: "0.6" }}>
          <h5>{t("enviarSaldo")}</h5> {/* Send balance heading */}
        </div>
        <div className="container-selects-afiliados" style={{ justifyContent: "start", gap: "2rem" }}>
          <input type="number" placeholder="$180" style={{ width: "8%" }} /> {/* Amount to deposit */}
          <button className="agregarAfiliados" type="button">
            {t("depositar")}
          </button> {/* Button to deposit balance */}
        </div>
      </div>
    </div>
  );
};

export default Saldo;
