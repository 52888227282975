import axios from "axios";
import { notifyExitoso, timeOut } from "../alerts/Alerts";

export const appearLogOut = (
  seccionNotify,
  setSeccionNotify,
  setSeccionLogOut
) => {
  setSeccionLogOut(false);
  setSeccionNotify(!seccionNotify);
};
export const appearNotifies = (
  seccionLogOut,
  setSeccionLogOut,
  setSeccionNotify
) => {
  setSeccionNotify(false);
  setSeccionLogOut(!seccionLogOut);
};
export const desactivarModals = (
  setMenuBars,
  setSeccionNotify,
  setSeccionLogOut
) => {
  setMenuBars(false);
  setSeccionNotify(false);
  setSeccionLogOut(false);
};
export const handleLogout = async () => {
  try {
    localStorage.removeItem("tokenlogin");
    notifyExitoso("Se ha cerrado sesión ");
    timeOut("/", 1000);
  } catch (error) {
    console.log(error);
  }
};
