import { useEffect, useState } from "react";
import ClusterNavbar from "./ClusterNavbar";
import { validateKeyCluster } from "../../../../Utils/handleValidateLogin";
import { Route, Routes, useParams } from "react-router";
import PanelControl from "./PanelControl";
import "./styles/clusters.css";
import { desactivarModals } from "../../../../Utils/Home/Home";
import Bots from "./pages/bots/Bots";
import TokenHumanizerModulo from "./pages/tokenHumanizer/Modules/TokenHumanizerModulo";
import TokenHumanizer from "./pages/tokenHumanizer/TokenHumanizer";
import GuildSpammer from "./pages/GuildSpammer/GuildSpammer";
import MassFriender from "./pages/massFriender/MassFriender";
import MassFrienderModulo from "./pages/massFriender/Modules/MassFrienderModulo";
import { handleOrderClusters } from "../funtionsDashboard/handleOrderClusters";
import { backendURL } from "../../../../config";
import GuildSpammerModulo from "./pages/GuildSpammer/ModuleGuildSpammer";
import ServerJoiner from "./pages/serverJoiner/ServerJoiner";
import ServerJoinerModulo from "./pages/serverJoiner/Modules/ServerJoinerModulo";

export default function Cluster({
  setMenuBars,
  user,
  setUser,
  setSeccionNotify,
  setSeccionLogOut,
}) {
  const [isLoading, setIsloading] = useState(true);
  const { key } = useParams();
  const [arrayCluster, setArrayClusters] = useState([]);

  const orderCluster = async () => {
    if (user) {
      await handleOrderClusters(user.email, backendURL, setArrayClusters);
      setIsloading(false);
    } else {
      setIsloading(false);
    }
  };

  useEffect(() => {
    orderCluster();
  }, [user]);

  // const handleDataCluster = async () => {
  //   await fetchUserProfile(setUser, backendURL);
  // };

  useEffect(() => {
    validateKeyCluster(key);
    // handleDataCluster();
  }, []);

  const handleClick = () => {
    desactivarModals(setMenuBars, setSeccionNotify, setSeccionLogOut);
  };
  const handleCodigoChange = (event) => {
    if (event.target.value === "") {
      return;
    } else {
      if (event.target.value === key) {
        return;
      } else {
        window.location.href = `/dashboard/${event.target.value}`;
      }
    }
  };
  return (
    <>
      <div className="cluster">
        <ClusterNavbar
          user={user}
          arrayCluster={arrayCluster}
          handleCodigoChange={handleCodigoChange}
        />
        <div className="cluster-container" onClick={handleClick}>
          <Routes>
            <Route path="/" element={<PanelControl user={user} />} />
            <Route
              path="/bots"
              element={<Bots user={user} setUser={setUser} />}
            />
            {/* MASS FRIENDER */}
            <Route path="/massFriender" element={<MassFriender />} />
            <Route
              path="/massFriender/:id"
              element={
                <MassFrienderModulo
                  ruta="massFriender"
                  rutaModulo="MassFriender"
                />
              }
            />
            {/* SERVER JOINER */}
            <Route
              path="/serverjoiner"
              element={
                <ServerJoiner ruta="serverJoiner" rutaModulo="ServerJoiner" />
              }
            />
            <Route
              path="/serverjoiner/:id"
              element={
                <ServerJoinerModulo
                  ruta="serverJoiner"
                  rutaModulo="ServerJoiner"
                />
              }
            />
            {/* Guild Spammer */}
            <Route path="/guildSpammer" element={<GuildSpammer />} />
            <Route
              path="/guildSpammer/:id"
              element={
                <GuildSpammerModulo
                  ruta="guildSpammer"
                  rutaModulo="guildSpammer"
                />
              }
            />
            {/* TOKEN HUMANIZER */}
            <Route
              path="/tokenHumanizer"
              element={<TokenHumanizer rutaModulo="tokenHumanizer" />}
            />
            <Route
              path="/tokenHumanizer/:id"
              element={<TokenHumanizerModulo ruta="tokenHumanizer" />}
            />
          </Routes>
        </div>
      </div>
    </>
  );
}
