import { useState } from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import { handleValidateLogin } from "../Utils/handleValidateLogin";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";

export default function Login({}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    handleValidateLogin(email, password);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <form onSubmit={handleSubmit} className="form-login">
          <div className="container-introduccion-form">
            <h2 style={{ margin: "0" }}>{t("Login")}</h2>
          </div>
          <div className="container-inputs-form-login">
            <div className="form-input-login">
              <label>
                {t("correoOrUserName")}{" "}
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="input-login"
                style={{ color: "white" }}
              />
            </div>
            <div className="form-input-login">
              <label>
                {t("Password")} <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                required
                className="input-login"
              />
            </div>
          </div>
          <div className="btns-login">
            <button type="submit" className="btn-form-login">
              {t("signIn")}
            </button>
            <h5 style={{ margin: "0", color: "#02a1fc" }}>
              {t("forgotPassword")}
            </h5>
            <div className="separator-register"></div>
            <Link to="/register" className="btn-form-login2">
              {t("Register")}
            </Link>
          </div>
        </form>

        {/* <Link to="/" className="btn-volver">
          {t("Back")}
        </Link> */}
      </div>
    </>
  );
}
