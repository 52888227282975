import { t } from "i18next";
import "./../Afiliados.css";
import SeparadorAfiliados from "./SeparadorAfiliados";
import CopiarAlPortapapeles from "../../../CopiarAlPortapapeles";
import copy from "clipboard-copy";
import { notifyErroneo, notifyExitoso } from "../../../../Utils/alerts/Alerts";
import { backendURL } from "../../../../config";

const ModuloAfiliados = ({ user, titulo }) => {
  // Function to handle click event for copying the referral link
  const handleClick = async () => {
    try {
      // Notify user that the link has been copied to the clipboard
      notifyExitoso(t("copiadoPortapapeles"));
      // Copy the referral link to the clipboard
      await copy(`${backendURL}?ref=${user.username}`);
    } catch (error) {
      // Notify user of an error if copying to clipboard fails
      notifyErroneo(t("errorCopiadoPortapapeles"));
      console.error(t("errorCopiadoPortapapeles"), error);
    }
  };

  return (
    <>
      {user && (
        <div className="container-modulo-afiliados">
          <div className="subtitulo-afiliados">
            <h2>{titulo}</h2> {/* Title of the affiliate module */}
          </div>
          <SeparadorAfiliados />
          <div className="container-info-programa-afiliados">
            <div className="primera-parte-programa-afiliados">
              <div>
                <input type="text" placeholder={`${backendURL}?ref=${user.username}`} />
                <button onClick={handleClick}>{t("copiar")}</button> {/* Button to copy the referral link */}
              </div>
              <p>{t("ReferrallinkText")}</p> {/* Text describing the referral link */}
            </div>
            <div className="segunda-parte-programa-afiliados">
              <div>
                <div>
                  <h1>{user.affiliates.directRegisteredUsers}</h1> {/* Number of directly registered users */}
                  <p>{t("Usuariosdirectos")}</p> {/* Label for direct users */}
                </div>
                <div>
                  <h1>{user.affiliates.indirectRegisteredUsers}</h1> {/* Number of indirectly registered users */}
                  <p>{t("Usuariosindirectos")}</p> {/* Label for indirect users */}
                </div>
              </div>
              <div>
                <div>
                  <h1>${user.affiliates.directEarnings}</h1> {/* Direct earnings amount */}
                  <p>{t("Ingresosdirectos")}</p> {/* Label for direct earnings */}
                </div>
                <div>
                  <h1>${user.affiliates.indirectEarnings}</h1> {/* Indirect earnings amount */}
                  <p>{t("Ingresosindirectos")}</p> {/* Label for indirect earnings */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModuloAfiliados;
