export const TranslateAddFunds = {
  en: {
    translation: {
      Añadirfondos: "Add Funds",
      Pagafacilrápido: "Pay easily and quickly",
      addFundsText1: "Recharge your account easily to purchase all our products and services with a single click.",
      addFundsText2: `Choose the amount you want to add to your account in dollars and click "Pay now". You can then select your preferred cryptocurrency and complete the transaction easily.`,
      Consiguecriptomonedas: "Get cryptocurrencies",
      Cryptohere: "Crypto here",
      Addyourdesiredamount: "Add your desired amount",
      pregunta1addfunds: "Can I pay with paypal?",
      respuesta1addfunds: "It is possible to pay with PayPal if you use our payment gateway, select card...",
      pregunta2addfunds: "Can I pay with card?",
      respuesta2addfunds: "Yes, we allow card payment for purchases over $7 throughout our website.",

      Depositar: "To deposit",
      Cantidad: "Amount",
      PleaseNote: "Keep in mind",
      PleaseNoteText:
        "Add your desired amount that you want to deposit into your account Clicking the pay now button will lead you to a checkout page",
      TOTAL: "TOTAL",
      PagarAhora: "Pay now",
    },
  },
  es: {
    translation: {
      Añadirfondos: "Añadir fondos",
      Pagafacilrápido: "Paga facil y rápido",
      addFundsText1: "Recarga tu cuenta fácilmente para adquirir todos nuestros productos y servicios con un solo clic.",
      addFundsText2: `Elige la cantidad que quieres agregar a tu cuenta en dólares y haz clic en "Pagar ahora".Después podrás seleccionar tu criptomoneda preferida y completar la transacción de forma sencilla.`,
      Consiguecriptomonedas: "Consigue criptomonedas",
      Cryptohere: "Cripto aquí",
      Addyourdesiredamount: "Añade la cantidad deseada",

      pregunta1addfunds: "¿Puedo pagar con PayPal?",
      respuesta1addfunds: "Es posible pagar con PayPal si utilizas nuestra pasarela de pagos, seleccionas tarjeta...",
      pregunta2addfunds: "¿Puedo pagar con Tarjeta?",
      respuesta2addfunds: "Sí, permitimos el pago con tarjeta en compras mayores a 7$ en toda nuestra web.",

      Depositar: "Depositar",
      Cantidad: "Cantidad",
      PleaseNote: "Tenga en cuenta",
      PleaseNoteText:
        "Agregue la cantidad deseada que desea depositar en su cuenta. Al hacer clic en el botón Pagar ahora, accederá a una página de pago.",
      TOTAL: "TOTAL",
      PagarAhora: "Pagar Ahora",
    },
  },
};
