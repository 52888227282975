import "./../../../common/Modulos.css";
import EstadisticasTokenHumanizer from "./EstadisticasTokenHumanizer.jsx";
import "react-toastify/dist/ReactToastify.css";
import { deleteModule, orderModule } from "../../../common/FuntionsRequest/FunctionsRequest.js";
import {
  SaveActiveTokenHumanizer,
  SaveDraftTokenHumanizer,
} from "./functionsModuleTokenHumanizer/functionsModuleTokenHumanizer.js";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import BtnsModules from "../../../common/BtnsModules.jsx";
import { notifyErroneo, notifyExitoso } from "../../../../../../../Utils/alerts/Alerts.js";
import SelfBotsTokenHumanizer from "./SelfBotsTokenHumanizer.jsx";
import AutoRespuestaTokenHumanizer from "./AutoRespuestaTokenHumanizer.jsx";
import ModalDeleteModule from "../../../common/ModalDeleteModule.jsx";
import ModalChangeNameModule from "../../../common/ModalChangeNameModule.jsx";
import { validateKeyCluster } from "../../../../../../../Utils/handleValidateLogin.js";

const TokenHumanizerModulo = ({ ruta }) => {
  const { key, id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [ModalchangeName, setModalchangeName] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [modulo, setModulo] = useState({});
  const [cooldown, setCooldown] = useState();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);
  useEffect(() => {
    orderModule(id, key, setModulo, "TokenHumanizer", ruta);
  }, []);
  return (
    <>
      <div className="container-principal-herramienta-cluster ">
        <div>
          {/* <ToastContainer /> */}
          <div className={`background-modal  ${isSelected ? "active" : ""}`} onClick={() => setIsSelected(false)}></div>
          <ModalDeleteModule
            isSelected={isSelected}
            setIsSelected={setIsSelected}
            setIsSelectBorradores={setIsSelectBorradores}
            isSelectBorradores={isSelectBorradores}
            id={id}
            key={key}
            deleteModule={deleteModule}
            notifyExitoso={notifyExitoso}
            notifyErroneo={notifyErroneo}
            backPath={true}
            ruta={"tokenHumanizer"}
            rutaModulo={"TokenHumanizer"}
          />
          <div className={`background-modal  ${ModalchangeName ? "active" : ""}`} onClick={() => setModalchangeName(false)}></div>
          <ModalChangeNameModule
            id={id}
            modulo={modulo}
            setModulo={setModulo}
            setModalchangeName={setModalchangeName}
            ModalchangeName={ModalchangeName}
          />
          <BtnsModules
            setModalchangeName={setModalchangeName}
            setIsSelected={setIsSelected}
            modulo={modulo}
            cooldown={cooldown}
            saveActive={SaveActiveTokenHumanizer}
            saveDraft={SaveDraftTokenHumanizer}
            ruta={"tokenHumanizer"}
          />
          <div className="container-modulos-cluster">
            <EstadisticasTokenHumanizer />
            <SelfBotsTokenHumanizer setModulo={setModulo} modulo={modulo} />
            <AutoRespuestaTokenHumanizer setModulo={setModulo} modulo={modulo} />
            {/* <CooldownGuildSpammer modulo={modulo} cooldown={cooldown} setCooldown={setCooldown} />
              <CanalesGuildSpammer setModulo={setModulo} modulo={modulo} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenHumanizerModulo;
