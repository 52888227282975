import "./MassFrienderModulo.css";
import InfoPrecios from "../../../common/EstadisticasHerramientasCluster";
import { t } from "i18next";
import Separador from "../../../common/Separador";
import BtnsModules from "../../../common/BtnsModules";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  deleteModule,
  orderModule,
} from "../../../common/FuntionsRequest/FunctionsRequest";
import {
  SaveActive,
  SaveDraft,
} from "../../GuildSpammer/ModuleGuildSpammer/functionsModuleGuildSpammer/moduleGuildSpammer";
import Invitaciones from "./Invitaciones";
import ModalDeleteModule from "../../../common/ModalDeleteModule";
import {
  notifyErroneo,
  notifyEsperando,
  notifyExitoso,
} from "../../../../../../../Utils/alerts/Alerts";
import {
  calculoStatsMassfriender,
  SaveActiveMassFriender,
  SaveDraftMassFriender,
} from "../functionsModuleMassFriender/moduleMassFriender";
import SelfBotsMassFriender from "./SelfBotsMassFriender";
import Spinner from "../../../../../../Spinner";
import { validateKeyCluster } from "../../../../../../../Utils/handleValidateLogin";
import EstadisticasHerramientasCluster from "../../../common/EstadisticasHerramientasCluster";
import BackgroundModalEffectunfocused from "../../../common/BackgroundModalEffectunfocused";
import ModalChangeNameModule from "../../../common/ModalChangeNameModule";
import SecurityMassFriender from "./SecurityMassFriender";
import ModalRaidCost from "../ModalRaidCost";
// import IntroModulo from "../../../common/IntroModulo";
const MassFriender2 = ({ rutaModulo, ruta }) => {
  const { key, id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [ModalchangeName, setModalchangeName] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [modulo, setModulo] = useState({});
  const [cooldown, setCooldown] = useState();
  const [loading, setLoading] = useState(true);
  const [price, setPrice] = useState("0,00");
  const [miembros, setMiembros] = useState("xxx");
  const [sugeridos, setSugeridos] = useState("xxx");
  const [time, setTime] = useState("0");
  const [loadingStats, setloadingStats] = useState(true);
  const [okModule, setOkModule] = useState(true);
  const [stateRequest, setStateRequest] = useState(false);
  const [activeModalRaid, setActiveModalRaid] = useState(false);
  useEffect(() => {
    const orderModuleKeyID = async () => {
      await validateKeyCluster(key);
      await orderModule(id, key, setModulo, rutaModulo, ruta);
      setLoading(false);
    };
    orderModuleKeyID();
  }, []);

  useEffect(() => {
    const calculoStats = async () => {
      // notifyEsperando(t("VerificandoInvitación"));
      setloadingStats(false);
      try {
        await calculoStatsMassfriender(
          key,
          id,
          modulo,
          setPrice,
          setMiembros,
          setSugeridos,
          setTime,
          setModulo,
          setOkModule,
          setStateRequest
        );
        setStateRequest(false);
      } catch (error) {
        setStateRequest(false);
        console.error(error);
      } finally {
        setStateRequest(false);
        setloadingStats(true);
      }
    };

    if (okModule) {
      if (modulo.invites && modulo.selfBots.length > 0) {
        setStateRequest(true);
        calculoStats();
      }
    } else {
      setOkModule(true);
      return;
    }
  }, [modulo]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-principal-herramienta-cluster">
          <div>
            <ModalRaidCost
              activeModalRaid={activeModalRaid}
              setActiveModalRaid={setActiveModalRaid}
              saveActive={SaveActiveMassFriender}
              price={price}
              modulo={modulo}
              id={id}
            />
            <ModalDeleteModule
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              setIsSelectBorradores={setIsSelectBorradores}
              isSelectBorradores={isSelectBorradores}
              id={id}
              setArrayModulesActive={[]}
              key={key}
              notifyExitoso={notifyExitoso}
              notifyErroneo={notifyErroneo}
              deleteModule={deleteModule}
              backPath={true}
              ruta={"massFriender"}
              rutaModulo={"massFriender"}
            />
            <BtnsModules
              setModalchangeName={setModalchangeName}
              setIsSelected={setIsSelected}
              modulo={modulo}
              cooldown={cooldown}
              saveActive={SaveActiveMassFriender}
              saveDraft={SaveDraftMassFriender}
              stateRequest={stateRequest}
              ruta={"massFriender"}
              setActiveModalRaid={setActiveModalRaid}
              price={price}
              okModule={okModule}
              NoCambioDeNombre={true}
            />
            <BackgroundModalEffectunfocused
              estado={activeModalRaid}
              setEstado={setActiveModalRaid}
            />
            <BackgroundModalEffectunfocused
              estado={isSelected}
              setEstado={setIsSelected}
            />
            <BackgroundModalEffectunfocused
              estado={ModalchangeName}
              setEstado={setModalchangeName}
            />
            <ModalChangeNameModule
              id={id}
              modulo={modulo}
              setModulo={setModulo}
              setModalchangeName={setModalchangeName}
              ModalchangeName={ModalchangeName}
            />
            <div className="container-modulos-cluster">
              <div className="container-herramienta-cluster">
                <div className="container-herramienta-modulo-titulo">
                  <h2>{t("estadisticas")}</h2>
                  <p>{t("estadisticasInfoModuloMassFrender")}</p>
                </div>
                <Separador />
                <div className="container-herramientas-estadisticas">
                  <div className="container-estadistica">
                    <EstadisticasHerramientasCluster
                      color="secundario"
                      num={`$${price ? price : "0,00"}`}
                      loadingStats={loadingStats}
                      info={"precioEstimado"}
                    />
                    <EstadisticasHerramientasCluster
                      color="primario"
                      num={`${miembros ? miembros : "0"}`}
                      loadingStats={loadingStats}
                      info={"Miembrosdelservidor"}
                    />
                    <EstadisticasHerramientasCluster
                      color="secundario"
                      num={`${sugeridos ? sugeridos : "xxx"}`}
                      loadingStats={loadingStats}
                      info={"SelfBotsSugeridos"}
                    />
                    <EstadisticasHerramientasCluster
                      color="secundario"
                      num={`${time ? time : "x"}h`}
                      loadingStats={loadingStats}
                      info={"Tiempomedio"}
                    />
                  </div>
                </div>
              </div>
              <Invitaciones modulo={modulo} setModulo={setModulo} />
              <SecurityMassFriender modulo={modulo} setModulo={setModulo} />
              <SelfBotsMassFriender modulo={modulo} setModulo={setModulo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MassFriender2;
