import money from "../../../../../img/money.svg";
import affiliated from "../../../../../img/affiliated.svg";
import "./Clusters.css";
const Clusters = ({ t, user }) => {
  return (
    <main>
      <section className="container-clusters">
        <div className="container-info-clusters">
          <div>
            <img src={money} alt="Img monedas fondo Jikesshop" />
            <h1>{t("AñadeFondosCuenta")}</h1>
            <p>{t("depositaDineroCluster")}</p>
            {!user ? (
              <button onClick={() => (window.location.href = "/register")}>{t("AñadirFondosbtn")}</button>
            ) : (
              <button onClick={() => (window.location.href = "/add-funds")}>{t("AñadirFondosbtn")}</button>
            )}
          </div>
          <div>
            <img src={affiliated} alt="Img affiliated fondo Jikesshop" />
            <h1>{t("ConvierteteAfiliado")}</h1>
            <p>{t("ConvierteteAfiliadoText")}</p>
            {!user ? (
              <button onClick={() => (window.location.href = "/register")}>{t("uneteBtn")}</button>
            ) : (
              <button onClick={() => (window.location.href = "/afiliados")}>{t("uneteBtn")}</button>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Clusters;
