import axios from "axios";
import { handleValidateLogin } from "./handleValidateLogin";
import { notifyErroneo, notifyExitoso } from "./alerts/Alerts";
import { t } from "i18next";
import { backendURL } from "../config";

export const handleValidateRegister = async (email, userName, confirmPassword, password, referrer) => {
	if (password !== confirmPassword) {
		notifyErroneo(t("noCoinciden"));
		return;
	}

	try {
		const response = await axios.post(`${backendURL}/account/register`, { email, userName, password, confirmPassword, referrer });
		if (response.status === 200) {
			notifyExitoso(t("registrado"));
			localStorage.removeItem("referrer");
			handleValidateLogin(email, password);
		} else {
			if (response.status === 205) {
				notifyErroneo(t("emailExistente"));
			}
		}
	} catch (error) {
		console.error(error);
	}
};
