import "./App.css";
import Register from "./components/Register";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
// import "./Translates/TranslateNavbar";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import Login from "./components/Login";
import Home from "./components/pages/Home/Home";
import Spinner from "./components/Spinner";
// Utils
import fetchUserProfile from "./Utils/fetchUserProfile";
import { loadingLanguage } from "./Utils/language";
import ScrollToTop from "./Utils/ScrollToTop";
import ProgramaAfiliados from "./components/pages/afiliados/modulos/ProgramaAfiliados";
import AddFunds from "./components/pages/funds/AddFunds";
//import ProductStore from "./components/pages/store/ProductStore";
//import "./components/pages/store/NavbarStore.css";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import Cluster from "./components/pages/Dashboard/Clusters/Cluster";
import NavbarHome from "./components/navbar/NavbarHome";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TranslateNavbar } from "./Translates/TranslateNavbar";
import { TranslateLoginRegister } from "./Translates/TranslateLoginRegister";
import { TranslateHome } from "./Translates/TranslateHome";
import { TranslateDashboard } from "./Translates/TranslateDashboard";
import { TranslateBots } from "./Translates/TranslateBots";
import { TranslateMassFriender } from "./Translates/TranslateMassFriender";
import { TranslateGuildSpammer } from "./Translates/TranslateGuildSpammer";
import { TranslateTokenHumanizer } from "./Translates/TranslateTokenHumanizer";
import { TranslateAfiliados } from "./Translates/TranslateAfiliados";
import { TranslateAddFunds } from "./Translates/TranslateAddFunds";
import { backendURL } from "./config";


function App() {
	const [menuBars, setMenuBars] = useState(false); // State to manage the menu bars
	const [loading, setLoading] = useState(true); // State to manage the loading status
	const [user, setUser] = useState(null); // State to store user information
	const [seccionNotify, setSeccionNotify] = useState(false); // State to manage notification section visibility
	const [seccionLogOut, setSeccionLogOut] = useState(false); // State to manage logout section visibility

	const tranformRef = async (referrerParam) => {
		const response = await axios.post(`${backendURL}/tranformRef`, { referrerParam });
		localStorage.setItem("referrer", JSON.stringify(response.data)); // Store reference data in local storage
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const referrerParam = urlParams.get("referrer");
		if (referrerParam) {
			tranformRef(referrerParam); // Transform reference if present in URL
		}
	}, []);

	useEffect(() => {
		const fetchProfile = async () => {
			setLoading(true); // Start loading
			await fetchUserProfile(setUser, backendURL); // Fetch user profile
			const storedLanguage = localStorage.getItem("language");

			if (storedLanguage) {
				i18n.use(initReactI18next).init({
					resources: {
						en: {
							translation: {
								...TranslateNavbar.en.translation,
								...TranslateLoginRegister.en.translation,
								...TranslateHome.en.translation,
								...TranslateDashboard.en.translation,
								...TranslateBots.en.translation,
								...TranslateMassFriender.en.translation,
								...TranslateGuildSpammer.en.translation,
								...TranslateTokenHumanizer.en.translation,
								...TranslateAfiliados.en.translation,
								...TranslateAddFunds.en.translation,
							},
						},
						es: {
							translation: {
								...TranslateNavbar.es.translation,
								...TranslateLoginRegister.es.translation,
								...TranslateHome.es.translation,
								...TranslateDashboard.es.translation,
								...TranslateBots.es.translation,
								...TranslateMassFriender.es.translation,
								...TranslateGuildSpammer.es.translation,
								...TranslateTokenHumanizer.es.translation,
								...TranslateAfiliados.es.translation,
								...TranslateAddFunds.es.translation,
							},
						},
					},
					lng: storedLanguage, // Set the language from local storage
					fallbackLng: "en", // Fallback language if stored language is not available
					interpolation: {
						escapeValue: false,
					},
				});
				setLoading(false);
			} else loadingLanguage(setLoading);
		};
		fetchProfile();
	}, []);


	const PrivateRoute = ({ element, ...rest }) => {
		return user ? element : <Navigate to="/login" />; // Redirect to login if user is not authenticated
	};

	return (
		<div className="App">
			{loading ? (
				<Spinner /> // Display spinner while loading
			) : (
				<BrowserRouter>
					<ScrollToTop />
					<NavbarHome
						setMenuBars={setMenuBars}
						menuBars={menuBars}
						user={user}
						setUser={setUser}
						seccionNotify={seccionNotify}
						seccionLogOut={seccionLogOut}
						setSeccionNotify={setSeccionNotify}
						setSeccionLogOut={setSeccionLogOut}
					/>
					<Routes>
						<Route
							path="/"
							element={
								<Home
									user={user}
									setUser={setUser}
									setMenuBars={setMenuBars}
									setSeccionNotify={setSeccionNotify}
									setSeccionLogOut={setSeccionLogOut}
								/>
							}
						/>
						{/* store */}
						<Route
							path="/add-funds"
							element={
								<PrivateRoute
									element={
										<AddFunds
											setMenuBars={setMenuBars}
											user={user}
											setSeccionNotify={setSeccionNotify}
											setSeccionLogOut={setSeccionLogOut}
										/>
									}
								/>
							}
						/>
						{/* /dashboard */}
						<Route
							path="/dashboard"
							element={
								<PrivateRoute
									element={
										<Dashboard
											setUser={setUser}
											user={user}
											seccionNotify={seccionNotify}
											menuBars={menuBars}
											setMenuBars={setMenuBars}
											setSeccionNotify={setSeccionNotify}
											setSeccionLogOut={setSeccionLogOut}
										/>
									}
								/>
							}
						/>
						<Route
							path="/dashboard/:key"
							element={
								<PrivateRoute
									element={
										<Cluster
											user={user}
											setUser={setUser}
											setMenuBars={setMenuBars}
											setSeccionNotify={setSeccionNotify}
											setSeccionLogOut={setSeccionLogOut}
										/>
									}
								/>
							}
						/>
						<Route
							path="/afiliados"
							element={
								<PrivateRoute
									element={
										<ProgramaAfiliados
											user={user}
											setMenuBars={setMenuBars}
											setSeccionNotify={setSeccionNotify}
											setSeccionLogOut={setSeccionLogOut}
										/>
									}
								/>
							}
						/>
						<Route
							path="/login"
							element={
								user ? (
									<Navigate to="/" />
								) : (
									<Login
										user={user}
										menuBars={menuBars}
										setMenuBars={setMenuBars}
										seccionLogOut={seccionLogOut}
										seccionNotify={seccionNotify}
										setSeccionNotify={setSeccionNotify}
										setSeccionLogOut={setSeccionLogOut}
									/>
								)
							}
						/>
						<Route
							path="/register"
							element={
								user ? (
									<Navigate to="/" />
								) : (
									<Register
										user={user}
										menuBars={menuBars}
										setMenuBars={setMenuBars}
										seccionLogOut={seccionLogOut}
										seccionNotify={seccionNotify}
										setSeccionNotify={setSeccionNotify}
										setSeccionLogOut={setSeccionLogOut}
									/>
								)
							}
						/>
						<Route path="*" element={<Navigate to="/" />} />{" "}
						<Route
							path="/dashboard/:key/*"
							element={
								<Cluster
									user={user}
									setUser={setUser}
									menuBars={menuBars}
									setMenuBars={setMenuBars}
									seccionLogOut={seccionLogOut}
									seccionNotify={seccionNotify}
									setSeccionNotify={setSeccionNotify}
									setSeccionLogOut={setSeccionLogOut}
								/>
							}
						/>
					</Routes>
					<ToastContainer />
				</BrowserRouter>
			)}
		</div>
	);
}

export default App;
