import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { handleValidateRegister } from "../Utils/handleValidateRegister";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { backendURL } from "./../config";
import axios from "axios";

export default function Register() {
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setRepeatPassword] = useState("");
  const [buttonActive, setButtonActive] = useState(true);
  const getReferrer = async () => {
    const referrerToken = localStorage.getItem("referrer");
    if (referrerToken) {
      const response = await axios.post(`${backendURL}/orderReferrer`, {
        referrerToken: JSON.parse(referrerToken),
      });
      return response.data;
    } else {
      return null;
    }
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    setButtonActive(false);
    e.preventDefault();
    const referrer = await getReferrer();
    handleValidateRegister(
      email,
      userName,
      password,
      confirmPassword,
	  referrer,
      navigate
    );
    setTimeout(() => {
      setButtonActive(true);
    }, 5000);
  };
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100vh",
          paddingTop: "10vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
        }}
      >
        <form onSubmit={handleSubmit} className="form-login">
          <div className="container-introduccion-form">
            <h2 style={{ margin: "0" }}>{t("crearCuenta")}</h2>
          </div>
          <div className="container-inputs-form-login">
            <div className="form-input-login">
              <label>
                {t("correo")}
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="input-login"
                style={{ color: "white" }}
                required
              />
            </div>
            <div className="form-input-login">
              <label>
                {t("UserName")}
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="text"
                onChange={(e) => setUserName(e.target.value)}
                className="input-login"
                style={{ color: "white" }}
                required
              />
            </div>
            <div className="form-input-login">
              <label>
                {t("Password")}
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="input-login"
                style={{ color: "white" }}
                required
              />
            </div>
            <div className="form-input-login">
              <label>
                {t("RepeatPassword")}
                <strong style={{ color: "red" }}> *</strong>
              </label>
              <input
                type="password"
                onChange={(e) => setRepeatPassword(e.target.value)}
                className="input-login"
                style={{ color: "white" }}
                required
              />
            </div>
          </div>
          <div className="btns-login">
            <p>{t("aceptarTerminos")}</p>
            {!buttonActive ? (
              <button className="btn-form-login">{t("Register")}</button>
            ) : (
              <button type="submit" className="btn-form-login">
                {t("Register")}
              </button>
            )}

            <div className="separator-register"></div>
            <Link to="/login" className="btn-form-login2">
              {t("Login")}
            </Link>
          </div>
        </form>
        {/* <Link to="/" className="btn-volver">
          {t("Back")}
        </Link> */}
      </div>
    </>
  );
}
