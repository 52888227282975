import { t } from "i18next";
import { useState, useEffect } from "react";
import tachoBasura from "../../../../imgDashboard/tachoBasura.svg";
import add from "../../../../../../../img/add.webp";
import saveIcon from "../../../../../../../img/save-icon.svg";
import editIcon from "../../../../../../../img/edit-icon.svg";
import { notifyExitoso } from "../../../../../../../Utils/alerts/Alerts";

export default function MensajeAutoRespuestaTokenHumanizer({ modulo, setModulo }) {
  const [message, setMessage] = useState(modulo.autoReply?.message || "");
  const [messageInput, setMessageInput] = useState("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (modulo.autoReply) {
      setMessage(modulo.autoReply.message || "");
    }
  }, [modulo.autoReply]);

  const handleEditMessage = () => {
    setEditMode(true);
    setMessageInput(message);
  };

  const handleDeleteMessage = () => {
    setEditMode(false);
    setMessage("");
    setMessageInput("");
    setModulo((prevModulo) => ({
      ...prevModulo,
      autoReply: {
        ...prevModulo.autoReply,
        message: "",
      },
    }));
    notifyExitoso(t("mensajeBorradoExitosamente"));
  };

  const handleSaveMessage = () => {
    if (!messageInput.trim()) {
      return;
    }

    setModulo((prevModulo) => ({
      ...prevModulo,
      autoReply: {
        ...prevModulo.autoReply,
        message: messageInput,
      },
    }));

    setMessage(messageInput);
    setEditMode(false);
    notifyExitoso(t(editMode ? "mensajeEditadoExitosamente" : "mensajeAgregadoExitosamente"));
  };

  const handleAddNewMessage = () => {
    if (!messageInput.trim()) {
      return;
    }

    setModulo((prevModulo) => ({
      ...prevModulo,
      autoReply: {
        ...prevModulo.autoReply,
        message: messageInput,
      },
    }));

    setMessage(messageInput);
    setEditMode(false);
    setMessageInput("");
    notifyExitoso(t("mensajeAgregadoExitosamente"));
  };

  return (
    <div className="container-info-spammer">
      <h3 style={{ color: "white" }}>{t("Mensaje")}</h3>
      <div className="container-mensajes">
        {message.length <= 0 || message === undefined ? (
          // <p className="parrafo-no-elements">No hay Mensaje</p>
          <></>
        ) : (
          <div className="mensaje">
            {editMode ? (
              <>
                <div className="mesajeEditContainer">
                  <pre style={{ color: "#88898a", whiteSpace: "pre-wrap" }}>
                    <span>{message}</span>
                    <button onClick={handleDeleteMessage}>
                      <img src={tachoBasura} alt="tachoDeBasura" />
                    </button>
                  </pre>
                  <div className="container-input-mensaje">
                    <textarea
                      placeholder={t("PlaceholderMensaje")}
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                    />
                    <button onClick={handleSaveMessage} style={{ cursor: "pointer" }}>
                      <img src={saveIcon} alt="save" />
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div>
                <pre style={{ color: "#88898a", whiteSpace: "pre-wrap" }}>
                  <span>{message}</span>
                  <div className="mensaje-buttons">
                    {/* <button onClick={handleEditMessage}>
                        <img src={editIcon} alt="edit" />
                      </button> */}
                    <button onClick={handleDeleteMessage}>
                      <img src={tachoBasura} alt="tachoDeBasura" />
                    </button>
                  </div>
                </pre>
              </div>
            )}
          </div>
        )}
        <div className={`container-input-mensaje ${message ? "active" : ""}`}>
          <textarea
            placeholder={t("PlaceholderMensaje")}
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
          <button onClick={handleAddNewMessage} style={{ cursor: "pointer" }}>
            <img src={add} alt="add" />
          </button>
        </div>
      </div>
    </div>
  );
}
