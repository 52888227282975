import React from "react";
import copy from "clipboard-copy";
import { t } from "i18next";
import { notifyErroneo, notifyExitoso } from "../Utils/alerts/Alerts";

const CopiarAlPortapapeles = ({ contenido }) => {
    const handleClick = async () => {
        try {
            await copy(contenido);
            notifyExitoso(t("copiadoPortapapeles"));
        } catch (error) {
            notifyErroneo(t("errorCopiadoPortapapeles"));
            console.error(t("errorCopiadoPortapapeles"), error);
        }
    };

    return (
        <>
            <span onClick={handleClick}>{contenido}</span>
        </>
    );
};

export default CopiarAlPortapapeles;
