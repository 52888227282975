import { t } from "i18next";
import "./../../../common/Modulos.css";
import Separador from "../../../common/Separador";
import EstadisticasHerramientasCluster from "../../../common/EstadisticasHerramientasCluster";
const EstadisticasTokenHumanizer = () => {
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>{t("estadisticas")}</h2>
        <p>{t("estadisticasInfoModuloMassFrender")}</p>
      </div>
      <Separador />
      <div className="container-herramientas-estadisticas">
        <div className="container-estadistica">
          <EstadisticasHerramientasCluster color="secundario" num="$0,10" info={"Precioporhosting"} />
          <EstadisticasHerramientasCluster color="secundario" num="$0,10" info={"Preciopormensaje"} />
        </div>
      </div>
    </div>
  );
};

export default EstadisticasTokenHumanizer;
