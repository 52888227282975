import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { createChart } from "lightweight-charts";
import Spinner from "../../../Spinner";
import { useParams } from "react-router";
import { handleOrderClusters } from "../funtionsDashboard/handleOrderClusters";
import { backendURL } from "../../../../config";

const StatisticsChart = ({ user }) => {
  const chartContainerRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [cluster, setCluster] = useState(null);
  const [arrayCluster, setArrayClusters] = useState([]);
  const { key } = useParams();

  const extractUsageData = (tool) => {
    if (!tool || !tool.usage) return [];

    // Ordenar el array de usage por la fecha
    const sortedUsage = tool.usage.sort((a, b) => new Date(a.date) - new Date(b.date));

    return sortedUsage.map((entry) => {
      // Obtener la fecha en formato UTC para evitar problemas de zona horaria
      const utcDate = new Date(entry.date);
      const utcTime = Date.UTC(
        utcDate.getUTCFullYear(),
        utcDate.getUTCMonth(),
        utcDate.getUTCDate(),
        utcDate.getUTCHours(),
        utcDate.getUTCMinutes(),
        utcDate.getUTCSeconds(),
        utcDate.getUTCMilliseconds()
      );

      return {
        time: utcTime / 1000, // Convertir a tiempo UNIX (segundos)
        value: entry.cost,
        originalDate: utcDate, // Guardar la fecha original para referencia
      };
    });
  };

  const handleOrder = async () => {
    if (user) {
      await handleOrderClusters(user.email, backendURL, setArrayClusters);
    }
  };

  useEffect(() => {
    handleOrder();
  }, [user]);

  useEffect(() => {
    if (arrayCluster.length > 0) {
      const activeCluster = arrayCluster.find((cluster) => cluster.key === key);
      if (activeCluster) {
        setCluster(activeCluster);
      }
    }
  }, [arrayCluster, key]);

  useLayoutEffect(() => {
    if (chartContainerRef.current && cluster) {
      const chartOptions = {
        layout: {
          textColor: "white",
          background: { type: "solid", color: "#111111" },
        },
        grid: {
          vertLines: {
            color: "rgba(197, 203, 206, 0.2)",
            style: 0, // solid line style
          },
          horzLines: {
            color: "rgba(197, 203, 206, 0.2)",
            style: 0, // solid line style
          },
        },
        // timeScale: {
        //   timeVisible: true,
        //   secondsVisible: false,
        //   tickMarkFormatter: (time, tickMarkType, locale) => {
        //     const date = new Date(time * 1000);
        //     console.log(date);
        //     const day = date.toLocaleString(locale, { day: "numeric" });
        //     const month = date.toLocaleString(locale, { month: "short" });
        //     return `${day} ${month}`;
        //   },
        // },
        priceScale: {
          position: "none", // Hide the price scale on the right side
        },
        handleScroll: {
          vertTouchDrag: false,
        },
        handleScale: {
          axisPressedMouseMove: false,
          axisDoubleClickReset: false,
          mouseWheel: true, // Enable mouse wheel zooming
          pinch: true, // Enable pinch to zoom
          vertMouseWheel: false, // Disable vertical mouse wheel zooming
        },
      };

      const chart = createChart(chartContainerRef.current, chartOptions);

      const lineSeriesOne = chart.addLineSeries({ color: "#2962FF" });
      const lineSeriesTwo = chart.addLineSeries({ color: "rgb(225, 87, 90)" });
      const lineSeriesThree = chart.addLineSeries({ color: "rgb(242, 142, 44)" });
      const lineSeriesFour = chart.addLineSeries({ color: "rgb(44, 242, 144)" });
      const lineSeriesFive = chart.addLineSeries({ color: "rgb(90, 87, 225)" });

      const lineSeriesOneData = extractUsageData(cluster.guildSpammer);
      const lineSeriesTwoData = extractUsageData(cluster.massFriender);
      const lineSeriesThreeData = extractUsageData(cluster.serverJoiner);
      const lineSeriesFourData = extractUsageData(cluster.tokenHumanizer);
      const lineSeriesFiveData = extractUsageData(cluster.tokenChecker);
      lineSeriesOne.setData(lineSeriesOneData);
      lineSeriesTwo.setData(lineSeriesTwoData);
      lineSeriesThree.setData(lineSeriesThreeData);
      lineSeriesFour.setData(lineSeriesFourData);
      lineSeriesFive.setData(lineSeriesFiveData);

      chart.timeScale().fitContent();
      setIsLoading(false); // Set loading to false after chart is created

      // Clean up the chart instance on component unmount
      return () => {
        chart.remove();
      };
    }
  }, [cluster]);

  return (
    <div style={{ width: "100%", height: "400px", boxShadow: "0 0 1.5px 0px rgb(255, 255, 255)" }}>
      {isLoading && <Spinner />}
      <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }} />
    </div>
  );
};

export default StatisticsChart;
