import { useEffect, useState } from "react";
import "./Navbar.css";
import logoBanner from "../../img/logoBanner.svg";
import logout from "../../img/logout.webp";
import cash from "../../img/cash.webp";
import notify from "../../img/notify.webp";
import NotLogNavbar from "../navbar/notLogNavbar/NotLogNavbar";
import LogNavbar from "../navbar/logNavbar/LogNavbar";

// Navbar component for home page
const NavbarHome = ({ setMenuBars, menuBars, user, seccionNotify, seccionLogOut, setSeccionNotify, setSeccionLogOut }) => {
  const [scroll, setScroll] = useState(false);

  // Determine if the current path is '/dashboard' to set static mode
  const isDashboard = window.location.pathname.includes("/dashboard");
  const modeStatic = isDashboard ? true : false;

  // Handle scroll events to toggle scroll class
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 30) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Toggle the menu bars visibility
  const handleManuResponse = () => {
    setMenuBars(!menuBars);
  };

  return (
    <nav className={`${scroll ? "scroll" : ""} ${modeStatic ? "active-mode-static" : ""}`}>
      {!user ? (
        // Render NotLogNavbar if user is not logged in
        <NotLogNavbar setMenuBars={setMenuBars} handleManuResponse={handleManuResponse} menuBars={menuBars} logo={logoBanner} />
      ) : (
        // Render LogNavbar if user is logged in
        <LogNavbar
          user={user}
          logo={logoBanner}
          notify={notify}
          handleManuResponse={handleManuResponse}
          menuBars={menuBars}
          setMenuBars={setMenuBars}
          logout={logout}
          cash={cash}
          seccionNotify={seccionNotify}
          seccionLogOut={seccionLogOut}
          setSeccionLogOut={setSeccionLogOut}
          setSeccionNotify={setSeccionNotify}
        />
      )}
      {/* Menu bars toggle button */}
      <div className="menu-bars" onClick={handleManuResponse}>
        <i className="fa-solid fa-bars"></i>
      </div>
    </nav>
  );
};

export default NavbarHome;
