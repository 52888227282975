import axios from "axios";
import {
  notifyErroneo,
  notifyExitoso,
  timeOut,
} from "../../../../../../Utils/alerts/Alerts.js";
import { backendURL } from "../../../../../../config.js";
import { t } from "i18next";

// desactivar cluster "active"
export const ClusterState = async (key, currentState) => {
  try {
    const response = await axios.put(`${backendURL}/update-cluster-state`, {
      key: key,
      active: currentState,
    });

    if (response.status === 200) {
      notifyExitoso(t("cambioStateCluster"));
      // await handleOrderClusters(email, backendURL, setArrayClusters);
    } else {
      notifyErroneo(t("errorCambioStateCluster"));
    }
  } catch (error) {
    console.error("Error updating cluster state", error);
    // Handle the error as needed
  }
};
// desactivar cluster "active"
export const ClusterPublic = async (key, currentState) => {
  try {
    const response = await axios.put(`${backendURL}/update-cluster-public`, {
      key: key,
      active: currentState,
    });
    if (response.status === 200) {
      notifyExitoso(t("cambioPublicCluster"));
      // await handleOrderClusters(email, backendURL, setArrayClusters);
    } else {
      notifyErroneo(t("errorCambioPublicCluster"));
    }
  } catch (error) {
    console.error("Error updating cluster Public", error);
    // Handle the error as needed
  }
};

// recupera los datos del modulo
export const orderModule = async (id, key, setModulo, ruta, rutaModulo) => {
  try {
    const response = await axios.post(`${backendURL}/get-module-${rutaModulo}`, {
      data: { id: id, key: key },
    });
    if (response.status === 200) {
      let moduleModify = response.data.module;
      moduleModify.invites = response.data.invites;
      setModulo(moduleModify);
    } else {
      window.location.href = `/dashboard/${key}/${ruta}`;
    }
  } catch (error) {
    console.error(error);
  }
};

// recupera todos los modulos de la cluster
export const OrderModules = async (
  key,
  setArrayModulesActive,
  setArrayModulesBorradores,
  rutaModulo,
  setArrayModulesHistory
) => {
  try {
    const response = await axios.post(`${backendURL}/order-modules-${rutaModulo}`, {
      key: key,
    });
    await setArrayModulesActive(response.data.modulesActivos);
    await setArrayModulesBorradores(response.data.modulesBorradores);
    await setArrayModulesHistory(response.data.modulesHistory);
  } catch (error) {
    console.error(error);
  }
};

// crea los modulos
export const CreateModule = async (key, ruta, rutaModulo) => {
  try {
    const response = await axios.post(`${backendURL}/create-module-${rutaModulo}`, {
      key: key,
    });
    notifyExitoso(t("moduloCreado"));
    timeOut(`/dashboard/${key}/${ruta}/${response.data.id}`, 2000);
    // await OrderModulesGuildSpammer(key, setArrayModulesActive, setArrayModulesBorradores);
  } catch (error) {
    notifyErroneo(t("errorCreandoModulo"));
    console.log(error);
  }
};

// eliminar el modulo
export const deleteModule = async (
  id,
  key,
  setIsSelected,
  setIsSelectBorradores,
  setArrayModulesActive,
  setArrayModulesBorradores,
  ruta,
  rutaModulo,
  backPath,
  setArrayModulesHistory
) => {
  try {
    await axios.delete(`${backendURL}/delete-module-${rutaModulo}`, {
      data: { key: key, id: id },
    });
    notifyExitoso(t("eliminadoExitosamente"));
    setIsSelected(false);
    setIsSelectBorradores(false);
    await OrderModules(
      key,
      setArrayModulesActive,
      setArrayModulesBorradores,
      rutaModulo,
      setArrayModulesHistory
    );
    if (backPath) {
      timeOut(`/dashboard/${key}/${ruta}`, 2000);
    }
  } catch (error) {
    notifyErroneo(t("errorEliminar"));
    console.log(error);
  }
};
