import { useState } from "react";
import { t } from "i18next";
import tachoBasura from "../../../../imgDashboard/tachoBasura.svg";
import { notifyEsperando } from "../../../../../../../Utils/alerts/Alerts";

export default function TableCanalesGuildSpammer({ setTags, tags, selfBots, setModulo, modulo }) {
  const [tagInputs, setTagInputs] = useState({});

  const handleTagRemove = (removedTag, botID) => {
    const updatedTags = tags.map((tag) =>
      tag.botID === botID
        ? {
            ...tag,
            channels: tag.channels.filter((channel) => channel !== removedTag),
          }
        : tag
    );

    setTags(updatedTags);

    let updatedModulo = {
      ...modulo,
      selfBots: modulo.selfBots.map((bot) =>
        bot.botID === botID
          ? {
              ...bot,
              channels: bot.channels.filter((channel) => channel !== removedTag),
            }
          : bot
      ),
    };

    // Verificar si el bot se quedó sin tags y eliminarlo
    updatedModulo = {
      ...updatedModulo,
      selfBots: updatedModulo.selfBots.filter((bot) => bot.channels.length > 0),
    };

    setModulo(updatedModulo);
  };

  const handleInputKeyDown = (e, botID) => {
    if (e.key === "Enter" && tagInputs[botID]?.trim() !== "") {
      const existingTags = new Set(modulo.selfBots.find((bot) => bot.botID === botID)?.channels || []);
      if (!existingTags.has(tagInputs[botID].trim())) {
        const updatedTags = tags.map((tag) =>
          tag.botID === botID
            ? {
                ...tag,
                channels: [...tag.channels, tagInputs[botID].trim()],
              }
            : tag
        );

        setTags(updatedTags);
        setTagInputs((prevInputs) => ({ ...prevInputs, [botID]: "" }));

        const updatedModulo = {
          ...modulo,
          selfBots: modulo.selfBots.map((bot) =>
            bot.botID === botID
              ? {
                  ...bot,
                  channels: [...bot.channels, tagInputs[botID].trim()],
                }
              : bot
          ),
        };

        setModulo(updatedModulo);
      } else {
        notifyEsperando("Este tag ya está en la lista.");
      }
    }
  };

  const handleSelectChange = (e, botID) => {
    const selectedBotId = e.target.value;
    setTagInputs((prevInputs) => ({
      ...prevInputs,
      [botID]: selectedBotId,
    }));
  };

  const handleDeleteAllTags = (botID) => {
    const updatedSelfBots = modulo.selfBots.filter((bot) => bot.botID !== botID);

    const updatedModulo = {
      ...modulo,
      selfBots: updatedSelfBots,
    };

    setModulo(updatedModulo);
  };

  const handleFocus = (botID) => {
    document.getElementById(`input-${botID}`).focus();
  };

  return (
    <div>
      {modulo.selfBots === undefined || modulo.selfBots.length <= 0 ? (
        <>
          <p className="parrafo-no-elements" style={{ textAlign: "center", margin: "5% auto" }}>
            {t("noElementos")}
          </p>
        </>
      ) : (
        <>
          <div>
            <h3 style={{ color: "white", margin: "0" }}>Canales añadidos</h3>
            <div className="titulos-canales">
              <h4>Self Bots</h4>
              <h4>{t("canales")}</h4>
            </div>
            {modulo.selfBots.map((bot) => (
              <div key={bot.botID} className="table-canals">
                <div>
                  <div
                    className="canal-selfbot canales"
                    style={{ cursor: "pointer" }}
                    title={selfBots.find((selfBot) => selfBot.id === bot.botID)?.username || bot.botID}
                  >
                    <p>{selfBots.find((selfBot) => selfBot.id === bot.botID)?.username || bot.botID}</p>
                  </div>
                </div>
                <div className="container-canales-tags">
                  <div>
                    <div className="container-canales-boton" onClick={() => handleFocus(bot.botID)}>
                      <div className="canales-tags">
                        {bot.channels.map((channel) => (
                          <div key={channel} className="tag-canal">
                            <span>{channel}</span>
                            <button onClick={() => handleTagRemove(channel, bot.botID)}>x</button>
                          </div>
                        ))}
                        <input
                          id={`input-${bot.botID}`}
                          value={tagInputs[bot.botID] || ""}
                          data-role="taginput"
                          onChange={(e) => handleSelectChange(e, bot.botID)}
                          onKeyDown={(e) => handleInputKeyDown(e, bot.botID)}
                          placeholder={t("addTags")}
                        />
                      </div>
                      <button onClick={() => handleDeleteAllTags(bot.botID)}>
                        <img src={tachoBasura} alt="Eliminar Selfbot Guildspammer" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
