import dashboard from "../imgNavbar/dashboard.webp";
import salir from "../imgNavbar/salir.webp";
import ajustes from "../imgNavbar/ajustes.webp";
import addFund from "../imgNavbar/addFund.webp";
import historialPagos from "../imgNavbar/historialPagos.webp";
import productosComprados from "../imgNavbar/productosComprados.webp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { t } from "i18next";
import { Link } from "react-router-dom";

// Profile menu component
export default function MenuPerfil({ user, handleLogout, seccionLogOut }) {
  return (
    <div
      className={`container-notification ${seccionLogOut ? "active" : ""}`}
      style={{
        gap: "1rem",
        marginBottom: "3rem",
      }}
    >
      {user && (
        <>
          {/* Display user information */}
          <h3>
            {t("saludo")} {user.username} {/* Greeting with username */}
          </h3>
          <p>{user.email}</p> {/* Display user email */}
        </>
      )}
      <div className="separator-notification"></div>
      {/* Dashboard link */}
      <div className="logout-info" onClick={() => (window.location.href = "/dashboard")}>
        <img src={dashboard} alt="dashboard" /> {/* Dashboard icon */}
        <p>{t("dash")}</p> {/* Dashboard text */}
      </div>
      {/* Purchased products link */}
      <div className="logout-info">
        <img src={productosComprados} alt="productosComprados" /> {/* Purchased products icon */}
        <p>{t("productosComprados")}</p> {/* Purchased products text */}
      </div>
      <div className="separator-notification"></div>
      {/* Add funds link */}
      <div className="logout-info">
        <img src={addFund} alt="addFund" /> {/* Add funds icon */}
        <p>{t("nuevosFondos")}</p> {/* Add funds text */}
      </div>
      {/* Payment history link */}
      <div className="logout-info">
        <img src={historialPagos} alt="historialPagos" /> {/* Payment history icon */}
        <p>{t("historialPagos")}</p> {/* Payment history text */}
      </div>
      <div className="separator-notification"></div>
      {/* Settings link */}
      <div className="logout-info">
        <img src={ajustes} alt="ajustes" /> {/* Settings icon */}
        <p>{t("ajustes")}</p> {/* Settings text */}
      </div>
      {/* Logout button */}
      <div className="logout-info" style={{ width: "70%", gap: "0.5rem" }}>
        <img src={salir} alt="salir" /> {/* Logout icon */}
        <button onClick={handleLogout} className="btn-logout">
          {t("Logout")} {/* Logout text */}
        </button>
      </div>
    </div>
  );
}
