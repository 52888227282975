import { useState } from "react";
import "./Preguntas.css";
import abrirPregunta from "../../../../../img/abrirPregunta.svg";
import cerrarPregunta from "../../../../../img/cerrarPregunta.svg";
import { t } from "i18next";
export default function Preguntas() {
  const [activeQuestion, setActiveQuestion] = useState(null);
  const handleQuestion = (index) => {
    setActiveQuestion(index === activeQuestion ? null : index);
  };

  return (
    <section>
      <div className="container-faq">
        <h1>{t("PreguntasTitulo")}</h1>
        <p>{t("descripcionPreguntas")}</p>
        <div className="preguntas-faq">
          <ul className="lista-preguntas-faq">
            <li onClick={() => handleQuestion(0)} className={`background ${activeQuestion === 0 ? "active" : "inactive"}`}>
              <div className="pregunta">
                <img
                  src={cerrarPregunta}
                  className={`cerrar-pregunta ${activeQuestion === 0 ? "inactive" : "active"}`}
                  alt="Imagen de Cierre pregunta Jikesshop "
                />
                <img
                  src={abrirPregunta}
                  className={`abrir-pregunta ${activeQuestion === 0 ? "active" : "inactive"}`}
                  alt="Imagen de Abre pregunta Jikesshop "
                />
                <p>{t("pregunta1")}</p>
              </div>
              <p className={`parrafo-pregunta ${activeQuestion === 0 ? "active" : "inactive"}`}>{t("respuesta1")}</p>
            </li>
            {/* // */}
            <li onClick={() => handleQuestion(1)} className={`background ${activeQuestion === 1 ? "active" : "inactive"}`}>
              <div className="pregunta">
                <img
                  src={cerrarPregunta}
                  className={`cerrar-pregunta ${activeQuestion === 1 ? "inactive" : "active"}`}
                  alt="Imagen de Cierre pregunta Jikesshop "
                />
                <img
                  src={abrirPregunta}
                  className={`abrir-pregunta ${activeQuestion === 1 ? "active" : "inactive"}`}
                  alt="Imagen de Abre pregunta Jikesshop "
                />
                <p>{t("pregunta2")}</p>
              </div>
              <p className={`parrafo-pregunta ${activeQuestion === 1 ? "active" : "inactive"}`}>{t("respuesta2")}</p>
            </li>
            {/* // */}
            <li onClick={() => handleQuestion(2)} className={`background ${activeQuestion === 2 ? "active" : "inactive"}`}>
              <div className="pregunta">
                <img
                  src={cerrarPregunta}
                  className={`cerrar-pregunta ${activeQuestion === 2 ? "inactive" : "active"}`}
                  alt="Imagen de Cierre pregunta Jikesshop "
                />
                <img
                  src={abrirPregunta}
                  className={`abrir-pregunta ${activeQuestion === 2 ? "active" : "inactive"}`}
                  alt="Imagen de Abre pregunta Jikesshop "
                />
                <p>{t("pregunta3")}</p>
              </div>
              <p className={`parrafo-pregunta ${activeQuestion === 2 ? "active" : "inactive"}`}>{t("respuesta3")}</p>
            </li>
            <li onClick={() => handleQuestion(3)} className={`background ${activeQuestion === 3 ? "active" : "inactive"}`}>
              <div className="pregunta">
                <img
                  src={cerrarPregunta}
                  className={`cerrar-pregunta ${activeQuestion === 3 ? "inactive" : "active"}`}
                  alt="Imagen de Cierre pregunta Jikesshop "
                />
                <img
                  src={abrirPregunta}
                  className={`abrir-pregunta ${activeQuestion === 3 ? "active" : "inactive"}`}
                  alt="Imagen de Abre pregunta Jikesshop "
                />
                <p>{t("pregunta4")}</p>
              </div>
              <p className={`parrafo-pregunta ${activeQuestion === 3 ? "active" : "inactive"}`}>{t("respuesta4")}</p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
