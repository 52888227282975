import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import MensajeAutoRespuestaTokenHumanizer from "./MensajeAutoRespuestaTokenHumanizer";
import DemoraTokenHumanizer from "./DemoraTokenHumanizer";
import Separador from "../../../common/Separador";
import "./../../../common/Modulos.css";
import { t } from "i18next";

export default function AutoRespuestaTokenHumanizer({ setModulo, modulo }) {
  const [actived, setActive] = useState(true);
  const toggleActive = () => {
    if (modulo.autoReply) {
      setActive(!actived);
      modulo.autoReply.enabled = !modulo.autoReply.enabled;
      setModulo(modulo);
    }
  };
  useEffect(() => {
    if (modulo.autoReply) {
      setActive(modulo.autoReply.enabled);
      setModulo(modulo);
    }
  }, [modulo.autoReply]);

  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramientas-data-space-between">
        <div className="container-herramienta-modulo-titulo">
          <h2 style={{ marginBottom: "0.5rem" }}>{t("Autorespuestas")}</h2>
          <p>{t("AutorespuestasText")}</p>
        </div>
        <div>
          {modulo.autoReply !== undefined && (
            <button
              onClick={toggleActive}
              className={`btn-control ${modulo.autoReply.enabled ? " btn-panel-control-active" : ""}`}
            >
              <span className={`bolita${actived ? " bolita-active" : ""}`} />
            </button>
          )}
        </div>
      </div>
      <Separador />
      <MensajeAutoRespuestaTokenHumanizer modulo={modulo} setModulo={setModulo} />
      <Separador />
      <DemoraTokenHumanizer modulo={modulo} setModulo={setModulo} />
    </div>
  );
}
