import { useEffect, useState } from "react";
import { t } from "i18next";
import { notifyExitoso } from "../../../../../Utils/alerts/Alerts.js";
import "./CommonModulo.css";

const ModalChangeNameModule = ({ modulo, setModulo, setModalchangeName, ModalchangeName }) => {
  const [name, setName] = useState(modulo.name || "");
  const handleName = () => {
    if (name === modulo.name) {
      setModalchangeName(false);
      return;
    } else if (!name) {
      return;
    }
    const updateModulo = { ...modulo, name: name };
    setModulo(updateModulo);
    setModalchangeName(false);
    notifyExitoso(t("nombreCambiado"));
  };
  useEffect(() => {
    setName(modulo.name);
  }, [modulo.name]);
  return (
    <>
      {modulo.name && (
        <div className={`Modal module ${ModalchangeName ? "view" : ""}`}>
          <h1>{t("nuevoNombre")}</h1>
          <input type="text" placeholder={t("nuevoNombre")} value={name || ""} onChange={(e) => setName(e.target.value)} />
          <div className="container-btn-add-modulo">
            <button
              type="reset"
              style={{
                background: "var(--color--background-btn-navbar)",
              }}
              onClick={() => {
                setModalchangeName(false);
                setName("");
              }}
            >
              {t("cancelar")}
            </button>
            <button
              style={{
                background: "var(--color--azul--dashboard)",
              }}
              onClick={() => handleName()}
            >
              {t("cambiar")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalChangeNameModule;
