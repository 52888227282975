import "./ServerJoiner.css";
import InfoPrecios from "../../../common/EstadisticasHerramientasCluster";
import { t } from "i18next";
import Separador from "../../../common/Separador";
import BtnsModules from "../../../common/BtnsModules";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { deleteModule, orderModule } from "../../../common/FuntionsRequest/FunctionsRequest";
import Invitaciones from "./Invitaciones";
import ModalDeleteModule from "../../../common/ModalDeleteModule";
import { notifyErroneo, notifyExitoso } from "../../../../../../../Utils/alerts/Alerts";
import { SaveActiveServerJoiner, SaveDraftServerJoiner } from "../functionsModuleServerJointer/moduleServerJoiner";
import SelfBotsMassFriender from "./SelfBotsMassFriender";
import Spinner from "../../../../../../Spinner";
import { validateKeyCluster } from "../../../../../../../Utils/handleValidateLogin";
import EstadisticasHerramientasCluster from "../../../common/EstadisticasHerramientasCluster";
import BackgroundModalEffectunfocused from "../../../common/BackgroundModalEffectunfocused";
import ModalChangeNameModule from "../../../common/ModalChangeNameModule";
import SecurityMassFriender from "./SecurityMassFriender";
// import IntroModulo from "../../../common/IntroModulo";

const ServerJoinerModulo = ({ rutaModulo, ruta }) => {
  const { key, id } = useParams();
  const [isSelected, setIsSelected] = useState(false);
  const [ModalchangeName, setModalchangeName] = useState(false);
  const [isSelectBorradores, setIsSelectBorradores] = useState(false);
  const [modulo, setModulo] = useState({});
  const [cooldown, setCooldown] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const orderModuleKeyID = async () => {
      await validateKeyCluster(key);
      await orderModule(id, key, setModulo, rutaModulo, ruta);
      setLoading(false);
    };
    orderModuleKeyID();
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-principal-herramienta-cluster">
          <div>
            <ModalDeleteModule
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              setIsSelectBorradores={setIsSelectBorradores}
              isSelectBorradores={isSelectBorradores}
              id={id}
              setArrayModulesActive={[]}
              key={key}
              notifyExitoso={notifyExitoso}
              notifyErroneo={notifyErroneo}
              deleteModule={deleteModule}
              backPath={true}
              ruta={"serverJoiner"}
              rutaModulo={"serverJoiner"}
            />
            <BtnsModules
              setModalchangeName={setModalchangeName}
              setIsSelected={setIsSelected}
              modulo={modulo}
              cooldown={cooldown}
              saveActive={SaveActiveServerJoiner}
              saveDraft={SaveDraftServerJoiner}
              ruta={"massFriender"}
              NoCambioDeNombre={true}
            />
            <BackgroundModalEffectunfocused estado={isSelected} setEstado={setIsSelected} />
            <BackgroundModalEffectunfocused estado={ModalchangeName} setEstado={setModalchangeName} />

            <ModalChangeNameModule
              id={id}
              modulo={modulo}
              setModulo={setModulo}
              setModalchangeName={setModalchangeName}
              ModalchangeName={ModalchangeName}
            />
            <div className="container-modulos-cluster">
              <div className="container-herramienta-cluster">
                <div className="container-herramienta-modulo-titulo">
                  <h2>{t("estadisticas")}</h2>
                  <p>{t("estadisticasInfoModuloMassFrender")}</p>
                </div>
                <Separador />
                <div className="container-herramientas-estadisticas">
                  <div className="container-estadistica">
                    <EstadisticasHerramientasCluster color="secundario" num="$0,10" info={"precioEstimado"} />
                    <EstadisticasHerramientasCluster color="primario" num="xxx" info={"Miembrosdelservidor"} />
                    <EstadisticasHerramientasCluster color="secundario" num="xxx" info={"SelfBotsSugeridos"} />
                    <EstadisticasHerramientasCluster color="secundario" num="xx h" info={"Tiempomedio"} />
                  </div>
                </div>
              </div>
              <Invitaciones modulo={modulo} setModulo={setModulo} />
              <SecurityMassFriender modulo={modulo} setModulo={setModulo} />
              <SelfBotsMassFriender modulo={modulo} setModulo={setModulo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServerJoinerModulo;
