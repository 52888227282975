import add from "./../../../imgDashboard/addGuild.svg";
import tachoBasura from "./../../../imgDashboard/tachoBasura.svg";
import subirArchivo from "./../../../imgDashboard/subirArchivo.svg";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./Bots.css";
import CopiarAlPortapapeles from "../../../../../CopiarAlPortapapeles";
import ModalBotsTxt from "./ModalBotsTxt";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  OrderSelfBots,
  checkearValidTokens,
  deleteTokenksInvalid,
  detectText,
  deteleTokenSelfBot,
  handleCheckBotsTokens,
} from "./functionsBots/functionsSelfBots";
import { t } from "i18next";
import Separador from "../../common/Separador";
import Spinner from "../../../../../Spinner";
import { validateKeyCluster } from "../../../../../../Utils/handleValidateLogin";
import BackgroundModalEffectunfocused from "../../common/BackgroundModalEffectunfocused";

export default function SelfBots() {
  const [countsUsersBots, setCountsUsersBots] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [arrayTokens, setArrayTokens] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  let { key } = useParams();
  useEffect(() => {
    validateKeyCluster(key);
  }, []);
  const [token, setToken] = useState("");
  // carga todos los bots al cambiar la key
  useEffect(() => {
    OrderSelfBots(setCountsUsersBots, key);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [key]);

  useEffect(() => {
    if (!isSelected) {
      setSelectedFile([]);
      handleDeleteButtonClick();
    }
  }, [isSelected]);

  // subir token individual
  const handleCheckTOKENS = async (e) => {
    e.preventDefault();
    if (!token) {
      return;
    }
    handleCheckBotsTokens(token, key, setCountsUsersBots, setToken);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    detectText(file, setSelectedFile, setIsSelected, setArrayTokens, setCountsUsersBots, key);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteButtonClick = () => {
    setSelectedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container-principal-herramienta-cluster">
          <div className="container-info-panel-cluster">
            <div>
              <h1>Bots & Self bots</h1>
              <p>{t("Informacion")}</p>
            </div>
          </div>
          <BackgroundModalEffectunfocused estado={isSelected} setEstado={setIsSelected} />
          <div className="container-herramienta-cluster">
            {/* container-info-herramientas-clusters */}
            <div className="container-info-herramientas-clusters bots">
              <div>
                <h2>Self Bots</h2>
                <p>{t("cuentasUsuarios")}.</p>
              </div>
              {/* <button className="btn-selfbots">
                <i className="fa-solid fa-cart-shopping"></i>
                <span>Compra tokens</span>
              </button> */}
            </div>
            <Separador />
            <div className="container-table-bots">
              {countsUsersBots.length === 0 ? (
                <p>{t("noElementos")}</p>
              ) : (
                <table className="tabla-bots">
                  <thead>
                    <tr>
                      {console.log(countsUsersBots)}
                      <th className="th-bots">{t("usuarios")}</th>
                      <th className="th-bots">ID's</th>
                      <th className="th-bots">Tokens</th>
                      <th className="th-bots">Email</th>
                      <th className="th-bots">{t("telefono")}</th>
                      <th className="th-bots">{t("estado")}</th>
                      <th className="th-bots"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {countsUsersBots.map((item, index) => (
                      <tr key={index}>
                        <td className="td-bots">
                          <span>
                            <p>
                              <CopiarAlPortapapeles contenido={item.username} />
                            </p>
                          </span>
                        </td>
                        <td className="td-bots">
                          <span>
                            <p>
                              <CopiarAlPortapapeles contenido={item.id} />
                            </p>
                          </span>
                        </td>
                        <td className="td-bots">
                          <span>
                            <p>
                              <CopiarAlPortapapeles contenido={item.token} />
                            </p>
                          </span>
                        </td>
                        <td className="td-bots">
                          <span className={!item.email ? "verificado" : "noVerificado"}>
                            {!item.email ? `${t("verificado")}` : `${t("noVerificado")}`}
                          </span>
                        </td>
                        <td className="td-bots">
                          <span className={item.phone ? "verificado" : "noVerificado"}>
                            {item.telefono ? `${t("verificado")}` : `${t("noVerificado")}`}
                          </span>
                        </td>
                        <td className="td-lastchild">
                          <span className={item.valid ? "verificado" : "noVerificado"}>
                            {item.valid ? `${t("verificado")}` : `${t("noVerificado")}`}
                          </span>
                        </td>

                        <td className="td-bots">
                          <button className="basura-img-btn" onClick={() => deteleTokenSelfBot(item.id, key, setCountsUsersBots)}>
                            <img className="basura-img" src={tachoBasura} alt="" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            <Separador />
            <div className="agregar-tokens">
              <form
                onSubmit={(e) => handleCheckTOKENS(e, countsUsersBots, setCountsUsersBots, key)}
                className="primera-parte-agregar"
              >
                <h3>{t("agregarToken")}</h3>
                <div className="moduloAgregar">
                  <input type="text" value={token} placeholder={t(`Addtokenhere`)} onChange={(e) => setToken(e.target.value)} />
                  <button type="submit">
                    {t("Send")} <img src={add} alt="Add" style={{ cursor: "pointer" }} />
                  </button>
                </div>
              </form>
              <div className="segunda-parte-agregar">
                <h3>{t("archivoToken")}(.txt)</h3>
                <div className="subir-archivo">
                  <div>
                    <input
                      type="file"
                      accept=".txt"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: "none" }} // Ocultar visualmente el input
                    />
                  </div>
                  {selectedFile ? (
                    <div>
                      <p className="archivo-seleccionado">
                        <span>{t("archivoSeleccionado")}:</span> {selectedFile.name}
                      </p>
                      <button className="btn-subir-archivo" onClick={handleDeleteButtonClick}>
                        {t("eliminar")}
                      </button>
                    </div>
                  ) : (
                    <button className="btn-subir-archivo" onClick={handleButtonClick}>
                      <span>{t("subirArchivo")}</span>
                      <img src={subirArchivo} alt="Subir" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            <Separador />
            <div className="tercera-parte-bots">
              <button className="btn-subir-archivo" onClick={() => checkearValidTokens(countsUsersBots, key, setCountsUsersBots)}>
                {t("checkearTokens")}
              </button>
              <button type="submit" onClick={() => deleteTokenksInvalid(countsUsersBots, key)} className="btn-subir-archivo">
                {t("eliminarTokens")}
              </button>
            </div>
          </div>
          <ModalBotsTxt isSelected={isSelected} setIsSelected={setIsSelected} arrayTokens={arrayTokens} />
        </div>
      )}
    </>
  );
}
