import "./Control.css";
import control from "../../../../../img/control.webp";

const Control = ({ t }) => {
  return (
    <main>
      <section className="container-control">
        <div className="primera-parte-control">
          <img src={control} alt="Control" />
        </div>
        <div className="segunda-parte-control">
          <h1>{t("tituloControl")}</h1>
          <p>{t("descripcionControl")}</p>
        </div>
      </section>
    </main>
  );
};

export default Control;
