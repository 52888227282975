export const TranslateAfiliados = {
  en: {
    translation: {
      // Afiliados
      programaAfiliados: "Affiliates Program",
      earnComision: "Earn a 10% commission for every registered user's deposit referred by your affiliate link.",
      readComision: "Please read our affiliate guidelines to make sure you fully comply with them.",
      detallesPrograma: "Program Details",
      linkAfiliados: "Affilate Link",
      copy: "Copy",
      shareLink: "You can share this link on your newsletter, blog or simply share it with your friend directly.",
      performanceAfiliados: "Your Performance",
      usuariosRegistrados: "Registered User",
      cuentasDepositos: "Deposited Accounts",
      facturasAfiliados: "Invoices",
      gananciasTotales: "Total Earnings",
      Rangosemanal: "Weekly Ranks",
      Porcentaje: "Percentage",
      ganancias: "Earnings",
      depositos: "Deposits",
      solicitudPago: "Request Payout",
      balance: "Balance",
      pagosAfiliados: "Payouts are only made through",
      solicitar: "Request",
      tiempoProcesamiento: "Processing Time",
      horas: "Hours",
      informacion: "Information",
      afiliadosInformatcion:
        "Earn passive income easily by recommending our products and get rewards not only for the purchases of your referrals, but also for those of their guests. Create a powerful affiliate network and earn money effortlessly!",

      porcentaje: "Percentage",
      descuento: "Discount",
      usos: "Use",
      Plata: "Silver",
      Oro: "Gold",
      Platino: "Platinum",
      Diamante: "Diamond",
      saldoAfiliado: "Affiliate balance",
      saldoDescripcion:
        "This is the balance you currently have in our affiliate system. You can withdraw it or send it to your account balance and use it for future purchases.",
      solicitarRetirada: "Request withdrawal",
      enviarSaldo: "Send to balance",
      depositar: "Deposit",
      Enlacedereferidos: "Referral link",
      Progresosemanal: "Weekly progress",
      copiar: "Copy",

      ReferrallinkText:
        "Direct users are those who register with your referral link. Indirect users are those who register from the link of a person who has registered with their link, as well as all the people invited by these people.",
      Usuariosdirectos: "Direct users",
      Usuariosindirectos: "Indirect users",
      Ingresosdirectos: "Direct income",
      Ingresosindirectos: "Indirect income",
      ultimoParrafoAfiliados:
        "The 25% profit may vary in certain circumstances. See our official documentation for specific details. The term 'recurring' means that you earn every time the user makes purchases, not for a one-time purchase. The conditions of this offer may change without prior notice.",
    },
  },
  es: {
    translation: {
      //Afiliados
      programaAfiliados: "Programa de Afiliados",
      Rangosemanal: "Rangos semanales",
      Porcentaje: "Porcentaje",
      earn: "Gane una",
      afiliadosInformatcion:
        "Gana ingresos pasivos fácilmente recomendando nuestros productos y obtén recompensas no solo por las compras de tus referidos, sino también por las de sus invitados. ¡Crea una poderosa red de afiliados y gana dinero sin esfuerzo!",
      comisionAfiliados: " comisión del 10%",
      terceraParteAfiliados: "por cada depósito de usuarios registrados referido por su enlace de afiliado.",
      readComision: "Por favor lea nuestras pautas para afiliados para asegurarse de cumplirlas plenamente..",
      detallesPrograma: "Detalles del programa",
      linkAfiliados: "Link de Afiliados",
      copy: "Copiar",
      shareLink:
        "Puede compartir este enlace en su boletín informativo, blog o simplemente compartirlo directamente con su amigo.",
      performanceAfiliados: "Su actuación",
      usuariosRegistrados: "Usuarios registrados",
      cuentasDepositos: "Cuentas depositadas",
      facturasAfiliados: "Facturas",
      gananciasTotales: "Ganancias Totales",
      ganancias: "Ganancias",
      depositos: "Depósitos",
      Progresosemanal: "Progreso semanal",
      Plata: "Plata",
      Oro: "Oro",
      Platino: "Platino",
      Diamante: "Diamante",
      Enlacedereferidos: "Enlace de referidos",
      solicitudPago: "Solicitud de Pago",
      balance: "Balance",
      pagosAfiliados: "Los pagos se realizan únicamente a través de",
      solicitar: "Solicitar",
      tiempoProcesamiento: "Tiempo de procesamiento",
      horas: "Horas",
      pagoMinimo: "Pago Mínimo",
      informacion: "Información",
      porcentaje: "Porcentaje",
      descuento: "Descuento",
      usos: "Usos",
      copiar: "Copiar",
      saldoAfiliado: "Saldo de afiliado",
      saldoDescripcion:
        "Este es el saldo que tienes actualmente en nuestro sistema de afiliados. Puedes retirarlo o enviarlo al balance general de tu cuenta y poder usarlo en futuras compras.",
      solicitarRetirada: "Solicitar retirada",
      enviarSaldo: "Enviar al saldo",
      ReferrallinkText:
        "Los usuarios directos son aquellos que se registran con tu enlace de referidos. Los usuarios indirectos son los que se registran a partir del enlace de una persona que se ha registrado con su enlace, así como todas las personas que inviten estas personas.",
      Usuariosdirectos: "Usuarios directos",
      Usuariosindirectos: "Usuarios indirectos",
      Ingresosdirectos: "Ingresos directos",
      Ingresosindirectos: "Ingresos indirectos",
      depositar: "Depositar",
      ultimoParrafoAfiliados:
        " La ganancia del 25% puede variar en ciertas circunstancias. Consulta nuestra documentación oficial para detalles específicos. El término 'recurrente' significa que obtienes ganancias cada vez que el usuario realiza compras, no por una compra única. Las condiciones de esta oferta pueden cambiar sin previo aviso.",
    },
  },
};
