import axios from "axios";
export const handleOrderClusters = async (email, backendURL, setArrayClusters) => {
  try {
    const response = await axios.post(`${backendURL}/order-clusters`, {
      email: email,
    });
    if (response.status === 200) {
      setArrayClusters(response.data.clusters.reverse());
    }
  } catch (error) {
    console.error(error);
  }
};

export const orderKeyCluster = async (key, backendURL, setActive) => {
  try {
    const response = await axios.get(`${backendURL}/order-state-cluster`, {
      params: { key: key },
    });
    if (response.status === 200) {
      setActive(response.data);
    }
  } catch (error) {
    console.error(error);
  }
};

// publicCluster
export const orderPublicCluster = async (key, backendURL, setPublicCluster) => {
  try {
    const response = await axios.get(`${backendURL}/order-public-cluster`, {
      params: { key: key },
    });
    if (response.status === 200) {
      setPublicCluster(response.data);
    }
  } catch (error) {
    console.log(error);
  }
};
