import "./GetStarted.css";
import star from "../../../../../img/star.svg";
import escudo from "../../../../../img/escudo.svg";
import send from "../../../../../img/send.svg";
import dinero from "../../../../../img/dinero.svg";

const GetStarted = ({ t, user }) => {
  return (
    <main>
      <section className="container-get-started">
        <div className="primera-parte-started">
          <h1>{t("tituloGetStarted")}</h1>
          <p>{t("subtituloGetStated")}</p>
          {!user && <button onClick={() => (window.location.href = "/register")}>{t("DescurirMas")}</button>}
        </div>
        <div className="segunda-parte-started">
          
          <div>
            <img src={escudo} alt="Secured Icon JikesShop" />
            <div>
              <h3>{t("asegurado")}</h3>
              <p>{t("codificaciones")}</p>
            </div>
          </div>
          <div>
            <div>
              <img src={send} alt="Send Icon JikesShop" />
            </div>
            <div>
              <h3>{t(`automatizad`)}</h3>
              <p>{t(`proceso`)}</p>
            </div>
          </div>
          <div>
            <img src={star} alt="Rewards Icon JikesShop" />
            <div>
              <h3>{t("GanaDinero")}</h3>
              <p>{t("ganaDineroText")}</p>
            </div>
          </div>
          <div>
            <div>
              <img src={dinero} alt="dinero Icon JikesShop" />
            </div>
            <div>
              <h3>{t("ServiciosBaratos")}</h3>
              <p>{t("ServiciosBaratosText")}</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default GetStarted;
