import { useState } from "react";
import { t } from "i18next";
import add from "../../../../../../../img/add.webp";
import AddMessageImg from "../../../../imgDashboard/img-agregar-servidor.png";
import { useRef } from "react";

export default function InputAddChannelsGuildSpammer({ selfBots, setModulo, modulo }) {
  const [selectedBotId, setSelectedBotId] = useState("");
  const [tagsAdd, setTagsAdd] = useState([]);
  const [tagInputAdd, setTagInputAdd] = useState("");
  const inputRef = useRef(null);

  const handleFocusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  const handleSelectChange = (e) => {
    setSelectedBotId(e.target.value);
  };
  const handleInputKeyDownAdd = (e) => {
    if (e.key === "Enter" && tagInputAdd.trim() !== "") {
      const isTagAlreadyAdded = tagsAdd.includes(tagInputAdd.trim());

      if (!isTagAlreadyAdded) {
        setTagsAdd([...tagsAdd, tagInputAdd.trim()]);
        setTagInputAdd("");
      } else {
        // notifyEsperando("Este tag ya está en la lista.");
      }
    }
  };

  const handleTagRemoveAdd = (removedTag) => {
    const updatedTagsAdd = tagsAdd.filter((tag) => tag !== removedTag);
    setTagsAdd(updatedTagsAdd);
  };

  const handleSave = () => {
    if (selectedBotId && tagsAdd.length > 0) {
      // Verificación de 'modulo' y 'modulo.selfBots'
      if (modulo && modulo.selfBots) {
        // Actualiza el estado del módulo con los nuevos selfBots
        setModulo((prevModulo) => {
          const updatedSelfBots = [
            ...prevModulo.selfBots,
            {
              botID: selectedBotId,
              channels: tagsAdd,
            },
          ];

          return { ...prevModulo, selfBots: updatedSelfBots };
        });
        setTagsAdd([]);
        setTagInputAdd("");
        // Puedes realizar acciones adicionales según tus necesidades
        // console.log("Información guardada con éxito");
      } else {
        // console.error("'modulo' o 'modulo.selfBots' es undefined");
      }
    } else {
      // console.log("Selecciona un bot y agrega al menos un tag antes de guardar.");
    }
  };

  // Filtra la lista de bots disponibles para que no incluya los bots ya seleccionados
  const availableBots = selfBots
    .filter((bot) => bot.valid === true)
    .filter((bot) => !modulo?.selfBots?.some((selectedBot) => selectedBot.botID === bot.id));

  return (
    <div>
      <h3 style={{ color: "white", margin: "0" }}>{t("Añadecanalesnuevos")}</h3>
      <div className="table-canals-Add">
        <div>
          <h4>Self Bots</h4>
          <div className="canal-selfbot Add-canales">
            {availableBots.length > 0 ? (
              <select value={selectedBotId} onChange={handleSelectChange} style={{ padding: "1rem", cursor: "pointer" }}>
                <option value="">{t("seleccioneBot")}</option>
                {availableBots.map((bot) => (
                  <option key={bot.id} value={bot.id}>
                    {bot.username}
                    {/* ({bot.id}) */}
                  </option>
                ))}
              </select>
            ) : (
              <select value={selectedBotId} onChange={handleSelectChange}>
                <option value="">{t("NohaySelfBots")}</option>
              </select>
            )}
          </div>
        </div>

        <div className="container-canales-tags">
          <div>
            <h4>{t("canales")}</h4>
            <div className="container-canales-boton" onClick={handleFocusInput}>
              <div className="canales-tags">
                {tagsAdd.map((tag, index) => (
                  <div key={tag} className="tag-canal">
                    <span>{tag}</span>
                    <button onClick={() => handleTagRemoveAdd(tag)}>x</button>
                  </div>
                ))}
                <input
                  ref={inputRef}
                  value={tagInputAdd || ""}
                  data-role="taginput"
                  onChange={(e) => setTagInputAdd(e.target.value)}
                  onKeyDown={handleInputKeyDownAdd}
                  placeholder={t("addTags")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-canales-tags">
          <h5>{t("dassadsads")}</h5>
          <div className="agregar-message">
            <button onClick={handleSave} style={{ cursor: "pointer", outline: "none", margin: "auto" }}>
              <img src={AddMessageImg} alt="Add new Tag guildSpammer jikesshop" />
              <span>+</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
