import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { OrderSelfBots } from "../../bots/functionsBots/functionsSelfBots";
import Separador from "../../../common/Separador";
import SelectSelfBotsMassFriender from "./SelectSelfBotsMassFriender";
import { t } from "i18next";
import ButtonsSelfbotsUser from "./ButtonsSelfbotsUser";

export default function SelfBotsMassFriender({ setModulo, modulo }) {
  const [tags, setTags] = useState([]);
  const [selfBots, setSelftBots] = useState([]);

  const { key } = useParams();

  useEffect(() => {
    const orderBots = async () => {
      await OrderSelfBots(setSelftBots, key);
    };
    orderBots();
  }, [key]);

  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>Self Bots</h2>
        <p>{t("SelfBotsText")}</p>
      </div>
      <Separador />
      <ButtonsSelfbotsUser setModulo={setModulo} modulo={modulo} />
      <Separador />
      <SelectSelfBotsMassFriender
        setTags={setTags}
        tags={tags}
        selfBots={selfBots}
        setSelftBots={setSelftBots}
        setModulo={setModulo}
        modulo={modulo}
      />
    </div>
  );
}
