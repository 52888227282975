import "./../dashboard.css";
import AddCluster from "./AddCluster";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

export default function CreateClusters({
  setIsSelected,
  email,
  isSelected,
  open,
  arrayCluster,
  close,
}) {
  return (
    <div className="clusters-container">
      <div
        className={`fondo-borroso ${isSelected ? "active" : ""}`}
        onClick={close}
      ></div>
      <h1 className="titulo">Clusters</h1>
      <div className="container-block-cluster">
        {arrayCluster.length > 0 &&
          arrayCluster.map((cluster, index) => (
            <Link
              to={`/dashboard/${cluster.key}`}
              className="container-block"
              key={index}
            >
              <h3>{cluster.name}</h3>
              <div className="separator-block"></div>
              <div className="container-numero-cluster">
                <p>{cluster.key}</p>
              </div>
            </Link>
          ))}
        <AddCluster open={open} />
      </div>
      <Modal
        setIsSelected={setIsSelected}
        email={email}
        isSelected={isSelected}
        close={close}
      />
    </div>
  );
}
