import React, { useState } from "react";
import silver from "../imgAfiliados/silver.webp";
import gold from "../imgAfiliados/gold.webp";
import platinium from "../imgAfiliados/platinium.webp";
import diamond from "../imgAfiliados/diamond.webp";
import { t } from "i18next";

export default function ProgressWeek() {
  const [progress, setProgress] = useState(66); // State for the progress percentage

  return (
    <div className="Progress-week">
      <h3>{t("Progresosemanal")}</h3> {/* Weekly progress title */}
      <div className="barra-progreso-container">
        <div className="barra-progreso" title={`${progress}% conseguido esta semana`}>
          <div className="progress-bar" style={{ width: `${progress}%` }} title={`${progress}% conseguido esta semana`}></div>
        </div>
        <div className="niveles" title={`${progress}% conseguido esta semana`}>
          <div className="nivel">
            <img src={silver} alt="Logo rank silver jikesshop afilliates" className={`${progress >= 0 ? "border" : ""}`} />
            <p>{t("Plata")}</p> {/* Silver level */}
            <h4>
              25%<span>*</span>
            </h4>
            <p>default</p> {/* Default reward */}
          </div>
          <div className="nivel">
            <img src={gold} alt="Logo rank gold jikesshop afilliates" className={`${progress >= 30 ? "border" : ""}`} />
            <p>{t("Oro")}</p> {/* Gold level */}
            <h4>
              30%<span>*</span>
            </h4>
            <p>5€</p> {/* Gold reward */}
          </div>
          <div className="nivel">
            <img src={platinium} alt="Logo rank platinium jikesshop afilliates" className={`${progress >= 66 ? "border" : ""}`} />
            <p>{t("Platino")}</p> {/* Platinum level */}
            <h4>
              35%<span>*</span>
            </h4>
            <p>25€</p> {/* Platinum reward */}
          </div>
          <div className="nivel">
            <img src={diamond} alt="Logo rank diamond jikesshop afilliates" className={`${progress >= 100 ? "border" : ""}`} />
            <p>{t("Diamante")}</p> {/* Diamond level */}
            <h4>
              45%<span>*</span>
            </h4>
            <p>50€</p> {/* Diamond reward */}
          </div>
        </div>
      </div>
    </div>
  );
}
