import { useState } from "react";
import { t } from "i18next";
import imgAgregarServidor from "./../../../../imgDashboard/img-agregar-servidor.png";
import tachoBasura from "./../../../../imgDashboard/tachoBasura.svg";
import Separador from "../../../common/Separador";

export default function Invitaciones({ modulo, setModulo }) {
  const [inviteUrl, setInviteUrl] = useState("");

  const handleAddInvite = (e) => {
    e.preventDefault();
    if (inviteUrl.trim() !== "") {
      const updatedModulo = { ...modulo };
      updatedModulo.invites = [...updatedModulo.invites, inviteUrl];
      setModulo(updatedModulo);
      setInviteUrl("");
    }
  };

  const handleDeleteInvite = (index) => {
    const updatedModulo = { ...modulo };
    updatedModulo.invites.splice(index, 1);
    setModulo(updatedModulo);
  };
  return (
    <div className="container-herramienta-cluster">
      <div className="container-herramienta-modulo-titulo">
        <h2>{t("Servidores")}</h2>
        <p>{t("ServidoresDescripcion")}</p>
      </div>
      <Separador />
      <div className="invitaciones">
        <h4>{t("invitaciones")}</h4>
        {modulo && modulo.invites && (
          <>
            {modulo.invites.length > 0 ? (
              <>
                {modulo.invites.map((moduloItem, index) => (
                  <div key={index} className="agregar-invitaciones" style={{ margin: "0.3rem 0" }}>
                    <input type="text" value={moduloItem} className="input-invitacion" />
                    <button type="submit" onClick={() => handleDeleteInvite(index)}>
                      <img src={tachoBasura} alt="TachoBasura" className="basura-img-invitacion" />
                    </button>
                  </div>
                ))}
              </>
            ) : (
              <p className="parrafo-no-elements">{t("NohayInvitaciones")}</p>
            )}
          </>
        )}
      </div>
      <Separador />
      <form className="invitaciones" onSubmit={(e) => handleAddInvite(e)}>
        <h4>{t("agregarInvitacion")}</h4>
        <div className="agregar-invitaciones">
          <input
            type="text"
            placeholder="https://discord.gg/asdf2as"
            value={inviteUrl}
            onChange={(e) => setInviteUrl(e.target.value)}
          />
          <button type="submit">
            <img src={imgAgregarServidor} alt="Add" /> <span>+</span>
          </button>
        </div>
      </form>
    </div>
  );
}
