import { t } from "i18next";
import "./ProgramaAfiliados.css";
import SeparadorAfiliados from "./SeparadorAfiliados";
import edit from "./../imgAfiliados/edit.svg";
import deleteIcon from "./../imgAfiliados/deleteIcon.svg";
import { useEffect, useState } from "react";
import ModalAfiliadosCodigosDescuento from "./ModalAfiliadosCodigosDescuento";
import { notifyErroneo, notifyExitoso } from "../../../../Utils/alerts/Alerts";
import clipboardCopy from "clipboard-copy";
import ModuloCambiarPorcentaje from "./ModuloCambiarPorcentaje";
import { backendURL } from "../../../../config";
import axios from "axios";

const Descuento = ({ user, setUser }) => {
  const [activeModal, setActiveModal] = useState(false); // State to manage the visibility of the discount code modal
  const [activeModalPorcentaje, setActiveModalPorcentaje] = useState(false); // State to manage the visibility of the percentage change modal
  const [Porcentaje, setPorcentaje] = useState(); // State for the discount percentage
  const [code, setCode] = useState(); // State for the discount code
  const [discountCodes, setDiscountCodes] = useState(user.affiliates.discountCodes || [{}]); // State for the list of discount codes

  const handleDelete = async (code) => {
    try {
      const response = await axios.delete(`${backendURL}/delete-discount-code-affiliates`, {
        data: { user: user, discountCodesData: code }, // Pass data in the 'data' property
      });

      if (response.status === 200) {
        notifyExitoso("¡Se eliminó con éxito!"); // Notify user of successful deletion
        setDiscountCodes(response.data.user.affiliates.discountCodes); // Update state with new list of discount codes
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = async (code) => {
    try {
      notifyExitoso(t("copiadoPortapapeles")); // Notify user that the code was copied to clipboard
      await clipboardCopy(code); // Copy code to clipboard
    } catch (error) {
      notifyErroneo(t("errorCopiadoPortapapeles")); // Notify user of copy error
      console.error(t("errorCopiadoPortapapeles"), error);
    }
  };

  const handleModal = (discountPercentage, code) => {
    setPorcentaje(discountPercentage); // Set discount percentage for the modal
    setCode(code); // Set discount code for the modal
    setActiveModalPorcentaje(true); // Show percentage change modal
  };

  return (
    <div className="container-modulo-afiliados">
      <div className="subtitulo-afiliados">
        <h2>{t("Códigos de descuento")}</h2> {/* Header for discount codes section */}
      </div>
      <SeparadorAfiliados />
      <div className={`background-modal  ${activeModal ? "active" : ""} `} onClick={() => setActiveModal(false)}></div>
      {user && (
        <ModalAfiliadosCodigosDescuento
          user={user}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
          setDiscountCodes={setDiscountCodes}
        />
      )}
      <div
        className={`background-modal  ${activeModalPorcentaje ? "activar" : ""}`}
        onClick={() => setActiveModalPorcentaje(false)}
      ></div>
      {user && (
        <ModuloCambiarPorcentaje
          user={user}
          Porcentaje={Porcentaje}
          code={code}
          activeModal={activeModalPorcentaje}
          setActiveModal={setActiveModalPorcentaje}
          setDiscountCodes={setDiscountCodes}
        />
      )}

      <div className="container-info-programa-afiliados-descuentos">
        {!user.affiliates.discountCodes || discountCodes.length === 0 ? (
          <p style={{ textAlign: "center", padding: "1rem" }}>No hay Códigos de descuento</p> // Message when no discount codes are available
        ) : (
          <>
            {discountCodes && (
              <>
                {discountCodes.map((code, index) => (
                  <div key={index} className="descuento-item">
                    <div className="primera-parte-programa-afiliados">
                      <input type="text" placeholder={code.code} />
                      <button onClick={() => handleClick(code.code)}>{t("copiar")}</button> {/* Button to copy discount code */}
                    </div>
                    <div>
                      <h1>{code.discountPercentage}%</h1>
                      <p>{t("descuento")}</p> {/* Display discount percentage */}
                    </div>
                    <div>
                      <h1>{code.usages}</h1>
                      <p>{t("usos")}</p> {/* Display number of uses */}
                    </div>
                    <div>
                      <h1>${code.earnings}</h1>
                      <p>{t("ganancias")}</p> {/* Display earnings */}
                    </div>
                    <button
                      type="button"
                      onClick={() => handleModal(code.discountPercentage, code.code)}
                      className="botonEditarAfiliados"
                    >
                      <img src={edit} alt="edit" /> {/* Button to edit discount code */}
                    </button>
                    <button type="button" onClick={() => handleDelete(code.code)} className="botonEditarAfiliados">
                      <img src={deleteIcon} alt="delete" /> {/* Button to delete discount code */}
                    </button>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>

      <SeparadorAfiliados />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          marginTop: "2rem",
        }}
      >
        <button className="agregarAfiliados" type="button" onClick={() => setActiveModal(true)}>
          Nuevo Código {/* Button to add a new discount code */}
        </button>
      </div>
    </div>
  );
};

export default Descuento;
