import { t } from "i18next";
import correo from "../imgNavbar/correo.webp";
import noNotificaciones from "../imgNavbar/noNotificaciones.webp";

// Notification menu component
export default function MenuNotificaciones({
  seccionNotify, // Boolean to control visibility of notifications section
  notifiacionesItem, // Array of notification items
}) {
  return (
    <div className={`container-notification ${seccionNotify ? "active" : ""}`}>
      {/* Header for the notifications menu */}
      <div className="header-notification">
        <h3>{t("notificaciones")}</h3> {/* Title in the current language */}
        <img src={correo} alt="Correo" style={{ cursor: "pointer" }} /> {/* Email icon */}
      </div>

      {/* Notifications list */}
      <div className="notificaciones">
        {notifiacionesItem ? (
          notifiacionesItem.map((item, index) => (
            <div key={index} className="container-info-notificaciones">
              {/* Notification item */}
              <div className="info-notify">
                {!item.viewed ? <div className="punto-azul"></div> : ""} {/* Blue dot for unread notifications */}
                <div className="titulos-notify">
                  <h4>{item.title}</h4> {/* Notification title */}
                  <p>{item.message}</p> {/* Notification message */}
                  {item.button && (
                    <button
                      style={{ color: item.button.color }} // Button color based on item data
                      onClick={() =>
                        (window.location.href = `/${item.button.href}`) // Redirect to button backendURL
                      }
                    >
                      {item.button.label} {/* Button label */}
                    </button>
                  )}
                </div>
              </div>
              <div className="separator-notification"></div> {/* Separator between notifications */}
            </div>
          ))
        ) : (
          <div>
            <img src={noNotificaciones} alt="Sin-notificaciones" /> {/* No notifications image */}
            <h3>{t("noNotificaciones")}</h3> {/* No notifications message in the current language */}
          </div>
        )}
      </div>
    </div>
  );
}
